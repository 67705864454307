import React, {useEffect, useState} from 'react';
import {
  Banner,
  Button,
  DataTable,
  DisplayText,
  Frame,
  Heading,
  Icon,
  Link,
  Loading,
  TextContainer,
} from '@shopify/polaris';
import {default as ExternalLink} from '../../app/src/foundation/Link';
import useUploadHistory from './hooks/get-upload-history';
import {useTreetShopContext} from '../../context/treet-shop-context';
import {Modal} from 'react-bootstrap';
import {MobileCancelMajor, ClockMajor} from '@shopify/polaris-icons';
import styled from 'styled-components';
import {compact, isEmpty} from 'lodash';
import {useNavigate} from 'react-router-dom';
import {MENU_ITEMS, MENU_ITEMS_URL_MAP} from '../../utils/navigation';
import {Box, Typography} from '@material-ui/core';
import TableContainer from '../../components/TableContainer';
import {ProductService} from '../../services';
import {VARIANT_FILTER} from '../../constants/filters';

const dateTimeFormat = new Intl.DateTimeFormat();
const productService = new ProductService();

const StyledIcon = styled(Box)`
  .Polaris-Icon {
    height: 80px;
    width: 80px;
  }
`;
const UploadType = {
  CrossPost: 'Cross-Post',
  Transfer: 'Transfer',
  Duplicate: 'Duplicate',
};

const EmptyState = () => {
  const navigate = useNavigate();
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      p={10}
      bgcolor="#ffffff"
    >
      <StyledIcon mb={2}>
        <Icon source={ClockMajor} color="base" />
      </StyledIcon>
      <Box mb={1}>
        <Heading>No Uploads Yet</Heading>
      </Box>
      <Box mb={2} textAlign="center">
        <DisplayText>
          Use the uploader to list brand direct items on Treet. <br />
          Once listed, they will appear here.
        </DisplayText>
      </Box>
      <Box>
        <Button
          fullWidth
          size="large"
          primary
          onClick={() => navigate(MENU_ITEMS_URL_MAP[MENU_ITEMS.OVERVIEW])}
        >
          List Product Variants
        </Button>
      </Box>
    </Box>
  );
};

const StyledHeader = styled(Modal.Header)`
  font-family: 'SF Pro';
  font-size: 1.25rem !important;
  color: #202223;
  font-weight: 400;

  .close {
    cursor: pointer;
  }

  .close > .Polaris-Icon {
    height: 1.25 rem;
    width: 1.25 rem;
  }
`;

const StyledBody = styled(Modal.Body)`
  overflow: auto;
`;

const ErrorModalForUpload = ({errorModalForUpload, onModalClose}) => {
  return (
    <Modal centered show={!!errorModalForUpload} onHide={onModalClose}>
      <StyledHeader closeButton>
        <Modal.Title>Error During Upload</Modal.Title>
        <div className="close" onClick={onModalClose}>
          <Icon source={MobileCancelMajor} color="base" />
        </div>
      </StyledHeader>
      <StyledBody>
        <TextContainer>
          Please reach out to{' '}
          <Link url="mailto:support@treet.co">support@treet.co</Link> and
          reference Upload{' '}
          {errorModalForUpload.uploadId
            ? `ID: ${errorModalForUpload.uploadId}`
            : `date: ${errorModalForUpload.uploadDate}`}
          .
        </TextContainer>
        <br />
        <TextContainer>
          <b>Error message:</b> {errorModalForUpload.errorMessage}
        </TextContainer>
      </StyledBody>
      <Modal.Dialog>
        <Button fullWidth onClick={onModalClose}>
          Close
        </Button>
      </Modal.Dialog>
    </Modal>
  );
};

const formatUploadRow = (
  uploadHistoryEntry,
  canonicalRootUrl,
  setErrorModalForUpload,
  navigate,
  preloadedSyncedVariants,
) => {
  const returnValue = [];

  if (isEmpty(uploadHistoryEntry)) {
    return returnValue;
  }

  const {
    uploadId,
    uploadDate,
    numListings,
    totalInventory,
    uploadStatus,
    errorMessage,
    isSyncedWithTreet,
    productVariantsByShopVariantId,
    uploadType,
  } = uploadHistoryEntry;

  const chipColor =
    uploadType === UploadType.Transfer
      ? '#ace7cf'
      : uploadType === UploadType.Duplicate
      ? '#e7c5ff'
      : '#ffd2c3';

  const isSuccessfulUpload = uploadStatus === 'SUCCEEDED';
  // format Date
  returnValue.push(dateTimeFormat.format(new Date(uploadDate)));

  // format upload id
  returnValue.push(uploadId ? `#${uploadId}` : 'N/A');

  // format upload type
  returnValue.push(
    <Box
      display={uploadType ? 'flex' : 'none'}
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      style={{
        backgroundColor: chipColor,
        padding: '1px',
        borderRadius: '20px',
      }}
    >
      {uploadType}
    </Box>,
  );

  // Add product variant count
  returnValue.push(numListings);

  // Add total inventory
  returnValue.push(totalInventory);

  // Add listing link
  returnValue.push(
    isSuccessfulUpload ? (
      <ExternalLink
        key={`manage-${uploadId || uploadDate}`}
        url={`${canonicalRootUrl}/listings/${uploadId || ''}`}
        external
      >
        Manage on Treet
      </ExternalLink>
    ) : (
      <Link
        key={`failed-${uploadId || uploadDate}`}
        onClick={() =>
          setErrorModalForUpload({uploadId, uploadDate, errorMessage})
        }
      >
        Upload Failed
      </Link>
    ),
  );

  // Find all variants from upload that are still marked as "synced with treet" in the DB
  const variantIdsSyncedWithTreetInUpload = compact(
    productVariantsByShopVariantId.map(
      (shopVariantId) =>
        preloadedSyncedVariants.find(
          (variant) => variant.shop_variant_id === `${shopVariantId}`,
        )?.id,
    ),
  );

  returnValue.push(
    isSyncedWithTreet &&
      variantIdsSyncedWithTreetInUpload.length > 0 &&
      isSuccessfulUpload ? (
      <Button
        size="slim"
        onClick={() =>
          navigate(MENU_ITEMS_URL_MAP[MENU_ITEMS.CROSS_POSTED_INVENTORY], {
            state: {isSelected: variantIdsSyncedWithTreetInUpload}, // automatically select variants
          })
        }
      >
        Unlink Inventory
      </Button>
    ) : (
      <></>
    ),
  );

  return returnValue;
};

const UploadHistoryView = () => {
  const {treetShopConfig} = useTreetShopContext();
  const {treetId, canonicalRootUrl} = treetShopConfig || {};
  const {
    isUploadHistoryLoading,
    uploadHistory,
    uploadHistoryError,
    dismissUploadHistoryError,
  } = useUploadHistory(treetId);
  const [errorModalForUpload, setErrorModalForUpload] = useState(null);
  const [preloadedSyncedVariants, setPreloadedSyncedVariants] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    productService
      .getFilteredProducts({
        variantFilter: VARIANT_FILTER.IS_SYNC_INVENTORY_WITH_TREET,
      })
      .then((res) => {
        const mappedVariantIds = res.data.data?.rows?.map(
          ({id, shop_variant_id}) => {
            return {id, shop_variant_id};
          },
        );
        setPreloadedSyncedVariants(mappedVariantIds);
      })
      .catch((e) => console.error(e));
  }, []);

  return (
    <>
      <Box my={1}>
        <Typography variant="h1" style={{fontSize: '24px', fontWeight: 'bold'}}>
          Upload History
        </Typography>
      </Box>
      {uploadHistoryError && (
        <Box mb={3}>
          <Banner
            status="critical"
            title={uploadHistoryError}
            onDismiss={dismissUploadHistoryError}
          />
        </Box>
      )}
      {errorModalForUpload && (
        <ErrorModalForUpload
          onModalClose={() => setErrorModalForUpload(null)}
          errorModalForUpload={errorModalForUpload}
        />
      )}
      <Frame>
        {isUploadHistoryLoading ? (
          <Frame>
            <Loading />
          </Frame>
        ) : uploadHistory.length === 0 ? (
          <EmptyState />
        ) : (
          <TableContainer>
            <DataTable
              columnContentTypes={[
                'text',
                'text',
                'text',
                'numeric',
                'numeric',
                'text',
                'text',
              ]}
              verticalAlign="center"
              headings={[
                <b key="upload-date">Upload Date</b>,
                <b key="upload-id">Upload ID</b>,
                <b key="upload-status">Upload Type</b>,
                <b key="prod-var-count">Product Variant Count</b>,
                <b key="unit-count">Unit Count</b>,
                <b key="manage-listings">Manage Listings</b>,
                <b key="unlink-inventory">Unlink</b>,
              ]}
              rows={uploadHistory?.map((history) =>
                formatUploadRow(
                  history,
                  canonicalRootUrl,
                  setErrorModalForUpload,
                  navigate,
                  preloadedSyncedVariants,
                ),
              )}
            />
          </TableContainer>
        )}
      </Frame>
    </>
  );
};

export default UploadHistoryView;
