export const PRODUCT_VARIANT_FORM_ID = 'PRODUCT_VARIANT_FORM_ID';

export const ORIGINAL_PRICE_FIELD = 'originalPrice';
export const CONDITION_FIELD = 'condition';
export const PRICE_FIELD = 'price';
export const SELLER_NOTES_FIELD = 'sellerNotes';
export const INTERNAL_NOTES_FIELD = 'internalNotes';
export const QUANTITY_FIELD = 'quantity';
export const SYNC_INVENTORY_WITH_TREET_FIELD = 'syncInventoryWithTreet';
export const TRANSFER_INVENTORY_TO_TREET_FIELD = 'transferInventoryToTreet';

export const REQUIRED_PRODUCT_VARIANT_FORM_FIELDS = [
  ORIGINAL_PRICE_FIELD,
  CONDITION_FIELD,
  PRICE_FIELD,
  QUANTITY_FIELD,
];
