import 'isomorphic-fetch';
import React, {useEffect, useMemo, useState} from 'react';
import {BrandService} from '../services/brand.service';
import {isEmpty} from 'lodash';

const Context = React.createContext(null);

// This context manages information related to the Treet shop.
const TreetShopContext = ({children}) => {
  const [isTreetShopLoading, setTreetShopLoading] = useState(false);
  const [treetShopConfig, setTreetShopConfig] = useState({});
  const [isContentfulShopConfigLoading, setIsContentfulShopConfigLoading] =
    useState(false);
  const [contentfulShopConfig, setContentfulShopConfig] = useState({});
  const brandService = useMemo(() => new BrandService(), []);

  useEffect(() => {
    if (isEmpty(treetShopConfig)) {
      setTreetShopLoading(true);
      brandService
        .getTreetShopConfig()
        .then(({data}) => {
          setTreetShopConfig(data);
        })
        .catch((reason) => {
          console.error(reason);
          setTreetShopConfig({});
        })
        .finally(() => setTreetShopLoading(false));
    }
  }, []);

  useEffect(() => {
    if (isEmpty(contentfulShopConfig)) {
      setIsContentfulShopConfigLoading(true);
      brandService
        .getContentfulConfig()
        .then(({data}) => {
          setContentfulShopConfig(data);
        })
        .catch((reason) => {
          console.error(reason);
          setContentfulShopConfig({});
        })
        .finally(() => setIsContentfulShopConfigLoading(false));
    }
  }, []);

  return (
    <Context.Provider
      value={{
        isTreetShopLoading,
        treetShopConfig,
        isContentfulShopConfigLoading,
        contentfulShopConfig,
      }}
    >
      {children}
    </Context.Provider>
  );
};

const useTreetShopContext = () => React.useContext(Context);

export {TreetShopContext, useTreetShopContext};
