import React, {useState} from 'react';
import {pluralize} from '../../utils/string';
import {ORIGINAL_PRICE_FIELD, PRICE_FIELD} from '../../constants/product-form';
import {MobileCancelMajor} from '@shopify/polaris-icons';
import {Button, Frame, Icon} from '@shopify/polaris';
import {Modal} from 'react-bootstrap';
import styled from 'styled-components';
import {Box} from '@material-ui/core';
import {roundAndTruncateToTwoDecimals} from '../../utils';

const StyledFooter = styled(Modal.Footer)`
  .Polaris-Button {
    background: #008060;
    color: white;
    font-size: 14px !important;
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
    line-height: 20px;
    border-radius: 4px;
    cursor: pointer;

    .Polaris-Button__Text {
      font-size: 14px !important;
    }
  }

  .Polaris-Button--disabled {
    background: transparent !important;
    box-shadow: none !important;
    border: 0.0625rem solid rgba(210, 213, 216, 1) !important;
    color: rgba(140, 145, 150, 1) !important;
  }

  .close {
    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }
`;

const StyledHeader = styled(Modal.Header)`
  font-family: 'SF Pro';
  font-size: 1.25rem !important;
  color: #202223;
  font-weight: 400;

  .close {
    font-size: 22px !important;
    cursor: pointer;
  }
`;

const StyledModalBody = styled(Modal.Body)``;

const PRICE_TYPE_PERCENT = 'PERCENT';
const PRICE_TYPE_AMOUNT = 'AMOUNT';

export const BulkEditTreetPriceModal = ({
  selectedResources,
  bulkEditModalType,
  setBulkEditModalType,
  selectedProductVariantsData,
  handleBulkUpdateSave,
  onKeyDown,
  isSelectAll,
  totalNumProducts,
}) => {
  let initialAmountValue;
  let initialPercentValue;
  // If there is a singular row selected, set that row's value to be the default
  if (selectedProductVariantsData.length === 1) {
    const variant = selectedProductVariantsData[0];
    const treetPriceAmount = variant[PRICE_FIELD];
    initialAmountValue = treetPriceAmount;
    initialPercentValue = Math.round(
      (treetPriceAmount / variant[ORIGINAL_PRICE_FIELD]) * 100,
    );
  }

  const [treetPriceAmount, setTreetPriceAmount] = useState(initialAmountValue);
  const [treetPricePercent, setTreetPricePercent] =
    useState(initialPercentValue);
  const [treetPriceType, setTreetPriceType] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    setBulkEditModalType(null);
  };

  const saveValues = async () => {
    setLoading(true);
    const isPercent = treetPriceType === PRICE_TYPE_PERCENT;
    const formValue = isPercent ? treetPricePercent : treetPriceAmount;

    if (formValue) {
      await handleBulkUpdateSave(
        selectedResources.reduce((acc, variantId) => {
          const variant = selectedProductVariantsData.find(
            (v) => v.id === variantId,
          );
          const newVariantPrice = isPercent
            ? roundAndTruncateToTwoDecimals(
                (formValue / 100) * variant[ORIGINAL_PRICE_FIELD],
              )
            : formValue;
          return {...acc, [variantId]: {[PRICE_FIELD]: newVariantPrice}};
        }, {}),
      );
    }
    setBulkEditModalType(false);
  };

  return (
    <Frame>
      <Modal
        dialogClassName="modal-custom-md"
        centered
        show={bulkEditModalType === PRICE_FIELD}
        onHide={handleClose}
      >
        <StyledHeader closeButton>
          <div>
            Set Treet Price For{' '}
            <b>
              {pluralize(
                'Row',
                isSelectAll ? totalNumProducts : selectedResources.length,
                true,
              )}
            </b>
          </div>
          <div className="close" onClick={handleClose}>
            <Icon source={MobileCancelMajor} color="base" />
          </div>
        </StyledHeader>
        <StyledModalBody className="sf-pro">
          <div className="modal-info">
            <Box display="flex" flexDirection="column">
              <label className="radio-button-modal">
                {/* Currently do not support PRICE_TYPE_PERCENT treet price bulk edits for the isSelectAll case. 
                This would need to be built out as separate endpoint logic since we cannot pre-calculate the
                dynamic values beyond the current page. */}
                {!isSelectAll && (
                  <Box display="flex" flexDirection="row" alignItems="center">
                    <input
                      type="radio"
                      value={PRICE_TYPE_PERCENT}
                      checked={treetPriceType === PRICE_TYPE_PERCENT}
                      onChange={(e) => {
                        setTreetPriceType(e.target.value);
                      }}
                    />
                    <Box
                      ml={2}
                      display="flex"
                      flexDirection="row"
                      alignItems="center"
                    >
                      <input
                        onChange={(e) => {
                          if (
                            treetPriceType === PRICE_TYPE_PERCENT ||
                            !treetPriceType
                          ) {
                            setTreetPricePercent(e.target.value);
                          }
                        }}
                        value={treetPricePercent}
                        type="number"
                        min="1"
                        max="100"
                      />
                      <Box ml={1}>% of retail price</Box>
                    </Box>
                  </Box>
                )}
              </label>
              <label className="radio-button-modal">
                <Box
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  my={1}
                >
                  <input
                    type="radio"
                    value={PRICE_TYPE_AMOUNT}
                    checked={treetPriceType === PRICE_TYPE_AMOUNT}
                    onChange={(e) => {
                      setTreetPriceType(e.target.value);
                    }}
                  />
                  <Box display="flex" flexDirection="row" alignItems="center">
                    <Box mx={2}>Use dollar amount: </Box>
                    <input
                      onChange={(e) => {
                        if (
                          treetPriceType === PRICE_TYPE_AMOUNT ||
                          !treetPriceType
                        ) {
                          setTreetPriceAmount(e.target.value);
                        }
                      }}
                      value={treetPriceAmount}
                      type="number"
                      min="1"
                      onKeyDown={onKeyDown}
                      style={{width: '60px'}}
                    />
                  </Box>
                </Box>
              </label>
            </Box>
          </div>
        </StyledModalBody>
        <StyledFooter>
          <Button
            loading={loading}
            disabled={
              !(
                (treetPriceType === PRICE_TYPE_AMOUNT && treetPriceAmount) ||
                (treetPriceType === PRICE_TYPE_PERCENT && treetPricePercent)
              )
            }
            type="button"
            onClick={() => saveValues()}
          >
            Set Treet Price
          </Button>
        </StyledFooter>
      </Modal>
    </Frame>
  );
};
