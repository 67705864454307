import React from 'react';
import Products from './Products';
import {PRODUCT_SUBMIT_ACTION} from '../util/constants';

const CrossPostedInventory = () => {
  return (
    <Products productSubmitAction={PRODUCT_SUBMIT_ACTION.UNLINK_CROSS_POST} />
  );
};

export default CrossPostedInventory;
