import {datadogLogs} from '@datadog/browser-logs';

// Datadog Browser Logging
export const setupDatadogBrowserLogs = () => {
  datadogLogs.init({
    clientToken: DATADOG_BROWSER_LOGS_CLIENT_TOKEN,
    site: 'datadoghq.com',
    forwardErrorsToLogs: true,
    sessionSampleRate: 100,
    forwardConsoleLogs: 'all',
    env: DEPLOYED_ENV,
    service: 'treet-resale-app',
    trackSessionAcrossSubdomains: true,
    silentMultipleInit: true,
  });

  datadogLogs.setGlobalContextProperty('env', DEPLOYED_ENV);
};
