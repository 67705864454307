import React from 'react';
import {pluralize} from '../../utils/string';
import {EditNumberFieldModal} from '../utils/edit-number-field.modal';
import {ORIGINAL_PRICE_FIELD} from '../../constants/product-form';

export const BulkEditOriginalPriceModal = ({
  selectedResources,
  bulkEditModalType,
  setBulkEditModalType,
  selectedProductVariantsData,
  handleBulkUpdateSave,
  onKeyDown,
  isSelectAll,
  totalNumProducts,
}) => {
  return (
    <EditNumberFieldModal
      title={
        <>
          Set Original Price For{' '}
          <b>
            {pluralize(
              'Row',
              isSelectAll ? totalNumProducts : selectedResources.length,
              true,
            )}
          </b>
        </>
      }
      subtitle="This will set the dollar amount price of the original price of the item (as listed on your main retail site). We recommend not changing this value unless necessary."
      buttonText="Save Original Price"
      inputHelperText="Use Dollar Amount:"
      isOpen={bulkEditModalType === ORIGINAL_PRICE_FIELD}
      selectedProducts={selectedResources}
      handleClose={() => {
        setBulkEditModalType(null);
      }}
      initialValue={
        // If there is a singular row selected, set that row's value to be the default
        selectedProductVariantsData.length === 1
          ? selectedProductVariantsData[0][ORIGINAL_PRICE_FIELD]
          : null
      }
      handleSubmit={async ({variantIds, value}) => {
        if (value) {
          await handleBulkUpdateSave(
            variantIds.reduce((acc, variantId) => {
              return {...acc, [variantId]: {[ORIGINAL_PRICE_FIELD]: value}};
            }, {}),
          );
        }
        setBulkEditModalType(false);
      }}
      onKeyDown={onKeyDown}
    />
  );
};
