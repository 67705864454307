import {gql, useMutation, useQuery} from '@apollo/client';
import {useAppBridge} from '@shopify/app-bridge-react';
import {ChannelMenu, Redirect} from '@shopify/app-bridge/actions';
import {
  Checklist,
  FeatureListCard,
  ProductStatusSection,
  SettingsPage,
} from '@shopify/channels-ui';
import {Banner, Button, Card, Link, SkeletonBodyText} from '@shopify/polaris';
import React, {useCallback, useEffect, useState} from 'react';
import {Col, Row} from 'react-bootstrap';
import {useNavigate} from 'react-router-dom';
import {
  createChannelMenuConfig,
  MENU_ITEMS,
  MENU_ITEMS_URL_MAP,
} from '../../../utils/navigation';
import {ProductService} from '../../../services';
import {useTreetShopContext} from '../../../context/treet-shop-context';
import pageWrapper from '../foundation/PageWrapper';

const productService = new ProductService();

const ACCOUNT_PAGE_QUERY = gql`
  query AccountPageQuery {
    adminBrand {
      id
      store_id
      meetsRequirements
      domain
      accountConnection
      onboardingCompleted
    }
  }
`;

// TODO: subscription object doesnt work in gql. Need to double check on prod
const SETTINGS_CHECKLIST_QUERY = gql`
  query settingsChecklistQuery {
    adminBrand {
      id
      config {
        hasStorefront
        shipsToCanada
        cadEnabled
        hasPolicies
      }
    }
  }
`;

const CONNECT_ACCOUNT_MUTATION = gql`
  mutation ConnectAccount {
    connectAccount {
      id
      accountConnection
    }
  }
`;

const DISCONNECT_ACCOUNT_MUTATION = gql`
  mutation DisconnectAccount {
    disconnectAccount {
      id
      accountConnection
    }
  }
`;

const AccountCardSkeleton = () => (
  <Card sectioned>
    <SkeletonBodyText lines={4} />
  </Card>
);

const AccountCardSync = ({connect, data, onConnectionChange}) => {
  if (!data) {
    return <AccountCardSkeleton />;
  }

  const accountCardTitle = connect
    ? 'You are connected'
    : 'Connect your Treet account';

  return (
    <Card title={accountCardTitle} sectioned>
      <div>
        <Row>
          <Col>
            <div className="d-flex">
              {connect ? (
                <>
                  <img
                    style={{width: '50px'}}
                    src="https://media.istockphoto.com/vectors/avatar-5-vector-id1131164548?k=20&m=1131164548&s=612x612&w=0&h=ODVFrdVqpWMNA1_uAHX_WJu2Xj3HLikEnbof6M_lccA="
                    alt="123"
                    width="50"
                    height="50"
                  />
                  <h4 className="mx-4 my-auto">{data.adminBrand.domain}</h4>
                </>
              ) : (
                <h4>Your account is disconnected from Treet</h4>
              )}
            </div>
          </Col>
          <Col className="d-flex flex-row-reverse">
            <div>
              <Button primary onClick={onConnectionChange}>
                {connect ? 'Disconnect' : 'Connect'}
              </Button>
            </div>
          </Col>
        </Row>
      </div>
    </Card>
  );
};

const AccountCardChecklist = () => {
  const {data, loading} = useQuery(SETTINGS_CHECKLIST_QUERY);
  const app = useAppBridge();

  if (loading || !data) {
    return <AccountCardSkeleton />;
  }

  const {
    adminBrand: {
      config: {hasStorefront, hasPolicies},
    },
  } = data;
  const redirect = Redirect.create(app);

  const items = [
    {
      label: 'Create an online store',
      completed: hasStorefront,
      action: {
        onAction: () => {
          redirect.dispatch(Redirect.Action.ADMIN_PATH, {
            path: '/settings/channels',
          });
        },
      },
    },
    // {
    //   label: 'Add a privacy policy and return policy',
    //   completed: hasPolicies,
    //   action: {
    //     onAction: () => {
    //       redirect.dispatch(Redirect.Action.ADMIN_PATH, {
    //         path: '/settings/legal',
    //       });
    //     },
    //   },
    // },
  ];

  return (
    <Card>
      <Checklist
        completeLabel="Your shop meets Treet's requirements"
        items={items}
      />
    </Card>
  );
};

const AccountCardMarketplace = ({data, isActive}) => {
  const {treetShopConfig} = useTreetShopContext();
  const {canonicalRootUrl} = treetShopConfig || {};

  if (!data) {
    return <AccountCardSkeleton />;
  }

  if (isActive) {
    // TODO: description should be a string
    return (
      <Card>
        <FeatureListCard
          features={[
            {
              title: 'Treet Resale',
              description: (
                <div>
                  {!!canonicalRootUrl && (
                    <>
                      <a
                        target="_blank"
                        href={canonicalRootUrl}
                        rel="noreferrer"
                      >
                        {canonicalRootUrl}
                      </a>
                      <br />
                      <br />
                    </>
                  )}
                  Customers can discover your brand and purchase products
                  directly on your Treet marketplace.
                </div>
              ),
              badge: {
                children: isActive ? 'Active' : 'Inactive',
                status: 'success',
              },
            },
          ]}
        />
      </Card>
    );
  }

  return (
    <Card>
      <Banner
        status="critical"
        title="Your Treet Resale items are inactive"
        action={{
          content: 'View details',
          url: MENU_ITEMS_URL_MAP[MENU_ITEMS.ACCOUNT],
        }}
      >
        <p>
          Customers will not be able to purchase your items from your Treet
          marketplace.
        </p>
      </Banner>
    </Card>
  );
};

const AccountComponent = () => {
  const {data} = useQuery(ACCOUNT_PAGE_QUERY);
  const app = useAppBridge();
  const navigate = useNavigate();
  const [connect, setConnect] = useState(false);

  const [connectAccount] = useMutation(CONNECT_ACCOUNT_MUTATION);
  const [disconnectAccount] = useMutation(DISCONNECT_ACCOUNT_MUTATION);

  const handleAction = useCallback(() => {
    setConnect((connect) => !connect);

    if (!connect) {
      connectAccount();
    } else {
      disconnectAccount();
    }
  }, [connect]);

  const onConnectionChange = () => handleAction();

  useEffect(() => {
    if (data && data.adminBrand.accountConnection) {
      setConnect(true);
    } else {
      // TODO: potential broken logic. Probably needs to call reconnection only if `data` is exist
      setConnect(false);
    }
    if (data && !data.adminBrand.onboardingCompleted) {
      navigate(MENU_ITEMS_URL_MAP[MENU_ITEMS.OVERVIEW]);
    }
  }, [data]);

  const [dibzProdInfo, setDibzProdInfo] = useState({});
  useEffect(() => {
    productService.getStat().then((data) => {
      setDibzProdInfo(data.data.data);
    });
  }, []);

  ChannelMenu.create(app, createChannelMenuConfig(app, MENU_ITEMS.ACCOUNT));

  const {onDibzProducts = 0, notOnDibzProducts = 0} = dibzProdInfo;

  const productsCountOnDibz = `${onDibzProducts} products are on Dibz Marketplace`;
  const productsCountNotOnDibz = `${notOnDibzProducts} products are not on Dibz Marketplace`;

  const productsCountAvailable = `${
    onDibzProducts + notOnDibzProducts
  } products available`;

  return (
    <div>
      <SettingsPage title="">
        <SettingsPage.Section
          title="Treet Account"
          description="Sync your account to the Treet"
        >
          <AccountCardSync
            connect={connect}
            data={data}
            onConnectionChange={onConnectionChange}
          />
        </SettingsPage.Section>

        <SettingsPage.Section
          title="App Requirements"
          description="This checklist ensures that your store meets Treet requirements."
        >
          <AccountCardChecklist />
        </SettingsPage.Section>

        {/* {data && !data.adminBrand.meetsRequirements && (
          <SettingsPage.Section title="" description="">
            <Banner
              status="critical"
              title="Your shop no longer meets Treet requirements"
              action={{
                content: 'View details',
                url: MENU_ITEMS_URL_MAP[MENU_ITEMS.ACCOUNT],
              }}
            >
              <p>
                Your shop no longer meets Treet requirements. To continue
                selling, please ensure your store meets all requirements.
              </p>
            </Banner>
          </SettingsPage.Section>
        )} */}

        {/*  TODO Replace with treet status stats */}
        {/* <SettingsPage.Section title="" description="Product status on Dibz">
          {!data ? (
            <AccountCardSkeleton />
          ) : (
            <ProductStatusSection
              manageAction={{
                content: 'Manage availability',
                external: true,
                url: `https://${data.adminBrand.domain}/admin/products`,
              }}
              summary={productsCountAvailable}
              productStatuses={[
                {
                  badge: {
                    children: 'Published',
                    status: 'success',
                  },
                  label: {
                    content: productsCountOnDibz,
                    external: true,
                    url: `https://${data.adminBrand.domain}/admin/products?selectedView=all&published_status=Active%3Avisible`,
                  },
                },
                {
                  badge: {
                    children: 'Not published',
                    status: 'attention',
                  },
                  label: {
                    content: productsCountNotOnDibz,
                    external: true,
                    url: `https://${data.adminBrand.domain}/admin/products?selectedView=all&published_status=Inactive%3Avisible`,
                  },
                },
              ]}
            />
          )}
        </SettingsPage.Section> */}

        <SettingsPage.Section title="" description="Manage your app features">
          <AccountCardMarketplace data={data} isActive={connect} />
        </SettingsPage.Section>

        <SettingsPage.Section
          title="Terms of Service"
          description="You can view the Treet terms of service here at any time."
        >
          <Card sectioned>
            <p>
              You have agreed to the&nbsp;
              <Link
                url="https://treet.zendesk.com/hc/en-us/articles/17510136684567-Terms-of-Service-"
                external
              >
                Treet Terms of Service
              </Link>
              .
            </p>
          </Card>
        </SettingsPage.Section>
      </SettingsPage>
    </div>
  );
};

const Account = pageWrapper(AccountComponent);
export default Account;
