import {ApiClient} from '../utils/api/api-client';
import {EndpointService} from '../utils/api/endpoints';
// const config = require("../../config");

const apiClient = new ApiClient();

export class BrandService {
  async getShippingOptions() {
    return apiClient.call(EndpointService.getShippingOptions);
  }

  async updateDefaultShipping(id) {
    return apiClient.call(EndpointService.updateDefaultShipping(id));
  }

  async getBrandDescription() {
    return apiClient.call(EndpointService.getBrandDescription);
  }

  async updateBrandDescription(descObj) {
    return apiClient.call(EndpointService.updateBrandDescription, descObj);
  }

  async getBrandMission() {
    return apiClient.call(EndpointService.getBrandMission);
  }

  async updateBrandMission(missionObj) {
    return apiClient.call(EndpointService.updateBrandMission, missionObj);
  }

  async uploadBrandImage(imageObj) {
    return apiClient.call(EndpointService.addBrandImage, imageObj);
  }

  async getBrandImages() {
    return apiClient.call(EndpointService.getBrandImages);
  }

  async deleteBrandImage(imageId) {
    return apiClient.call(EndpointService.deleteBrandImage(imageId));
  }

  async getTreetShopConfig() {
    return apiClient.call(EndpointService.getTreetShopConfig);
  }

  async getUploadHistory(treetId) {
    return apiClient.call(EndpointService.getUploadHistory(treetId));
  }

  async getContentfulConfig() {
    return apiClient.call(EndpointService.getContentfulConfig);
  }

  async getEnabledPermissionsForTreet(treetId) {
    return apiClient.call(
      EndpointService.getEnabledPermissionsForTreet(treetId),
    );
  }
}
