/* eslint-disable react/no-children-prop */
import {Banner, Button, Frame, Tooltip} from '@shopify/polaris';
import React, {useState} from 'react';
import {useProductContext} from '../../context';
import {useControlData} from './use-control-data.hook';
import {useFormState} from 'react-final-form';
import {stringifyKey} from '../../utils/form';
import {Box} from '@material-ui/core';
import {pluralize} from '../../utils/string';
import {
  QUANTITY_FIELD,
  REQUIRED_PRODUCT_VARIANT_FORM_FIELDS,
} from '../../constants/product-form';
import ConditionalWrapper from '../utils/conditionalWrapper';
import {ConfirmTreetSubmitModal} from './confirm-treet-submit.modal';
import {PRODUCT_VARIANTS_PAGE_SIZE} from '../../views/product/utils';
import {PRODUCT_SUBMIT_ACTION} from '../../app/src/util/constants';
import {Typography} from '@material-ui/core';

const getFooterText = (productSubmitAction) => {
  switch (productSubmitAction) {
    case PRODUCT_SUBMIT_ACTION.UNLINK_CROSS_POST:
      return {
        title: 'Unlink',
        subtitle:
          'Treet Listings will remain open until they are closed on Treet.',
      };
    case PRODUCT_SUBMIT_ACTION.TRANSFER:
      return {
        title: 'Transfer',
        subtitle: 'Inventory will be removed from Shopify.',
      };
    case PRODUCT_SUBMIT_ACTION.CROSS_POST:
      return {
        title: 'Cross-Post',
        subtitle: 'Shopify/Treet Inventory synced.',
      };
    case PRODUCT_SUBMIT_ACTION.DUPLICATE:
    default:
      return {
        title: 'Duplicate',
        subtitle:
          'Shopify inventory will not be modified, new inventory will be created on Treet.',
      };
  }
};

const MAX_NUM_ROWS_TO_LIST = 500;

const ZERO_QUANTITY_ITEM_ERROR =
  'Listings must be created with a quantity greater than zero (0). Please double check your data and ensure that no rows have a quantity of 0.';
const MAX_ROWS_EXCEEDED_ERROR =
  'A maximum of 500 product variants can be listed at once. Please reduce the number of selected rows and try again.';

export const DataControlFooter = ({
  className,
  initialData = [],
  selectedResources = [],
  setFilteredProducts,
  filteredProductsVariants,
  handleSelectionChange,
  productSubmitAction,
  hasInitSelected,
}) => {
  const {totalNumProducts, isLoading} = useProductContext();
  const {
    handleUpdateStatus,
    toastMessage,
    setToastMessage,
    setErrorMessage,
    errorMessage,
    isSelectAll,
  } = useControlData({
    initialData,
    setFilteredProducts,
    selectedResources,
    filteredProducts: filteredProductsVariants,
    handleSelectionChange,
    hasInitSelected,
  });

  const {values: formValues} = useFormState();

  const [isConfirmTreetSubmitModalOpen, setIsConfirmTreetSubmitModalOpen] =
    useState(false);

  const isUnlink =
    productSubmitAction === PRODUCT_SUBMIT_ACTION.UNLINK_CROSS_POST;

  const hasZeroQuantityItem = filteredProductsVariants
    .filter(({id}) => selectedResources.includes(id))
    .some(
      ({id}) => Number(formValues[stringifyKey(id)]?.[QUANTITY_FIELD]) <= 0,
    );

  const totalNumSelectedRows = filteredProductsVariants.filter(({id}) =>
    selectedResources.includes(id),
  )?.length;

  const selectedProductVariantsData = filteredProductsVariants
    .filter(({id}) => selectedResources.includes(id))
    .map(({id}) => ({id, ...formValues[stringifyKey(id)]}));

  const numPreviouslyListedOnTreet =
    filteredProductsVariants
      .filter(({id}) => selectedResources.includes(id))
      .filter(({on_treet}) => on_treet)?.length || 0;

  const handleSubmitProducts = async () => {
    if (hasZeroQuantityItem && !isUnlink) {
      setErrorMessage(ZERO_QUANTITY_ITEM_ERROR);
      return;
    }
    if (totalNumSelectedRows > MAX_NUM_ROWS_TO_LIST) {
      setErrorMessage(MAX_ROWS_EXCEEDED_ERROR);
      return;
    }

    await handleUpdateStatus(selectedProductVariantsData, productSubmitAction);
  };

  const areProductsSelected = !!selectedResources.length;
  const totalSelectedUnits = selectedProductVariantsData.reduce(
    (acc, variant) => {
      return Number(variant[QUANTITY_FIELD]) + acc;
    },
    0,
  );
  // TODO add in more robust form validation
  const areAllRowsValid = selectedProductVariantsData.every((variant) => {
    return REQUIRED_PRODUCT_VARIANT_FORM_FIELDS.every(
      (field) => !!variant[field],
    );
  });

  const numSelectedItems = isSelectAll
    ? totalNumProducts
    : selectedResources.length;
  const itemsText = pluralize('Item', numSelectedItems, true);

  const {title, subtitle} = getFooterText(productSubmitAction);
  const descriptionText = `${title} ${numSelectedItems} Product Variants`;
  const buttonText = `${title} ${itemsText}`;

  return (
    <div className={`DataControl ${className || ''}`}>
      {errorMessage && (
        <Box>
          <Banner
            status="critical"
            title={errorMessage}
            onDismiss={() => setErrorMessage(null)}
          />
        </Box>
      )}
      {toastMessage && (
        <Banner
          content={toastMessage}
          status={toastMessage.includes('Error') ? 'critical' : 'info'}
          title={toastMessage}
          onDismiss={() => setToastMessage('')}
        />
      )}
      <Frame>
        {/* ACTION BUTTON */}
        <ConditionalWrapper
          condition={isSelectAll}
          wrapper={(children) => (
            <Tooltip
              // TODO (TREET-6223) Enable multi-page addToSys
              content={`A maximum of ${PRODUCT_VARIANTS_PAGE_SIZE} listings can be created in a single upload.`}
              preferredPosition="above"
            >
              {children}
            </Tooltip>
          )}
        >
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            py={1}
            px={2}
            alignItems="center"
          >
            <Box display="flex" flexDirection="column">
              <Typography variant="h3">{descriptionText}</Typography>
              <Typography variant="body2">{subtitle}</Typography>
            </Box>
            <Box width="30%">
              <Button
                className="btn filter-btn"
                type="button"
                primary
                fullWidth
                onClick={() => {
                  setIsConfirmTreetSubmitModalOpen(true);
                }}
                disabled={
                  !(
                    areProductsSelected &&
                    (isUnlink || totalSelectedUnits > 0) &&
                    areAllRowsValid &&
                    !isSelectAll
                  )
                }
              >
                {buttonText}
              </Button>
            </Box>
          </Box>
        </ConditionalWrapper>
        {isConfirmTreetSubmitModalOpen && (
          <ConfirmTreetSubmitModal
            totalSelectedUnits={totalSelectedUnits}
            numPreviouslyListedOnTreet={numPreviouslyListedOnTreet}
            onSubmit={handleSubmitProducts}
            selectedResources={selectedResources}
            isConfirmTreetSubmitModalOpen={isConfirmTreetSubmitModalOpen}
            onClose={() => {
              setIsConfirmTreetSubmitModalOpen(false);
            }}
            isSelectAll={isSelectAll}
            totalNumProducts={totalNumProducts}
            isUnlink={isUnlink}
          />
        )}
      </Frame>
    </div>
  );
};
