import React from 'react';
import * as Sentry from '@sentry/react';

// Docs: https://docs.sentry.io/platforms/javascript/guides/react/features/error-boundary/

const Fallback = () => {
  return <p>An error has occurred. Please refresh to try again.</p>;
};

const SentryErrorBoundary = ({children}) => {
  if (SENTRY_FRONTEND_DSN) {
    return (
      <Sentry.ErrorBoundary fallback={<Fallback />} showDialog>
        {children}
      </Sentry.ErrorBoundary>
    );
  }

  console.log('Front-end Sentry disabled. Errors will not be sent.');
  return children;
};

export default SentryErrorBoundary;
