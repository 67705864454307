// Treet Status filter
// Keep LISTED_ON_TREET_FILTER constant in sync with /server/routes/products/functions/getProducts.js
export const LISTED_ON_TREET_FILTER = 'Previously Listed On Treet';
export const READY_FOR_TREET_FILTER = 'Ready To Be Listed On Treet';
export const STATUS_FILTERS = [READY_FOR_TREET_FILTER, LISTED_ON_TREET_FILTER];

// Treet Conditions
export const NEW_WITH_TAGS = 'new_with_tags';
export const NEW_WITHOUT_TAGS = 'new_without_tags';
export const EXCELLENT = 'excellent';
export const GOOD = 'good';
export const MINOR_DEFECT = 'minor_defect';

// Variant model treet fields
export const VARIANT_CONDITION_FIELD = 'condition';
export const VARIANT_TREET_ORIGINAL_PRICE_FIELD = 'treet_original_price';
export const VARIANT_QUANTITY_FIELD = 'treet_uploaded_quantity';
export const VARIANT_INTERAL_NOTES_FIELD = 'internal_brand_notes';
export const VARIANT_TREET_PRICE_FIELD = 'treet_price';

export const TREET_APP_TAG = 'treet-app';

export const UPLOAD_TYPE = {
  CrossPost: 'Cross-Post',
  Transfer: 'Transfer',
  Duplicate: 'Duplicate',
};
