import {FooterHelp} from '@shopify/polaris';
import React from 'react';

export const PageFooter = () => {
  return (
    <FooterHelp>
      Learn more about&nbsp;
      <a target="_blank" href="https://www.treet.co/" rel="noreferrer">
        Treet Resale
      </a>
    </FooterHelp>
  );
};
