import React from 'react';
import {Box} from '@material-ui/core';
import {IMAGE_PLACEHOLDER} from '../../utils/images';

export const ProductVariantImage = (data) => {
  const {isDisabled} = data;
  const {
    Image: variantImageObject,
    Product,
    name: variantName,
  } = data.variantWithProduct;
  const {image: variantImage} = variantImageObject || {};
  const {image: productImage} = Product || {};

  const disabledStyles = isDisabled ? {opacity: 0.4} : {};

  return (
    <Box m={1} width="48px">
      <img
        src={variantImage || productImage || IMAGE_PLACEHOLDER}
        alt={variantName}
        style={{maxHeight: '80px', ...disabledStyles}}
      />
    </Box>
  );
};
