import 'isomorphic-fetch';
import {useEffect, useMemo, useState} from 'react';
import {BrandService} from '../../../services/brand.service';
import {isEmpty} from 'lodash';

/**
 *
 * @param {*} data
 * @returns {*} { uploadDate, uploadStatus, uploadId, numListings, totalInventory, errorMessage }[]
 */
const normalizeUploadHistoryData = (data) => {
  const {uploads} = data;
  return uploads
    .map((upload) => {
      const {listingsData, uploadId, partnerId, uploadType} =
        upload?.data?.data || {};
      if (partnerId !== 'SHOPIFY_RESALE_APP') {
        return;
      }
      return {
        uploadStatus: upload.status,
        uploadDate: upload.createdAt,
        uploadId,
        uploadType,
        numListings: listingsData.length,
        totalInventory: listingsData.reduce(
          (prev, current) => (prev?.inventory || 0) + current?.inventory,
          0,
        ),
        isSyncedWithTreet: listingsData.some(
          (listingData) => listingData.syncShopifyInventory,
        ),
        productVariantsByShopVariantId: listingsData.map(
          (listingData) => listingData.productIdentifier,
        ),
        errorMessage: upload.error,
      };
    })
    .filter((upload) => !!upload);
};

// This hook grabs the information for upload history.
const useUploadHistory = (treetId) => {
  const [isUploadHistoryLoading, setUploadHistoryLoading] = useState(false);
  const [uploadHistoryError, setUploadHistoryError] = useState(null);
  const [uploadHistory, setUploadHistory] = useState([]);
  const brandService = useMemo(() => new BrandService(), []);

  const dismissUploadHistoryError = () => setUploadHistoryError(null);

  useEffect(() => {
    if (isEmpty(uploadHistory) && !!treetId) {
      setUploadHistoryLoading(true);
      setUploadHistoryError(null);
      brandService
        .getUploadHistory(treetId)
        .then(({data}) => {
          setUploadHistory(normalizeUploadHistoryData(data));
        })
        .catch((response) => {
          console.error(response);
          setUploadHistory([]);
          setUploadHistoryError(
            response?.data?.errorMessage ||
              'There was an error retrieving your uploads.',
          );
        })
        .finally(() => setUploadHistoryLoading(false));
    }
  }, [treetId]);

  return {
    uploadHistory,
    isUploadHistoryLoading,
    uploadHistoryError,
    dismissUploadHistoryError,
  };
};

export default useUploadHistory;
