import {ApiClient} from '../utils/api/api-client';
import {EndpointService} from '../utils/api/endpoints';

const apiClient = new ApiClient();

export class CommandCenterService {
  async getCloudinarySignature(options) {
    return apiClient.call(EndpointService.getCloudinarySignature, options);
  }

  async removeImageFromCloudinary(publicId) {
    return apiClient.call(EndpointService.removeImageFromCloudinary, {
      publicId,
    });
  }
}
