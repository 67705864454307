const isVowel = (char) => {
  return /^[aeiou]$/i.test(char);
};

/**
 * @param noun Word to be pluralized
 * @param count Number used to maybe pluralize the noun
 * @param includeCount Whether or not to include the count in the result
 * @returns Pluralized string, possibly with count
 */
export const pluralize = (noun, count, includeCount = false) => {
  const nounLastChar = noun.slice(-1);
  const nounSecondToLastChar = noun.charAt(noun.length - 2);

  let pluralNoun;
  if (count === 1) {
    pluralNoun = noun;
  } else if (!isVowel(nounSecondToLastChar) && nounLastChar === 'y') {
    const nounUpToLastChar = noun.slice(0, -1);
    pluralNoun = `${nounUpToLastChar}ies`;
  } else if (
    nounLastChar === 's' ||
    nounLastChar === 'x' ||
    nounLastChar === 'z'
  ) {
    pluralNoun = `${noun}es`;
  } else {
    pluralNoun = `${noun}s`;
  }

  return includeCount ? `${count} ${pluralNoun}` : pluralNoun;
};
