import {Frame, Icon} from '@shopify/polaris';
import React from 'react';
import {Modal} from 'react-bootstrap';
import styled from 'styled-components';
import {MobileCancelMajor} from '@shopify/polaris-icons';
import {Box, Typography} from '@material-ui/core';

const StyledHeader = styled(Modal.Header)`
  font-family: 'SF Pro';
  font-size: 1.25rem !important;
  color: #202223;
  font-weight: 400;

  .close {
    font-size: 22px !important;
    cursor: pointer;
  }
`;

const StyledModalBody = styled(Modal.Body)`
  .modal-subtext {
    font-family: 'SF Pro';
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    color: #6d7175;
  }

  .modal-info {
    textarea {
      width: 90%;
      margin-right: 2%;
      border: 1px solid #a7acb1;
      border-radius: 4px;
    }
  }
`;

export const LearnMoreModal = ({handleClose, isOpen}) => {
  return (
    <Frame>
      <Modal centered show={isOpen} onHide={handleClose}>
        <StyledHeader closeButton>
          <Typography variant="h3" display="inline">
            Treet Listings Upload
          </Typography>
          <div className="close" onClick={handleClose}>
            <Box height="20px" width="20px">
              <Icon source={MobileCancelMajor} color="base" />
            </Box>
          </div>
        </StyledHeader>
        <StyledModalBody className="sf-pro">
          <div className="modal-info">
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="space-around"
            >
              <Box my={1}>
                <Typography variant="h4">Step 1: Choose Upload Type</Typography>
                <Typography variant="body1">
                  Choose the upload type that fits how you manage inventory and
                  fulfill orders.
                </Typography>
              </Box>
              <Box my={1}>
                <Typography variant="h4">Step 2: Select Products</Typography>
                <Typography variant="body1">
                  Select product variants in bulk and add Treet-specific
                  information.
                </Typography>
              </Box>
              <Box my={1}>
                <Typography variant="h4">
                  Step 3: Listings Are Live On Treet
                </Typography>
                <Typography variant="body1">
                  Listings will be available for purchase on your Treet site
                  immediately.
                </Typography>
              </Box>
            </Box>
            <Box my={2}>
              <Typography variant="body2">
                And repeat! You can upload as many times as you want and use
                different upload types each time.
              </Typography>
            </Box>
          </div>
        </StyledModalBody>
      </Modal>
    </Frame>
  );
};
