import {Frame} from '@shopify/polaris';
import React from 'react';
import {BrandService} from '../../services/brand.service';

const ProfileInfoItems = ({
  detail,
  shippingOption,
  setShippingOption,
  setToastMessage,
}) => {
  const brandService = new BrandService();
  const shippingOnClick = async (option) => {
    await brandService
      .updateDefaultShipping(option)
      .then(() => {
        const updatedOptions = shippingOption.map((detail) => ({
          ...detail,
          default: detail.id === option,
        }));
        setShippingOption(updatedOptions);
        setToastMessage('Default shipping updated successfully');
      })
      .catch((error) => {
        console.log({error});
        setToastMessage('update failed');
      });
  };

  return (
    <Frame>
      <div
        key={detail.id}
        className={`
          ${
            detail.default ? 'active-container' : ''
          } d-flex justify-content-between section p-3`}
      >
        <div className="d-flex gap-2 align-items-start flex-column">
          <h5 style={{fontSize: '1rem '}} className="mb-0">
            {detail?.name}
          </h5>
          <p style={{fontSize: '0.85rem '}} className="mb-0 font-subdued">
            {detail?.Location?.address1}
          </p>
          <p style={{fontSize: '0.85rem '}} className="mb-0 font-subdued">
            {detail?.Location?.name}
          </p>
          <p style={{fontSize: '0.85rem '}} className="mb-0 font-subdued">
            {detail?.Location?.city}, {detail?.Location?.province_code}{' '}
            {detail?.Location?.zip} {detail?.Location?.country_name}
          </p>
        </div>

        <div className="d-flex flex-column justify-content-center">
          <label
            onClick={() => shippingOnClick(detail.id)}
            className="d-flex align-items-center gap-3 shipping-option"
          >
            <input type="radio" checked={detail.default} readOnly />
            Default
          </label>
        </div>
      </div>
    </Frame>
  );
};

export default ProfileInfoItems;
