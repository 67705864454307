import {Field, useField} from 'react-final-form';
import React from 'react';

const FieldTextInputComponent = (props) => {
  const {id, input: inputProps, ...rest} = props;

  return <input id={id} {...inputProps} {...rest} />;
};

export const FieldTextInput = (props) => {
  const {input, meta} = useField(props.name);
  return (
    <Field
      component={FieldTextInputComponent}
      input={input}
      meta={meta}
      {...props}
    />
  );
};
