import {Icon, Thumbnail} from '@shopify/polaris';
import React from 'react';
import PropTypes from 'prop-types';
import {CircleCancelMajor} from '@shopify/polaris-icons';
import styled from 'styled-components';

const Container = styled.div`
  margin-left: 1%;
  margin-right: 1%;
`;

const RelativeIcon = styled.div`
  position: relative;
  left: 2em;
  z-index: 2;
  cursor: pointer;
`;

const RelativeThumbnail = styled.div`
  position: relative;
  margin-top: -10%;
  z-index: 1;
`;

export const RowItem = ({image, handleRemoveImage}) => {
  return (
    <Container>
      <RelativeIcon onClick={() => handleRemoveImage(image.hosting_id)}>
        <Icon source={CircleCancelMajor} color="base" />
      </RelativeIcon>
      <RelativeThumbnail>
        <Thumbnail source={image.secure_url} />
      </RelativeThumbnail>
    </Container>
  );
};

RowItem.propTypes = {
  handleRemoveImage: PropTypes.func,
  image: PropTypes.object,
};
