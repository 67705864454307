import React, {useState} from 'react';
import {pluralize} from '../../utils/string';
import {MobileCancelMajor} from '@shopify/polaris-icons';
import {Banner, Button, Frame, Icon, Link} from '@shopify/polaris';
import {Modal} from 'react-bootstrap';
import styled from 'styled-components';
import {Box} from '@material-ui/core';
import {useTreetShopContext} from '../../context/treet-shop-context';

const StyledFooter = styled(Modal.Footer)`
  .Polaris-Button {
    background: #008060;
    color: white;
    font-size: 14px !important;
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
    line-height: 20px;
    border-radius: 4px;
    cursor: pointer;

    .Polaris-Button__Text {
      font-size: 14px !important;
    }
  }

  .Polaris-Button--disabled {
    background: transparent !important;
    box-shadow: none !important;
    border: 0.0625rem solid rgba(210, 213, 216, 1) !important;
    color: rgba(140, 145, 150, 1) !important;
  }

  .close {
    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }
`;

const StyledHeader = styled(Modal.Header)`
  font-family: 'SF Pro';
  font-size: 1.25rem !important;
  color: #202223;
  font-weight: 400;

  .close {
    font-size: 22px !important;
    cursor: pointer;
  }
`;

const StyledModalBody = styled(Modal.Body)`
  .modal-subtext {
    font-family: 'SF Pro';
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    color: #6d7175;
  }
`;

export const ConfirmTreetSubmitModal = ({
  totalSelectedUnits,
  onSubmit,
  selectedResources,
  isConfirmTreetSubmitModalOpen,
  numPreviouslyListedOnTreet,
  onClose,
  isSelectAll,
  totalNumProducts,
  isUnlink,
}) => {
  const [loading, setLoading] = useState(false);
  const {treetShopConfig} = useTreetShopContext();

  const totalNumSelectedProductVariants = selectedResources.length;

  const handleClose = () => {
    onClose();
  };

  const handleSubmit = async () => {
    setLoading(true);
    onSubmit();
    onClose();
  };

  const modalContent = isUnlink
    ? {
        title: 'Remove Cross Post',
        body: (
          <>
            <p>
              Are your sure you want to unlink {totalSelectedUnits} Treet items
              ({totalNumSelectedProductVariants} product variants) from your
              Shopify inventory?
            </p>
            <p>
              These items will remain on Treet, but will no longer affect the
              quantity of your Shopify inventory, which you can manage under
              Products in Shopify.
            </p>
          </>
        ),
        buttonText: 'Remove Cross Post',
      }
    : {
        title: 'Confirm Treet Listings',
        body: (
          <>
            {' '}
            {numPreviouslyListedOnTreet > 0 && (
              <>
                ⚠️ These selections include{' '}
                {!isSelectAll
                  ? pluralize(
                      'product variant',
                      numPreviouslyListedOnTreet,
                      true,
                    )
                  : 'product variants'}{' '}
                previously already listed on Treet. Proceed only if the
                additional listings have unique conditions or quirks. Otherwise,{' '}
                <Link
                  onClick={(event) => {
                    event.stopPropagation();
                    window.open(
                      `${treetShopConfig?.canonicalRootUrl}/listings`,
                      '_blank',
                    );
                  }}
                >
                  manage your listings directly on Treet.
                </Link>
                <br />
                <br />
              </>
            )}
            <b>
              This action is irreversible and your listings will be live
              immediately.
            </b>
            <br />
            <br />
            Once listed,{' '}
            <Link
              onClick={(event) => {
                event.stopPropagation();
                window.open(
                  `${treetShopConfig?.canonicalRootUrl}/listings`,
                  '_blank',
                );
              }}
            >
              manage listings directly on Treet.
            </Link>
          </>
        ),
        buttonText: 'List on Treet',
      };

  return (
    <Frame>
      <Modal
        dialogClassName="modal-custom-md"
        centered
        show={isConfirmTreetSubmitModalOpen}
        onHide={handleClose}
      >
        <StyledHeader closeButton>
          <b>{modalContent.title}</b>
          <div className="close" onClick={handleClose}>
            <Icon source={MobileCancelMajor} color="base" />
          </div>
        </StyledHeader>
        <StyledModalBody className="sf-pro">
          <Box my={2}>
            <div className="modal-info">
              <Box display="inline">{modalContent.body}</Box>
            </div>
          </Box>
        </StyledModalBody>
        <StyledFooter>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="left"
            mr={1}
          >
            <Box>
              <b>
                {pluralize(
                  'listing',
                  isSelectAll
                    ? totalNumProducts
                    : totalNumSelectedProductVariants,
                  true,
                )}
              </b>{' '}
              will be {isUnlink ? 'unlinked from' : 'created on'} Treet.
            </Box>
            {!isSelectAll && (
              <Box>
                <b>
                  (total quantity: {pluralize('unit', totalSelectedUnits, true)}
                  )
                </b>
              </Box>
            )}
          </Box>
          <Button loading={loading} type="button" onClick={handleSubmit}>
            {modalContent.buttonText}
          </Button>
        </StyledFooter>
      </Modal>
    </Frame>
  );
};
