import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import {RowItem} from './RowItem';

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 80%;
`;

export const ImagesRow = ({src, handleRemoveImage}) => {
  if (src.length === 0) {
    return null;
  }
  return (
    <Container>
      {src.map((image, index) => {
        return (
          <RowItem
            image={image}
            handleRemoveImage={handleRemoveImage}
            key={`thumbnail-${image.id}-${index}`}
          />
        );
      })}
    </Container>
  );
};
ImagesRow.propTypes = {
  handleRemoveImage: PropTypes.func,
  src: PropTypes.array,
};
