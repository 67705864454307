import {ApiClient} from '../utils/api/api-client';
import {EndpointService} from '../utils/api/endpoints';

const apiClient = new ApiClient();

export class BrandSizingGuideService {
  async list() {
    return apiClient.call(EndpointService.getBrandSizingGuides);
  }

  async add(sizingGuideObj) {
    return apiClient.call(EndpointService.addBrandSizingGuide, sizingGuideObj);
  }

  async delete(sizingGuideId) {
    return apiClient.call(
      EndpointService.deleteBrandSizingGuide(sizingGuideId),
    );
  }

  async update(id, categories) {
    return apiClient.call(
      EndpointService.updateBrandSizingGuideCategories(id),
      {categories},
    );
  }
}
