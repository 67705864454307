import {Banner, Page} from '@shopify/polaris';
import React from 'react';
import {useTreetShopContext} from '../../../context/treet-shop-context';
import {isEmpty} from 'lodash';
import styled from 'styled-components';
import {PageFooter} from '../../../components/PageFooter/PageFooter.component';
import {TopNavComponent} from '../../../components/topnav/TopNav.component';

const StyledDiv = styled.div`
  .Polaris-Banner {
    align-items: center;
    margin-bottom: 16px;
  }

  .Polaris-Banner--withinPage {
    padding: 1rem;
  }

  .Polaris-Banner--withinPage .Polaris-Banner__ContentWrapper {
    margin-top: 0px;
  }
`;

const pageWrapper = (WrappedComponent) => {
  const PageWrapper = (props) => {
    const {treetShopConfig, isTreetShopLoading} = useTreetShopContext();
    const doesNotHaveTreetShop =
      !isTreetShopLoading && isEmpty(treetShopConfig);

    return (
      <Page>
        {doesNotHaveTreetShop && (
          <StyledDiv>
            <Banner
              status="warning"
              title="Warning: You must create a Treet marketplace to list items. Please reach out to sales@treet.co."
            />
          </StyledDiv>
        )}
        <TopNavComponent />
        <WrappedComponent {...props} />
        <PageFooter />
      </Page>
    );
  };

  PageWrapper.displayName = `pageWrapper(${
    WrappedComponent.displayName || WrappedComponent.name
  })`;
  return PageWrapper;
};

export default pageWrapper;
