import React from 'react';
import Products from './Products';
import {PRODUCT_SUBMIT_ACTION} from '../util/constants';

const TransferProducts = () => {
  return (
    <Products
      productSubmitAction={PRODUCT_SUBMIT_ACTION.TRANSFER}
      canEditProductQuantity
    />
  );
};

export default TransferProducts;
