import React, {useEffect, useState} from 'react';
import {Button} from '@shopify/polaris';
import {useAppBridge} from '@shopify/app-bridge-react';
import {Modal} from 'react-bootstrap';
import {verifyAuthErrors} from '../services';
import styled from 'styled-components';

const StyledHeader = styled(Modal.Header)`
  border-bottom: none;

  .auth-modal-title {
    font-family: 'SF Pro';
    font-size: 1.25rem !important;
    color: #202223;
    font-weight: 600 !important;
  }
`;

const StyledFooter = styled(Modal.Footer)`
  border-top: none;

  .Polaris-Button {
    background: #008060;
    color: white;
    font-size: 14px !important;
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
    line-height: 20px;
    border-radius: 4px;
    cursor: pointer;

    .Polaris-Button__Text {
      font-size: 14px !important;
    }
  }
`;

const withAuthErrors = (Component) => {
  const shop =
    window && new URLSearchParams(window.location.search).get('shop');

  return () => {
    const [hasError, setError] = useState(false);
    const {localOrigin} = useAppBridge();

    const checkAuthErrors = async () => {
      await verifyAuthErrors()
        .then(({data}) => {
          if (data?.error || !data?.isScopesEqual) {
            setError(true);
          }
        })
        .catch(({status, data}) => {
          if (status === 404 && data?.error) {
            setError(true);
          }

          console.error({status, data});
        });
    };

    useEffect(() => {
      checkAuthErrors();
    }, []);

    const handleAuthRedirect = (event) => {
      event.preventDefault();

      if (window && window.parent) {
        const redirectUrl =
          `${localOrigin}/auth?shop=` + `${shop}&isReAuth=${true}`;

        window.parent.location.href = redirectUrl;
      }
    };

    if (!hasError) {
      return <Component {...Component.props} />;
    }

    return (
      <Modal dialogClassName="modal-custom-md" centered show={hasError}>
        <StyledHeader className="pt-4 pb-1">
          <p className="auth-modal-title">Update the app!</p>
        </StyledHeader>
        <Modal.Body>
          <p>We've made some changes that require an app update.</p>
        </Modal.Body>
        <StyledFooter>
          <Button type="button" onClick={handleAuthRedirect}>
            Update
          </Button>
        </StyledFooter>
      </Modal>
    );
  };
};

export default withAuthErrors;
