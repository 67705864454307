import {gql, useMutation, useQuery} from '@apollo/client';
import {OnboardingPage} from '@shopify/channels-ui';
import {Card, SkeletonBodyText} from '@shopify/polaris';
import React, {useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import styled from 'styled-components';
import {
  LoadingState,
  OnboardingAccountCard,
  OnboardingChecklistCard,
  OnboardingInfoCard,
} from './components';
import {MENU_ITEMS, MENU_ITEMS_URL_MAP} from '../../../../utils/navigation';

const ONBOARDING_PAGE_QUERY = gql`
  query OnboardingPageQuery {
    adminBrand {
      id
      domain
      accountConnection
      onboardingInfoCompleted
      termsAccepted
      onboardingCompleted
      config {
        hasStorefront
        shipsToCanada
        cadEnabled
        hasPolicies
      }
    }
  }
`;

const COMPLETE_ONBOARDING_MUTATION = gql`
  mutation CompleteOnboarding {
    completeOnboarding {
      id
      onboardingCompleted
    }
  }
`;

const StyledOnboarding = styled.div`
  .Polaris-Breadcrumbs__Breadcrumb {
    border: none !important;
  }

  .Polaris-DisplayText Polaris-DisplayText--sizeLarge {
    margin: auto 0 !important;
  }

  .Polaris-DisplayText {
    margin: auto 0 !important;
  }
`;

const Onboarding = () => {
  const {data, loading} = useQuery(ONBOARDING_PAGE_QUERY);

  const [completeOnboarding, {loading: completeLoading}] = useMutation(
    COMPLETE_ONBOARDING_MUTATION,
  );
  const navigate = useNavigate();

  useEffect(() => {
    if (data && data.adminBrand.onboardingCompleted) {
      navigate(MENU_ITEMS_URL_MAP[MENU_ITEMS.OVERVIEW]);
    }
  }, [data]);

  if (loading) {
    return <LoadingState />;
  }

  const handleAction = () => {
    completeOnboarding();
    navigate(MENU_ITEMS_URL_MAP[MENU_ITEMS.OVERVIEW]);
  };

  const {
    adminBrand: {
      accountConnection,
      domain,
      onboardingInfoCompleted,
      config: {hasStorefront, shipsToCanada, cadEnabled, hasPolicies},
    },
  } = data;

  let requirementsMet = hasStorefront && hasPolicies;

  const OnboardingAccountCardProps = data
    ? {
        children: (
          <OnboardingAccountCard
            state={accountConnection ? 'completed' : 'active'}
            domain={domain}
          />
        ),
      }
    : {
        sectioned: true,
        children: <SkeletonBodyText lines={4} />,
      };

  // const OnboardingInfoCardProps = data
  //   ? {
  //       children: (
  //         <OnboardingInfoCard
  //           state={
  //             accountConnection
  //               ? onboardingInfoCompleted
  //                 ? 'completed'
  //                 : 'active'
  //               : 'disabled'
  //           }
  //         />
  //       ),
  //     }
  //   : {
  //       sectioned: true,
  //       children: <SkeletonBodyText lines={4} />,
  //     };

  let onboardingState = 'disabled';
  if (accountConnection) {
    if (requirementsMet) {
      onboardingState = 'completed';
    }
    onboardingState = 'active';
  }
  const OnboardingChecklistCardProps = data
    ? {
        children: (
          <OnboardingChecklistCard
            hasStorefront={hasStorefront}
            shipsToCanada={shipsToCanada}
            cadEnabled={cadEnabled}
            hasPolicies={hasPolicies}
            state={onboardingState}
          />
        ),
      }
    : {
        sectioned: true,
        children: <SkeletonBodyText lines={4} />,
      };

  return (
    <StyledOnboarding>
      <OnboardingPage
        title="Setup Treet Resale"
        breadcrumb={{
          content: 'Back',
          url: '/', // TODO: Need to use MENU_ITEMS_URL_MAP constants
        }}
        action={{
          content: 'Finish',
          loading: completeLoading,
          onAction: handleAction,
        }}
      >
        <Card {...OnboardingAccountCardProps} />
        {/* <Card {...OnboardingInfoCardProps} /> */}
        <Card {...OnboardingChecklistCardProps} />
      </OnboardingPage>
    </StyledOnboarding>
  );
};

export default Onboarding;
