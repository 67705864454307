import {FeatureCard, IntroductionPage} from '@shopify/channels-ui';
import {gql, useMutation, useQuery} from '@apollo/client';
import React, {useEffect} from 'react';
import styled from 'styled-components';
import {useNavigate} from 'react-router-dom';
import {MENU_ITEMS, MENU_ITEMS_URL_MAP} from '../../../utils/navigation';

const StyledIntro = styled.div`
  .title {
    margin: 20px 0;
    text-align: center;
    font-weight: 400 !important;
    font-size: 14px !important;
  }

  .CHUI-FeatureCard.CHUI-FeatureCard__Portrait {
    padding: 24px 0;
  }

  .CHUI-FeatureCard__HeaderContainer {
    display: none !important;
  }

  .CHUI-FeatureCard__Description {
    display: none !important;
  }

  .CHUI-FeatureCard__InfoContainer {
    padding: 0 !important;
  }

  .CHUI-FeatureCard__ButtonContainer {
    margin-top: 0 !important;
    display: flex;
    margin: auto;

    .Polaris-Button {
      background: #5109c7;
    }
  }

  .badge {
    font-weight: 400 !important;
    color: #5f5f5f;
    display: flex;
    justify-content: center;
    margin: 12px 0;

    .badge-title {
      font-size: 13px !important;
      background: #dbdfe4;
      padding: 0 12px;
      border-radius: 12px;
    }
  }
  .subtitle {
    font-size: 14px !important;
    font-weight: 400 !important;
  }
`;

const ONBOARDING_PAGE_QUERY = gql`
  query OnboardingPageQuery {
    adminBrand {
      id
      onboardingInfoCompleted
      termsAccepted
      onboardingCompleted
      config {
        hasStorefront
        shipsToCanada
        cadEnabled
        hasPolicies
      }
    }
  }
`;

const COMPLETE_ONBOARDING_MUTATION = gql`
  mutation CompleteOnboarding {
    completeOnboarding {
      id
      onboardingCompleted
    }
  }
`;

const Finish = ({setupInProgress}) => {
  const {data} = useQuery(ONBOARDING_PAGE_QUERY);
  const navigate = useNavigate();

  const [completeOnboarding] = useMutation(COMPLETE_ONBOARDING_MUTATION);
  useEffect(() => {
    if (data && data.adminBrand.onboardingCompleted) {
      navigate(MENU_ITEMS_URL_MAP[MENU_ITEMS.OVERVIEW]);
    }
  }, [data]);

  const handleAction = () => {
    completeOnboarding();
  };

  return (
    <StyledIntro>
      <IntroductionPage title="">
        <FeatureCard
          primaryAction={{
            content: setupInProgress
              ? 'Continue setup'
              : 'Start Adding Products',
            url: MENU_ITEMS_URL_MAP[MENU_ITEMS.OVERVIEW],
            onAction: handleAction,
          }}
          portrait
        >
          <h3 className="d-flex justify-content-center text-bold mt-4">
            Thank you for installing Treet
          </h3>

          <h4 className="subtitle d-flex justify-content-center text-center px-5">
            {/* eslint-disable-next-line react/no-unescaped-entities */}
            You're one step closer to making a positive impact to your revenue
            and the planet. Get started by adding products your Treet
            marketplace.
          </h4>
        </FeatureCard>
      </IntroductionPage>
    </StyledIntro>
  );
};

export default Finish;
