import {CommandCenterService} from '../../services/commandCenter.service';

export const cloudinarySafeString = (stringToChange) => {
  // ?&#\/%<> are not supported
  // and let's also replace space with _ to be consistent with what we've done before
  if (!stringToChange) return;

  return stringToChange
    .replace(/ /g, '_')
    .replace(/&/g, 'and')
    .replace(/[?#\\/%<>]/g, '-');
};

export const generateCloudinarySignature = async (options) => {
  try {
    const CommandCenter = new CommandCenterService();
    const {
      data: {signature, timestamp},
    } = await CommandCenter.getCloudinarySignature(options);
    return new Promise((resolve) => {
      resolve({signature, timestamp});
    });
  } catch (error) {
    console.error(error);
    return new Promise((_, reject) => {
      reject('Error in signature generation');
    });
  }
};

export const removeImageFromCloudinary = async (imageId) => {
  try {
    const CommandCenter = new CommandCenterService();
    await CommandCenter.removeImageFromCloudinary(imageId);
    return new Promise((resolve) => {
      resolve();
    });
  } catch (error) {
    console.error(error);
    return new Promise((_, reject) => {
      reject('Error in removing image from cloudinary');
    });
  }
};
