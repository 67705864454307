import React from 'react';
import styled from 'styled-components';

const StyledDivider = styled.div`
  border-top: 1px solid #dfe3e6;
  width: 100%;
  height: 2px;
`;

export const Divider = () => {
  return <StyledDivider />;
};
