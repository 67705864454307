import React, {useEffect} from 'react';
import {useAppBridge} from '@shopify/app-bridge-react';
import {ChannelMenu} from '@shopify/app-bridge/actions';
import {gql, useQuery} from '@apollo/client';
import {useNavigate} from 'react-router-dom';
import {
  createChannelMenuConfig,
  MENU_ITEMS_URL_MAP,
  MENU_ITEMS,
} from '../../../utils/navigation';
import ProductVariantView from '../../../views/product/ProductVariantView';
import TableContainer from '../../../components/TableContainer';
import pageWrapper from '../foundation/PageWrapper';
import {PRODUCT_SUBMIT_ACTION} from '../util/constants';
import {Box} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';

const SETTINGS_PAGE_QUERY = gql`
  query OnboardingPageQuery {
    adminBrand {
      id
      onboardingCompleted
      config {
        hasStorefront
        shipsToCanada
        cadEnabled
        hasPolicies
      }
    }
  }
`;

const getHeaderText = (productSubmitAction) => {
  switch (productSubmitAction) {
    case PRODUCT_SUBMIT_ACTION.UNLINK_CROSS_POST:
      return {
        title: 'Cross-Posted Inventory',
        subtitle:
          'This is inventory that is listed on both Shopify and your Treet site. When a sale happens on either, the inventory will be decremented on both.',
      };
    case PRODUCT_SUBMIT_ACTION.TRANSFER:
      return {
        title: 'Transfer Products',
      };
    case PRODUCT_SUBMIT_ACTION.CROSS_POST:
      return {
        title: 'Cross-Post Products',
      };
    case PRODUCT_SUBMIT_ACTION.DUPLICATE:
    default:
      return {title: 'Duplicate Products'};
  }
};

const ProductsComponent = (props) => {
  const {productSubmitAction, canEditProductQuantity} = props;
  const {data} = useQuery(SETTINGS_PAGE_QUERY);
  const app = useAppBridge();
  const navigate = useNavigate();

  useEffect(() => {
    if (data && !data.adminBrand.onboardingCompleted) {
      navigate(MENU_ITEMS_URL_MAP[MENU_ITEMS.OVERVIEW]);
    }
  }, [data]);

  ChannelMenu.create(app, createChannelMenuConfig(app, MENU_ITEMS.PRODUCTS));

  const {title, subtitle} = getHeaderText(productSubmitAction);

  return (
    <>
      <Box my={1}>
        <Typography variant="h1" style={{fontSize: '24px', fontWeight: 'bold'}}>
          {title}
        </Typography>
        {subtitle && (
          <Typography variant="body1" style={{fontSize: '15px'}}>
            {subtitle}
          </Typography>
        )}
      </Box>
      <TableContainer>
        <ProductVariantView
          canEditProductQuantity={canEditProductQuantity}
          productSubmitAction={productSubmitAction}
        />
      </TableContainer>
    </>
  );
};

export const Products = pageWrapper(ProductsComponent);

export default Products;
