import * as React from 'react';
import {ProductContext} from './product-context';
import {UtilContext} from './util-context';
import {TreetShopContext} from './treet-shop-context';

const ApplicationContext = ({children}) => {
  return (
    <TreetShopContext>
      <ProductContext>
        <UtilContext>{children}</UtilContext>
      </ProductContext>
    </TreetShopContext>
  );
};

export default ApplicationContext;
export * from './product-context';
