import {Box} from '@material-ui/core';
import {ActionList, Button, Icon, Popover} from '@shopify/polaris';
import React, {useState} from 'react';

export const FilterActionComponent = ({
  items,
  icon,
  activatorText = 'Status',
  disabled = false,
}) => {
  const [isActive, setActive] = useState(false);
  const toggleActive = () => setActive((prev) => !prev);

  return (
    <Popover
      active={isActive}
      activator={
        <Button
          disclosure={icon ? undefined : 'down'}
          onClick={toggleActive}
          className="btn filter-btn"
          type="button"
          disabled={disabled}
        >
          <Box display="flex">
            {icon && (
              <Box height="20px">
                <Icon source={icon} />
              </Box>
            )}
            {activatorText}
          </Box>
        </Button>
      }
      onClose={toggleActive}
    >
      <ActionList items={items} />
    </Popover>
  );
};
