import React from 'react';
import {pluralize} from '../../utils/string';
import {QUANTITY_FIELD} from '../../constants/product-form';
import {EditNumberFieldModal} from '../utils/edit-number-field.modal';

export const BulkEditQuantityModal = ({
  selectedResources,
  bulkEditModalType,
  setBulkEditModalType,
  selectedProductVariantsData,
  handleBulkUpdateSave,
  onKeyDown,
  isSelectAll,
  totalNumProducts,
}) => {
  return (
    <EditNumberFieldModal
      title={
        <>
          Set Quantity For{' '}
          <b>
            {pluralize(
              'Row',
              isSelectAll ? totalNumProducts : selectedResources.length,
              true,
            )}
          </b>
        </>
      }
      subtitle="This will set the initial quantity to be listed on Treet. All items that are listed on Treet must have inventory managed on Treet after that point."
      buttonText="Set Quantity"
      inputHelperText="Set Quantity:"
      isOpen={bulkEditModalType === QUANTITY_FIELD}
      selectedProducts={selectedResources}
      handleClose={() => {
        setBulkEditModalType(null);
      }}
      initialValue={
        // If there is a singular row selected, set that row's value to be the default
        selectedProductVariantsData.length === 1
          ? selectedProductVariantsData[0][QUANTITY_FIELD]
          : null
      }
      handleSubmit={async ({variantIds, value}) => {
        if (value) {
          await handleBulkUpdateSave(
            variantIds.reduce((acc, variantId) => {
              return {...acc, [variantId]: {[QUANTITY_FIELD]: value}};
            }, {}),
          );
        }
        setBulkEditModalType(false);
      }}
      onKeyDown={onKeyDown}
    />
  );
};
