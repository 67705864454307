import {Button, Frame, Icon} from '@shopify/polaris';
import React, {useState} from 'react';
import {Modal} from 'react-bootstrap';
import styled from 'styled-components';
import {MobileCancelMajor} from '@shopify/polaris-icons';
import {Box} from '@material-ui/core';
const StyledFooter = styled(Modal.Footer)`
  .Polaris-Button {
    background: #008060;
    color: white;
    font-size: 14px !important;
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
    line-height: 20px;
    border-radius: 4px;
    cursor: pointer;

    .Polaris-Button__Text {
      font-size: 14px !important;
    }
  }

  .Polaris-Button--disabled {
    background: transparent !important;
    box-shadow: none !important;
    border: 0.0625rem solid rgba(210, 213, 216, 1) !important;
    color: rgba(140, 145, 150, 1) !important;
  }

  .close {
    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }
`;

const StyledHeader = styled(Modal.Header)`
  font-family: 'SF Pro';
  font-size: 1.25rem !important;
  color: #202223;
  font-weight: 400;

  .close {
    font-size: 22px !important;
    cursor: pointer;
  }
`;

const StyledModalBody = styled(Modal.Body)`
  .modal-info {
    input {
      margin-top: 6px;
      border: 1px solid #a7acb1;
      border-radius: 4px;

      &:focus,
      &:hover {
        cursor: pointer;
      }
    }
  }
`;

export const EditRadioFieldModal = ({
  selectedProducts: variantIds,
  handleSubmit,
  handleClose,
  isOpen,
  title,
  buttonText,
  initialValue,
  options,
}) => {
  const [value, setValue] = useState(initialValue);
  const [loading, setLoading] = useState(false);

  const saveValues = () => {
    setLoading(true);
    handleSubmit({
      variantIds,
      value,
    });
  };
  return (
    <Frame>
      <Modal
        dialogClassName="modal-custom-md"
        centered
        show={isOpen}
        onHide={handleClose}
      >
        <StyledHeader closeButton>
          <div>{title}</div>
          <div className="close" onClick={handleClose}>
            <Icon source={MobileCancelMajor} color="base" />
          </div>
        </StyledHeader>
        <StyledModalBody className="sf-pro">
          <div className="modal-info">
            <Box display="flex" flexDirection="column">
              {options.map(({key, description}) => {
                return (
                  <label key={key} className="radio-button-modal">
                    <Box display="flex" alignItems="start" mb={2}>
                      <input
                        type="radio"
                        value={key}
                        checked={value === key}
                        onChange={(e) => {
                          setValue(e.target.value);
                        }}
                      />
                      <Box ml={2}>{description}</Box>
                    </Box>
                  </label>
                );
              })}
            </Box>
          </div>
        </StyledModalBody>
        <StyledFooter>
          <Button
            loading={loading}
            disabled={!value}
            type="button"
            onClick={() => saveValues()}
          >
            {buttonText}
          </Button>
        </StyledFooter>
      </Modal>
    </Frame>
  );
};
