export const QUANTITY_SORT_ORDER = {
  FROM_HIGH_TO_LOW: 'H-L',
  FROM_LOW_TO_HIGH: 'L-H',
};

export const NAME_SORT_ORDER = {
  FROM_A_TO_Z: 'a-z',
  FROM_Z_TO_A: 'z-a',
};

export const CREATED_AT_SORT_ORDER = {
  FROM_OLDEST_TO_NEWEST: 'oldest',
  FROM_NEWEST_TO_OLDEST: 'newest',
};
