import {getSessionToken} from '@shopify/app-bridge-utils';
import axios from 'axios';
import axiosRetry from 'axios-retry';

const inRange = (value, min, max) => value >= min && value <= max;

export const Request = {
  GET: 'get',
  POST: 'post',
  PUT: 'put',
  DELETE: 'delete',
};

export class ApiClient {
  constructor(configs) {
    this._configs = Object.assign(
      {
        baseURL: '/',
        timeout: 60000,
        retry: 0,
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          // "Authorization": `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJodHRwczpcL1wvb3V0bGlhbnQtZGV2Lm15c2hvcGlmeS5jb21cL2FkbWluIiwiZGVzdCI6Imh0dHBzOlwvXC9vdXRsaWFudC1kZXYubXlzaG9waWZ5LmNvbSIsImF1ZCI6ImMyYmQ0YzY5NGVmYjU0N2JmMGY4ZWIzM2EwM2YxYTkxIiwic3ViIjoiNTI2NDE2NjEwNTkiLCJleHAiOjE2NDU1Mzg0MDIsIm5iZiI6MTY0NTUzODM0MiwiaWF0IjoxNjQ1NTM4MzQyLCJqdGkiOiI0ZDc0YzZjMC05YTg5LTQ1ZjEtOWE3YS04N2E1YTYwMmViZGYiLCJzaWQiOiI4MWI1MjAxNThmNTA1YzEzNmY5YmJhMzFjZGEwOTg2ZDFjZTNlZDg3ZmRiNTI5ZDIzM2QzMDNmMTM1MzczNWE3In0.MKJtXRsK42HIyDgECXtW3ATodvuhHskx7WfFS0ymTOQ`
        },
      },
      configs,
    );

    this.headersConfig = this._configs.headers;
    this.axiosConfig = {
      baseURL: this._configs.baseURL,
      timeout: this._configs.timeout,
    };

    this.client = axios.create(this._configs);
  }

  call = async (endpoint, postValue) => {
    this.client.interceptors.request.use(
      async (config) => {
        return await getSessionToken(window.app) // requires a Shopify App Bridge instance
          .then((token) => {
            config.headers['Authorization'] = `Bearer ${token}`;
            config.headers['Access-Control-Allow-Origin'] = '*';
            return config;
          });
        // return config;
      },
      (error) => {
        return Promise.reject(error);
      },
    );

    this.client.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        return Promise.reject(error);
      },
    );

    axiosRetry(this.client, {
      retries: this._configs.retry || 0,
      retryDelay: (retryCount) => {
        return retryCount * 1000;
      },
    });

    const headerObj = Object.assign({}, this.headersConfig, {
      // "Shop-Origin": shopOrigin,
      // "Content-Security-Policy": `frame-ancestors https://${shopOrigin} https://admin.shopify.com;`,
    });

    let requestConfig = {
      url: endpoint.uri,
      method: endpoint.method,
      headers:
        Object.getOwnPropertyNames(headerObj).length !== 0 ? headerObj : null,
    };

    if (endpoint.method != Request.GET) {
      requestConfig['data'] = postValue;
    }

    if (endpoint.cancelToken) {
      requestConfig['cancelToken'] = endpoint.cancelToken.token;
    }

    try {
      let response = await this.client.request(requestConfig);
      return transformResponse(response);
    } catch (error) {
      if (error.response) {
        return Promise.reject(transformResponse(error.response));
      }
      if (axios.isCancel(error)) {
        return error.message;
      }
      return Promise.reject(error);
    }
  };
}

const wrapAxiosResponse = (axiosResponse) => {
  const wrappedResponse = {
    status: axiosResponse.status,
    statusText: axiosResponse.statusText,
    data: axiosResponse.data,
    isSuccessful: inRange(axiosResponse.status, 200, 299),
    isClientError: inRange(axiosResponse.status, 400, 499),
  };

  return wrappedResponse;
};

const transformResponse = (response) => {
  return wrapAxiosResponse(response);
};

export const Endpoint = (method, url, request, cancelToken = null) => {
  if (request?.query) {
    const query = Object.entries(request.query)
      .filter((entry) => typeof entry[1] !== 'undefined')
      .map(([field, value]) => field + '=' + value)
      .join('&');
    url += query ? '?' + query : '';
  }

  return {
    uri: url,
    method,
    data: request?.data,
    cancelToken,
  };
};

const apiWrapper = {ApiClient, Endpoint, Request};
export default apiWrapper;
