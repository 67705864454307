import React, {useCallback, useEffect, useState} from 'react';
import {UtilService} from '../services/util.service';

const Context = React.createContext(null);

// This context manages everything that has to do with products such as
// All current products, the selected products, the active variants

const UtilContext = ({children}) => {
  const [isLoadingColors, setLoadingColors] = useState(false);
  const [isLoadingSizes, setLoadingSizes] = useState(false);
  const [isLoadingCats, setLoadingCats] = useState(false);
  const [colors, setColors] = useState([]);
  const [sizes, setSizes] = useState([]);
  const [variantColors, setVariantColors] = useState([]);
  const [variantSizes, setVariantSizes] = useState([]);
  const [categories, setCategories] = useState([]);

  const utilService = new UtilService();

  const getColors = useCallback(
    async (productId) => {
      if (productId) {
        setLoadingColors(true);
        await utilService
          .getVariantColors(productId)
          .then(({data}) => {
            setVariantColors(data.data);
          })
          .catch((error) => console.log({error}))
          .finally(() => setLoadingColors(false));
      } else {
        if (!colors.length) {
          setLoadingColors(true);
          await utilService
            .getColors()
            .then(({data}) => {
              setColors(data.data);
            })
            .catch((error) => console.log({error}))
            .finally(() => setLoadingColors(false));
        }
      }
    },
    [colors, variantColors],
  );

  const getSizes = useCallback(
    async (productId) => {
      if (productId) {
        setLoadingSizes(true);
        await utilService
          .getVariantSizes(productId)
          .then(({data}) => {
            console.log({data});
            setVariantSizes(data.data);
          })
          .catch((error) => console.log({error}))
          .finally(() => setLoadingSizes(false));
      } else {
        if (!sizes.length) {
          setLoadingSizes(true);
          await utilService
            .getSizes()
            .then(({data}) => {
              setSizes(data.data);
            })
            .catch((error) => console.log({error}))
            .finally(() => setLoadingSizes(false));
        }
      }
    },
    [sizes, variantSizes],
  );

  const getCategories = useCallback(async () => {
    if (!categories.length) {
      setLoadingCats(true);
      await utilService
        .getCategories()
        .then(({data}) => {
          setCategories(data.data);
        })
        .catch((error) => console.log({error}))
        .finally(() => setLoadingCats(false));
    }
  }, [categories]);

  useEffect(() => {
    getCategories();
  }, []);

  return (
    <Context.Provider
      value={{
        isLoadingColors,
        isLoadingSizes,
        isLoadingCats,
        colors,
        variantColors,
        sizes,
        variantSizes,
        getSizes,
        getColors,
        categories,
        getCategories,
      }}
    >
      {children}
    </Context.Provider>
  );
};

const useUtilContext = () => React.useContext(Context);

export {UtilContext, useUtilContext};
