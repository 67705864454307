import {gql, useMutation} from '@apollo/client';
import {OnboardingCard} from '@shopify/channels-ui';
import {AccountConnection} from '@shopify/polaris';
import React, {useCallback, useState} from 'react';
import styled from 'styled-components';

const StyledTos = styled.div`
  .Polaris-Modal-Header--titleHidden {
    right: 1.8rem !important;
  }

  .terms {
    background: transparent !important;
    border: none !important;
    text-decoration: underline !important;
    color: blue !important;

    &:hover {
      opacity: 0.8;
      cursor: pointer;
    }
  }
`;
const CONNECT_ACCOUNT_MUTATION = gql`
  mutation ConnectAccount {
    connectAccount {
      id
      accountConnection
    }
  }
`;

const DISCONNECT_ACCOUNT_MUTATION = gql`
  mutation DisconnectAccount {
    disconnectAccount {
      id
      accountConnection
    }
  }
`;

const ACCEPT_TERMS_MUTATION = gql`
  mutation AcceptTerms {
    acceptTerms {
      id
      termsAccepted
    }
  }
`;

const NOT_ACCEPT_TERMS_MUTATION = gql`
  mutation NotAcceptTerms {
    notAcceptTerms {
      id
      termsAccepted
    }
  }
`;
const OnboardingAccountCard = ({state, domain}) => {
  const [connectAccount] = useMutation(CONNECT_ACCOUNT_MUTATION);
  const [disconnectAccount] = useMutation(DISCONNECT_ACCOUNT_MUTATION);

  const [acceptTerms] = useMutation(ACCEPT_TERMS_MUTATION);
  const [notAcceptTerms] = useMutation(NOT_ACCEPT_TERMS_MUTATION);

  const [connected, setConnected] = useState(state === 'completed');
  const accountName = connected ? '' : '';

  const handleAction = useCallback(() => {
    setConnected((connected) => !connected);

    if (!connected) {
      connectAccount();
      acceptTerms();
    } else {
      disconnectAccount();
      notAcceptTerms();
    }
  }, [connected]);

  const buttonText = connected ? 'Disconnect' : 'Connect';
  const details = connected ? 'Account connected' : 'No account connected';
  const terms = connected ? null : (
    <p>
      By clicking <strong>Connect</strong>, you agree to accept Treet’s{' '}
      <a
        target={'_blank'}
        href="https://treet.zendesk.com/hc/en-us/articles/17510136684567-Terms-of-Service-"
        rel="noreferrer"
      >
        terms and conditions
      </a>
      . Products will be uploaded directly to your Treet marketplace.
    </p>
  );

  return (
    <StyledTos>
      <OnboardingCard title={<h5>Treet account</h5>} state={state}>
        <AccountConnection
          accountName={accountName}
          connected={connected}
          title={domain}
          action={{
            content: buttonText,
            onAction: handleAction,
          }}
          details={details}
          termsOfService={terms}
        />
      </OnboardingCard>
    </StyledTos>
  );
};

export default OnboardingAccountCard;
