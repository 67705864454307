import {gql, useQuery} from '@apollo/client';
import {Box} from '@material-ui/core';
import {useAppBridge} from '@shopify/app-bridge-react';
import {ChannelMenu, Redirect} from '@shopify/app-bridge/actions';
import {
  Badge,
  Banner,
  Card,
  SkeletonBodyText,
  SkeletonPage,
} from '@shopify/polaris';
import {default as ExternalLink} from '../../foundation/Link.jsx';
import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {Introduction} from './components';
import pageWrapper from '../../foundation/PageWrapper';
import {CrossPostSyncIcon} from '../../../../components/icons';
import {LandingCard} from '../../../../components/landing/LandingCard.jsx';
import {useTreetShopContext} from '../../../../context/treet-shop-context.js';
import useEnabledPermissionsOnTreet from './hooks/useEnabledPermissionsOnTreet.jsx';
import CrossPostIcon from './icons/CrossPostIcon';
import TransferIcon from './icons/TransferIcon';
import DuplicateIcon from './icons/DuplicateIcon';
import Typography from '@material-ui/core/Typography';
import {DEFAULT_BRAND_FULFILLMENT_METHOD} from '../../util/constants';
import {
  MENU_ITEMS,
  MENU_ITEMS_URL_MAP,
  createChannelMenuConfig,
} from '../../../../utils/navigation.js';
import {LearnMoreModal} from '../../../../components/utils/learn-more.modal.component.jsx';

const StyledDiv = styled.div`
  .Polaris-Banner {
    align-items: center;
  }

  .Polaris-Banner--withinPage {
    padding: 1rem;
  }

  .Polaris-Banner--withinPage .Polaris-Banner__ContentWrapper {
    margin-top: 0px;
  }
`;

const HOME_PAGE_QUERY = gql`
  query HomePageQuery {
    adminBrand {
      id
      onboardingInfoCompleted
      onboardingCompleted
    }
  }
`;

const HomeComponent = () => {
  const app = useAppBridge();
  const redirect = Redirect.create(app);
  const {data, loading} = useQuery(HOME_PAGE_QUERY);
  const {treetShopConfig, contentfulShopConfig, isContentfulShopConfigLoading} =
    useTreetShopContext();
  const {defaultBrandFulfillmentMethod} = contentfulShopConfig || {};
  const hasShopifyFulfillmentEnabled =
    defaultBrandFulfillmentMethod === DEFAULT_BRAND_FULFILLMENT_METHOD.SHOPIFY;
  const {treetId, canonicalRootUrl} = treetShopConfig || {};
  const {
    enabledPermissionsOnTreet,
    isEnabledPermissionsOnTreetLoading,
    enabledPermissionsOnTreetError,
  } = useEnabledPermissionsOnTreet(treetId);
  const [showPermissionsBanner, setShowPermissionsBanner] = useState(false);
  const [showShopifyFulfillmentBanner, setShowShopifyFulfillmentBanner] =
    useState(false);

  const [showLearnMoreModal, setShowLearnMoreModal] = useState(false);

  ChannelMenu.create(app, createChannelMenuConfig(app, MENU_ITEMS.OVERVIEW));

  // useEffect(() => {
  //   if (
  //     data &&
  //     !data.adminBrand.subscriptionMeta.active &&
  //     data.adminBrand.subscriptionMeta.subscriptionUrl
  //   ) {
  //     // redirect to non-testing subscription form
  //     redirect.dispatch(Redirect.Action.REMOTE, {
  //       url: data.adminBrand.subscriptionMeta.subscriptionUrl,
  //     });
  //   }
  // }, [data]);

  useEffect(() => {
    const hasPermissions =
      enabledPermissionsOnTreet.includes('read_inventory') &&
      enabledPermissionsOnTreet.includes('write_inventory');

    setShowPermissionsBanner(
      !isEnabledPermissionsOnTreetLoading &&
        (!hasPermissions || enabledPermissionsOnTreetError),
    );
  }, [isEnabledPermissionsOnTreetLoading, enabledPermissionsOnTreet]);

  useEffect(() => {
    setShowShopifyFulfillmentBanner(
      !hasShopifyFulfillmentEnabled && !isContentfulShopConfigLoading,
    );
  }, [hasShopifyFulfillmentEnabled, isContentfulShopConfigLoading]);

  if (loading || !data) {
    return (
      <SkeletonPage>
        <Card sectioned>
          <SkeletonBodyText />
        </Card>
      </SkeletonPage>
    );
  }

  const {
    adminBrand: {onboardingInfoCompleted, onboardingCompleted},
  } = data;

  if (onboardingCompleted) {
    return (
      <>
        <Typography variant="h1" style={{fontSize: '30px', fontWeight: 'bold'}}>
          Create New Listings On Treet
        </Typography>
        <Typography variant="h1" style={{fontSize: '15px'}}>
          Keep your brand’s catalogue and warehouses fresh by creating brand
          direct listings on Treet. Slow-moving inventory, returns, samples,
          discontinued/damaged items are perfect for Treet.
        </Typography>
        {showPermissionsBanner && (
          <StyledDiv className="m-4">
            <Banner status="info">
              <Typography variant="body1" style={{fontWeight: 'bold'}}>
                You have not shared the required permissions to move inventory
                to Treet.
              </Typography>
              <Typography variant="body1" display="inline">
                Turn on “Manage Inventory” permissions in Shopify.{' '}
              </Typography>
              <a
                href="https://treetbrandsuccess.zendesk.com/hc/en-us/articles/24168073936535-Updating-Your-Shopify-Permissions-for-Treet-Resale-App"
                target="_blank"
                rel="noreferrer"
              >
                <Typography variant="body1" display="inline">
                  Learn how to manage your Shopify permissions.
                </Typography>
              </a>
            </Banner>
          </StyledDiv>
        )}
        {showShopifyFulfillmentBanner && (
          <StyledDiv className="m-4">
            <Banner status="info">
              <Typography variant="body1" style={{fontWeight: 'bold'}}>
                You have not enabled Shopify Fulfillment for your brand. This is
                required for Cross-Post functionality. Please reach out to{' '}
                <a
                  href="mailto: support@treet.co"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Typography variant="body1" display="inline">
                    support@treet.co
                  </Typography>
                </a>{' '}
                to enable.
              </Typography>
            </Banner>
          </StyledDiv>
        )}
        <Box my={3} display="flex">
          <Typography variant="h3">Choose Upload Type</Typography>
          <Box ml={1} onClick={() => setShowLearnMoreModal(true)}>
            <Typography variant="h3" className="modal-link">
              Learn More
            </Typography>
          </Box>
        </Box>
        <Box
          my={2}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          flexDirection="row"
        >
          <Box>
            <LandingCard
              title="Transfer"
              description="Transfer inventory quantity from Shopify to Treet."
              extraDescription="Transferred inventory quantity will be removed from Shopify immediately after upload."
              buttonText="Transfer"
              navigateTo={MENU_ITEMS_URL_MAP[MENU_ITEMS.TRANSFER_PRODUCTS]}
              isDisabled={showPermissionsBanner}
            >
              <Box width="100%" display="flex" justifyContent="space-between">
                <TransferIcon />
                <Box>
                  <Badge status="info">BETA</Badge>
                </Box>
              </Box>
            </LandingCard>
          </Box>
          <Box>
            <LandingCard
              title="Duplicate"
              description="Duplicate product variant data from Shopify onto Treet."
              extraDescription="Your Shopify inventory quantities will not be modified in any way."
              buttonText="Duplicate"
              navigateTo={MENU_ITEMS_URL_MAP[MENU_ITEMS.PRODUCTS]}
            >
              <DuplicateIcon />
            </LandingCard>
          </Box>
          <Box>
            <LandingCard
              title="Cross-Post"
              titleIcon={<CrossPostSyncIcon />}
              description="Inventory posted on Treet will be linked to a corresponding Shopify product variant."
              extraDescription="Shopify inventory quantity will be the source of truth and will decrement when purchased on either platform."
              buttonText="Cross-Post"
              navigateTo={MENU_ITEMS_URL_MAP[MENU_ITEMS.CROSS_POST_PRODUCTS]}
              isDisabled={showShopifyFulfillmentBanner}
            >
              <Box width="100%" display="flex" justifyContent="space-between">
                <CrossPostIcon />
                <Box>
                  <Badge status="info">BETA</Badge>
                </Box>
              </Box>
            </LandingCard>
          </Box>
        </Box>
        <Box p={2} style={{textAlign: 'center'}}>
          <Typography variant="body1" style={{opacity: 0.6}} display="inline">
            Looking to manage product variants already posted to Treet?{' '}
            <ExternalLink
              url={`${canonicalRootUrl}/listings`}
              external
              style={{color: 'inherit'}}
            >
              Manage your marketplace inventory on Treet.
            </ExternalLink>
          </Typography>
        </Box>
        <LearnMoreModal
          isOpen={showLearnMoreModal}
          handleClose={() => setShowLearnMoreModal(false)}
        />
      </>
    );
  }

  return <Introduction setupInProgress={onboardingInfoCompleted} />;
};

const Home = pageWrapper(HomeComponent);
export default Home;
