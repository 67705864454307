import {gql, useQuery} from '@apollo/client';
import {useAppBridge} from '@shopify/app-bridge-react';
import {ChannelMenu} from '@shopify/app-bridge/actions';
import {SettingsPage} from '@shopify/channels-ui';
import {
  Button,
  Card,
  Frame,
  Modal,
  Spinner,
  TextStyle,
  Toast,
} from '@shopify/polaris';
import React, {useCallback, useEffect, useState} from 'react';
import {Form} from 'react-bootstrap';
import {useNavigate} from 'react-router-dom';
import ProfileInfoItems from '../../../../components/profile-tab/ProfileInfoItems';
import {UploadImage} from '../../../../components/upload-image/UploadImage';
import shippingData from '../../../../constants/shipping-data';
import {BrandService} from '../../../../services/brand.service';
import {
  generateCloudinarySignature,
  cloudinarySafeString,
} from '../../../../utils';
import {SizeChart} from './SizeChart';
import {
  createChannelMenuConfig,
  MENU_ITEMS,
} from '../../../../utils/navigation';
import pageWrapper from '../../foundation/PageWrapper';

const SETTINGS_PAGE_QUERY = gql`
  query OnboardingPageQuery {
    adminBrand {
      id
      name
      onboardingCompleted
    }
  }
`;

const MAX_DESCRIPTION_LENGTH = 280;
const MAX_MISSION_LENGTH = 512;

const SettingsComponent = () => {
  const app = useAppBridge();
  const navigate = useNavigate();
  const brandService = new BrandService();
  const {data} = useQuery(SETTINGS_PAGE_QUERY);

  const [desc, setDesc] = useState('');
  const [errorUploadingImages, setErrorUploadingImages] = useState(false);
  const [imagesList, setImagesList] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [mission, setMission] = useState('');
  const [shippingOption, setShippingOption] = useState(shippingData);
  const [toastMessage, setToastMessage] = useState('');
  const [loadingImages, setLoadingImages] = useState(false);
  const [sizingModalOpened, setSizingModalOpened] = useState(false);

  const submitDescriptionUpdate = async () => {
    await brandService
      .updateBrandDescription({description: desc})
      .then(() => {
        setToastMessage('Updated description successfully');
      })
      .catch((error) => {
        console.log({error});
        setToastMessage(' Update failed');
      });
  };

  const submitMissionUpdate = useCallback(async () => {
    await brandService
      .updateBrandMission({mission})
      .then(() => {
        setToastMessage('Updated Mission successfully');
      })
      .catch((error) => {
        console.log({error});
        setToastMessage('Update Mission failed');
      });
  }, [mission]);

  useEffect(() => {
    async function fetchOptions() {
      setLoading(true);
      await brandService
        .getShippingOptions()
        .then(({data}) => {
          data && setShippingOption(data.data);
        })
        .catch((error) => console.log({error}))
        .finally(() => setLoading(false));
    }

    fetchOptions();
  }, [data]);

  useEffect(() => {
    if (data && !data.adminBrand.onboardingCompleted) {
      navigate('/'); // TODO: Need to use MENU_ITEMS_URL_MAP constants
    }
  }, [data]);

  useEffect(() => {
    const fetchDescription = async () => {
      await brandService
        .getBrandDescription()
        .then((res) => {
          const {data} = res.data;
          setDesc(data.description);
        })
        .catch((error) => console.log({error}));
    };

    const fetchMission = async () => {
      await brandService
        .getBrandMission()
        .then((res) => {
          const {data} = res.data;
          setMission(data.mission);
        })
        .catch((error) => console.log({error}));
    };

    fetchDescription();
    fetchMission();
  }, []);

  useEffect(() => {
    const fetchImages = async () => {
      try {
        setLoadingImages(true);
        const {data} = await brandService.getBrandImages();
        setImagesList(data.data);
      } catch (error) {
        console.log(error);
        setErrorUploadingImages(true);
      } finally {
        setLoadingImages(false);
      }
    };
    fetchImages();
  }, []);

  const openCloudinaryWidget = async () => {
    const imageFolder = `brand_images${
      data?.adminBrand?.id
        ? `/${data.adminBrand.id}${
            data?.adminBrand?.name
              ? `_${cloudinarySafeString(data.adminBrand.name)}`
              : ''
          }`
        : ''
    }`;

    const {signature, timestamp} = await generateCloudinarySignature({
      folder: imageFolder,
      source: 'uw',
    });
    let widget = window.cloudinary.createUploadWidget(
      {
        cloudName: CLOUDINARY_CLOUD_NAME,
        folder: imageFolder,
        maxFiles: 6,
        uploadSignature: signature,
        uploadSignatureTimestamp: timestamp,
        api_key: CLOUDINARY_API_KEY,
        maxFileSize: 5000000,
        maxImageFileSize: 5000000,
      },
      async (error, result) => {
        if (!error && result && result.event === 'success') {
          const uploadedImg = {
            id: result?.info?.public_id,
            secure_url: result?.info?.secure_url,
            width: result?.info?.width,
            height: result?.info?.height,
            format: result?.info?.format,
            bytes: result?.info?.bytes,
            hosting_url: 'https://res.cloudinary.com',
            hosting_id: result?.info?.asset_id,
            original_filename: result?.info?.original_filename,
          };
          if (imagesList.length + 1 <= 6) {
            try {
              setLoadingImages(true);
              setErrorUploadingImages(false);
              await brandService.uploadBrandImage(uploadedImg);
              setImagesList((imagesList) => [...imagesList, uploadedImg]);
            } catch (error) {
              console.error(error);
              setErrorUploadingImages(true);
            } finally {
              setLoadingImages(false);
            }
          }
        }
      },
    );
    widget.open();
  };

  const onRemoveImage = async (id) => {
    try {
      setLoadingImages(true);
      setErrorUploadingImages(false);
      await brandService.deleteBrandImage(id);
      setToastMessage('Operation successful');
      setImagesList(imagesList.filter((image) => image.hosting_id !== id));
    } catch (error) {
      setToastMessage('Operation failed');
      setErrorUploadingImages(true);
    } finally {
      setLoadingImages(false);
    }
  };

  ChannelMenu.create(app, createChannelMenuConfig(app, MENU_ITEMS.SETTINGS));

  return (
    <Frame>
      <SettingsPage title="">
        <SettingsPage.Section
          title="About"
          description="Include information about your brand so that Dibz shoppers understand more about your brand."
        >
          <Card title="Brand Description" sectioned>
            <Form.Control
              as="textarea"
              rows={5}
              maxLength={MAX_DESCRIPTION_LENGTH}
              value={desc}
              onChange={(e) => setDesc(e.target.value)}
            />
            <TextStyle variation="subdued">
              Max {MAX_DESCRIPTION_LENGTH} characters. Styling not supported. (
              {desc?.length || 0}/{MAX_DESCRIPTION_LENGTH})
            </TextStyle>
            <div className="mt-4 d-flex justify-content-end">
              <Button primary onClick={() => submitDescriptionUpdate()}>
                Save
              </Button>
            </div>
          </Card>
        </SettingsPage.Section>

        <div>{/* HACK: empty div for removing border between sections */}</div>

        <SettingsPage.Section
          title=""
          description="Add your mission so shoppers know what makes you special."
        >
          <Card title="Mission (optional)" sectioned>
            <Form.Control
              as="textarea"
              rows={5}
              placeholders="Share a bit about your brands mission..."
              maxLength={MAX_MISSION_LENGTH}
              value={mission}
              onChange={(e) => setMission(e.target.value)}
            />

            <div className="mt-1 text-sm text-black-50">
              Max {MAX_MISSION_LENGTH} characters. Styling not supported. (
              {mission?.length || 0}/{MAX_MISSION_LENGTH})
            </div>

            <div className="mt-4 d-flex justify-content-end">
              <Button primary onClick={() => submitMissionUpdate()}>
                Save
              </Button>
            </div>
          </Card>
        </SettingsPage.Section>

        <SettingsPage.Section
          title="Size chart"
          description="Share your brands sizing so shoppers have an idea of fit. The size guidelines will be linked to the product details page"
        >
          <Card sectioned>
            <div className="d-flex flex-column align-items-center">
              <Button
                className="col-2"
                primary
                onClick={() => setSizingModalOpened(true)}
              >
                Add sizing
              </Button>
              <div className="mt-4 text-sm text-black-50 text-center">
                File types supported: PDF, JPEG, PNG. Recomended aspect ratio
                16:9
              </div>
              {sizingModalOpened && (
                <Modal
                  title="Size Chart"
                  open={sizingModalOpened}
                  onClose={() => setSizingModalOpened(false)}
                >
                  <SizeChart />
                </Modal>
              )}
            </div>
          </Card>
        </SettingsPage.Section>

        <SettingsPage.Section
          title="Photography"
          description="Share images of your brand,size guidelines and more. These images will be featured under your “About” section on the product details page."
        >
          <UploadImage
            imagesList={imagesList}
            handleUploadImage={openCloudinaryWidget}
            handleRemoveImage={onRemoveImage}
            uploadedNumber={imagesList.length}
            isLoading={loadingImages}
            errorUploading={errorUploadingImages}
          ></UploadImage>
        </SettingsPage.Section>

        <SettingsPage.Section
          title="Shipping"
          description="Set a shipping option for all products."
        >
          <Card title="Shipping Options" sectioned>
            {isLoading ? (
              <div>
                <Spinner size="small" />
              </div>
            ) : shippingOption.length ? (
              shippingOption.map((detail, index) => (
                <ProfileInfoItems
                  detail={detail}
                  setToastMessage={setToastMessage}
                  key={index}
                  setShippingOption={setShippingOption}
                  shippingOption={shippingOption}
                />
              ))
            ) : (
              <div style={{textAlign: 'center'}}>
                <TextStyle variation="negative">
                  No shipping options found.
                </TextStyle>
              </div>
            )}
          </Card>
        </SettingsPage.Section>
      </SettingsPage>

      {toastMessage.length ? (
        <Toast
          content={toastMessage}
          duration={2500}
          error={toastMessage.includes('failed')}
          onDismiss={() => setToastMessage('')}
        />
      ) : null}
    </Frame>
  );
};

const Settings = pageWrapper(SettingsComponent);
export default Settings;
