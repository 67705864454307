import React, {useCallback, useState, useEffect} from 'react';
import {Button, OptionList, Popover} from '@shopify/polaris';
import PropTypes from 'prop-types';

export const OptionListSubCategories = ({
  categories,
  fileInfo,
  selectedCategories,
  handleSubCategoryClick,
  initialSelectedSubCategories,
}) => {
  const [selected, setSelected] = useState([]);
  const [popoverActive, setPopoverActive] = useState(true);

  useEffect(() => {
    setSelected(initialSelectedSubCategories);
  }, []);

  const togglePopoverActive = useCallback(
    () => setPopoverActive((popoverActive) => !popoverActive),
    [],
  );

  const isButtonDisabled = !selectedCategories.find(
    (category) => category.id === fileInfo.id,
  );

  const buttonLabel =
    selected.length > 0 ? `${selected.length} selected` : 'Subcategory';

  const activator = (
    <Button
      disabled={isButtonDisabled}
      onClick={togglePopoverActive}
      disclosure
    >
      {buttonLabel}
    </Button>
  );

  const onCategoryClick = useCallback(
    (category) => {
      setSelected(category);
      handleSubCategoryClick(category);
    },
    [handleSubCategoryClick],
  );

  return (
    <div>
      <Popover
        active={popoverActive}
        activator={activator}
        onClose={togglePopoverActive}
      >
        {categories.map((cat) => {
          const existingFilteredFile = selectedCategories.find(
            (cat) => cat.id === fileInfo.id,
          );
          if (!existingFilteredFile) {
            return null;
          }
          if (
            !existingFilteredFile.category_id.includes(cat.id) ||
            cat.subCategories.length === 0
          ) {
            return null;
          }
          return (
            <OptionList
              key={`option-list-` + cat.id}
              title={cat.name}
              onChange={onCategoryClick}
              options={cat.subCategories.map((subCat) => {
                return {label: subCat.name, value: subCat.id};
              })}
              selected={selected}
              allowMultiple
            />
          );
        })}
      </Popover>
    </div>
  );
};

OptionListSubCategories.propTypes = {
  categories: PropTypes.arrayOf(PropTypes.object),
  fileInfo: PropTypes.object,
  handleSubCategoryClick: PropTypes.func,
  initialSelectedSubCategories: PropTypes.arrayOf(PropTypes.number),
};
