import React, {useState} from 'react';
import {ExtendedAppProvider} from '@shopify/channels-ui';
import ApplicationContext from '../../../context';
import {Outlet as RouterOutlet, useLocation} from 'react-router';
import polarisTranslations from '@shopify/polaris/locales/en.json';
import translations from '@shopify/channels-ui/locales/en.json';
import GraphQLProvider from './GraphQL';
import Link from './Link';
import '../../../styles/index.scss';
import RoutePropagator from './RoutePropagator';
import withAuthErrors from '../../../utils/authErrors';
import SentryErrorBoundary from './SentryErrorBoundary';

const Outlet = withAuthErrors(RouterOutlet);

const AppProvider = () => {
  const {search} = useLocation();
  const [host] = useState(new URLSearchParams(search).get('host'));

  return (
    <SentryErrorBoundary>
      <ExtendedAppProvider
        polaris={{i18n: polarisTranslations, linkComponent: Link}}
        i18n={translations}
        config={{
          host,
          apiKey: API_KEY,
          forceRedirect: true,
        }}
      >
        <ApplicationContext>
          <GraphQLProvider>
            <Outlet />
            <RoutePropagator />
          </GraphQLProvider>
        </ApplicationContext>
      </ExtendedAppProvider>
    </SentryErrorBoundary>
  );
};

export default AppProvider;
