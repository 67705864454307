import {gql, useQuery} from '@apollo/client';
import {
  Banner,
  Frame,
  IndexTable,
  Loading,
  SkeletonBodyText,
  useIndexResourceState,
  Pagination,
  Tooltip,
} from '@shopify/polaris';
import {CrossPostSyncIcon} from '../../components/icons';
import {Form as FinalForm, FormSpy} from 'react-final-form';
import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {DataControlComponent} from '../../components/control-data/control-data.component';
import {DataControlFooter} from '../../components/control-data/control-data-footer';
import {useProductContext} from '../../context';
import {MENU_ITEMS, MENU_ITEMS_URL_MAP} from '../../utils/navigation';
import {BannerProductInstructions} from './BannerProductInstructions';
import {FieldTextInput} from '../../components/form/FieldTextInput';
import {
  CONDITION_FIELD,
  INTERNAL_NOTES_FIELD,
  ORIGINAL_PRICE_FIELD,
  PRICE_FIELD,
  PRODUCT_VARIANT_FORM_ID,
  QUANTITY_FIELD,
  SELLER_NOTES_FIELD,
  SYNC_INVENTORY_WITH_TREET_FIELD,
  TRANSFER_INVENTORY_TO_TREET_FIELD,
} from '../../constants/product-form';
import {destringifyKey, stringifyKey} from '../../utils/form';
import {roundAndTruncateToTwoDecimals} from '../../utils';
import {FieldSelect} from '../../components/form/FieldSelect';
import {ProductService} from '../../services';
import {isEmpty, snakeCase, startCase} from 'lodash';
import {
  PRODUCT_VARIANTS_PAGE_SIZE,
  preventDecimal,
  preventNegativeNumber,
} from './utils';
import {Box, Chip, ThemeProvider} from '@material-ui/core';
import {useTreetShopContext} from '../../context/treet-shop-context';
import {PRODUCT_SUBMIT_ACTION} from '../../app/src/util/constants';
import {ProductVariantImage} from '../../components/variant-row-elements/ProductVariantImage';
import {useLocation, useNavigate} from 'react-router-dom';
import {CheckCircle} from 'react-feather';
import {createTheme} from '@material-ui/core';

const productService = new ProductService();

function stopPropagation(event) {
  event.stopPropagation();
}
function StopClickPropagation({children, className, style}) {
  return (
    <div onClick={stopPropagation} style={style} className={className}>
      {children}
    </div>
  );
}

export const getMuiTheme = () => {
  return createTheme({
    palette: {
      primary: {
        main: '#008060',
      },
      secondary: {
        main: '#95BF47',
      },
    },
    overrides: {
      MuiChip: {
        colorSecondary: {
          color: '#FFFFFF',
        },
      },
    },
  });
};

const SETTINGS_PAGE_QUERY = gql`
  query OnboardingPageQuery {
    adminBrand {
      id
      accountConnection
      domain
    }
  }
`;

const StickyDataControl = styled(DataControlComponent)`
  position: sticky;
  top: 0;
  left: 0;
  z-index: 100;
  background-color: rgb(246, 246, 247);
`;

const StickyDataFooter = styled(DataControlFooter)`
  position: sticky;
  bottom: 15;
  left: 0;
  z-index: 100;
  background-color: rgb(246, 246, 247);
`;

const StatusOnTreet = (props) => {
  switch (true) {
    case props.crossPosted:
      return (
        <Tooltip
          content={
            <span>
              <strong>Item is cross-posted.</strong>
              <br />
              {props.isUnlinkCrossPost
                ? ''
                : 'Click to manage cross-posted items.'}
            </span>
          }
          preferredPosition="below"
        >
          <Chip
            className="listed-link"
            onClick={
              props.isUnlinkCrossPost ? () => null : props.handleCrossPostClick
            }
            color="secondary"
            label="Cross-Posted"
            size="small"
            clickable={!props.isUnlinkCrossPost}
            icon={
              <Box display="flex" alignItems="center" justifyContent="center">
                <CrossPostSyncIcon height="18" width="18" color="white" />
              </Box>
            }
          />
        </Tooltip>
      );
    case props.listedOnTreet:
      return (
        <Tooltip
          content={
            <span>
              <strong>This SKU is already listed on Treet.</strong>
              <br />
              Click to view items matching this SKU on Treet.
            </span>
          }
          preferredPosition="below"
        >
          <Chip
            className="listed-link"
            color="primary"
            label="Listed"
            size="small"
            clickable
            onClick={props.handleListedClick}
            icon={<CheckCircle />}
          />
        </Tooltip>
      );

    default:
      return <Chip label="Not Listed" size="small" />;
  }
};

const ProductVariantRow = (props) => {
  const {
    variantWithProduct,
    index,
    isSelected,
    canEditProductQuantity,
    productSubmitAction,
  } = props;
  const {treetShopConfig} = useTreetShopContext();
  const navigate = useNavigate();

  const {
    id: variantId,
    name: variantName,
    on_treet,
    sku,
    inventory_quantity,
    shop_variant_id,
    Product,
    sync_inventory_with_treet,
  } = variantWithProduct;
  const {name: productName} = Product;
  const fieldId = stringifyKey(variantId); // Otherwise RFF does not support numerical keys
  const isDuplicate = productSubmitAction === PRODUCT_SUBMIT_ACTION.DUPLICATE;
  const isUnlinkCrossPost =
    productSubmitAction === PRODUCT_SUBMIT_ACTION.UNLINK_CROSS_POST;
  const isDisabled =
    !isDuplicate &&
    !isUnlinkCrossPost &&
    (sync_inventory_with_treet || Number(inventory_quantity) === 0);

  return (
    <IndexTable.Row
      id={variantId}
      key={variantId}
      selected={isSelected}
      position={index}
      // disabled={isDisabled}
      subdued={isDisabled} // HACK: use "subdued" as a stand-in for "disabled"
    >
      <IndexTable.Cell>
        <ProductVariantImage
          variantWithProduct={variantWithProduct}
          isDisabled={isDisabled}
          key={variantWithProduct.id}
        />
      </IndexTable.Cell>
      <IndexTable.Cell>{productName}</IndexTable.Cell>
      <IndexTable.Cell>{variantName}</IndexTable.Cell>
      <IndexTable.Cell>{sku}</IndexTable.Cell>
      <IndexTable.Cell>
        <StatusOnTreet
          key={variantId}
          listedOnTreet={on_treet}
          handleListedClick={(event) => {
            event.stopPropagation();
            window.open(
              `${treetShopConfig?.canonicalRootUrl}/listings${
                shop_variant_id
                  ? `?ids=${encodeURIComponent(
                      `gid://shopify/ProductVariant/${shop_variant_id}`,
                    )}`
                  : ''
              }`,
              '_blank',
            );
          }}
          crossPosted={sync_inventory_with_treet}
          handleCrossPostClick={(event) => {
            event.stopPropagation();
            return navigate(
              MENU_ITEMS_URL_MAP[MENU_ITEMS.CROSS_POSTED_INVENTORY],
              {
                state: {isSelected: [variantId]}, // automatically select variants
              },
            );
          }}
          isUnlinkCrossPost={isUnlinkCrossPost}
        />
      </IndexTable.Cell>
      <IndexTable.Cell>
        <Box className="d-flex align-items-center position-relative">
          <StopClickPropagation className="d-flex align-items-center position-relative">
            <FieldTextInput
              id={`${fieldId}.${QUANTITY_FIELD}`}
              name={`${fieldId}.${QUANTITY_FIELD}`}
              type="number"
              style={{fontSize: '14px', width: 48}}
              onKeyDown={(event) => {
                preventNegativeNumber(event);
                preventDecimal(event);
              }}
              disabled={isDisabled || !canEditProductQuantity}
            />
          </StopClickPropagation>
          <Box mx={1}>{'/'}</Box>
          <Box>{inventory_quantity}</Box>
        </Box>
      </IndexTable.Cell>
      <IndexTable.Cell>
        <StopClickPropagation className="d-flex align-items-center position-relative">
          <FieldSelect
            id={`${fieldId}.${CONDITION_FIELD}`}
            name={`${fieldId}.${CONDITION_FIELD}`}
            style={{
              fontSize: '14px',
              height: '27px',
              minWidth: 100,
              maxWidth: 150,
              overflowX: 'scroll',
            }}
            type="text"
            format={(value) => startCase(value)}
            parse={(value) => snakeCase(value)}
            disabled={isDisabled}
          >
            <option>New With Tags</option>
            <option>New Without Tags</option>
            <option>Excellent</option>
            <option>Good</option>
          </FieldSelect>
        </StopClickPropagation>
      </IndexTable.Cell>
      <IndexTable.Cell>
        <StopClickPropagation className="d-flex align-items-center position-relative">
          <FieldTextInput
            id={`${fieldId}.${ORIGINAL_PRICE_FIELD}`}
            name={`${fieldId}.${ORIGINAL_PRICE_FIELD}`}
            type="number"
            style={{fontSize: '14px', width: 72}}
            onKeyDown={preventNegativeNumber}
            disabled={isDisabled}
          />
        </StopClickPropagation>
      </IndexTable.Cell>
      <IndexTable.Cell>
        <StopClickPropagation className="d-flex align-items-center position-relative">
          <FieldTextInput
            id={`${fieldId}.${PRICE_FIELD}`}
            name={`${fieldId}.${PRICE_FIELD}`}
            type="number"
            style={{fontSize: '14px', width: 72}}
            onKeyDown={preventNegativeNumber}
            disabled={isDisabled}
          />
        </StopClickPropagation>
      </IndexTable.Cell>
      <IndexTable.Cell>
        <StopClickPropagation className="d-flex align-items-center position-relative">
          <FieldTextInput
            id={`${fieldId}.${SELLER_NOTES_FIELD}`}
            name={`${fieldId}.${SELLER_NOTES_FIELD}`}
            type="textarea"
            style={{fontSize: '14px'}}
            disabled={isDisabled}
          />
        </StopClickPropagation>
      </IndexTable.Cell>
      <IndexTable.Cell>
        <StopClickPropagation className="d-flex align-items-center position-relative">
          <FieldTextInput
            id={`${fieldId}.${INTERNAL_NOTES_FIELD}`}
            name={`${fieldId}.${INTERNAL_NOTES_FIELD}`}
            type="textarea"
            style={{fontSize: '14px'}}
            disabled={isDisabled}
          />
        </StopClickPropagation>
      </IndexTable.Cell>
    </IndexTable.Row>
  );
};

const getInitialQuantity = (variant, productSubmitAction) => {
  if (productSubmitAction === PRODUCT_SUBMIT_ACTION.CROSS_POST) {
    return Number(variant.inventory_quantity) || 0;
  }

  return (
    Math.max(variant.treet_uploaded_quantity, 0) ||
    // inventory_quantity field type is bigint which is cast to a string in javascript
    Number(variant.inventory_quantity) ||
    0
  );
};

const getInitialValues = (variantsData, productSubmitAction) => {
  const initialTreetPriceMultiplier =
    productSubmitAction === PRODUCT_SUBMIT_ACTION.CROSS_POST ? 1 : 0.5;

  return variantsData.reduce((acc, variant) => {
    const {compare_at_price, price} = variant;
    const originalPrice = Math.max(price, compare_at_price);
    return {
      ...acc,
      [stringifyKey(variant.id)]: {
        [CONDITION_FIELD]: variant.condition || 'new_with_tags',
        [ORIGINAL_PRICE_FIELD]: variant.treet_original_price
          ? roundAndTruncateToTwoDecimals(variant.treet_original_price / 100)
          : roundAndTruncateToTwoDecimals(originalPrice),
        [PRICE_FIELD]: roundAndTruncateToTwoDecimals(
          variant.treet_price
            ? variant.treet_price / 100
            : initialTreetPriceMultiplier * originalPrice,
        ),
        [SELLER_NOTES_FIELD]: variant.treet_seller_notes,
        [INTERNAL_NOTES_FIELD]: variant.internal_brand_notes,
        [QUANTITY_FIELD]: getInitialQuantity(variant, productSubmitAction),
        [SYNC_INVENTORY_WITH_TREET_FIELD]:
          productSubmitAction === PRODUCT_SUBMIT_ACTION.CROSS_POST ||
          variant.sync_inventory_with_treet,
        [TRANSFER_INVENTORY_TO_TREET_FIELD]:
          productSubmitAction === PRODUCT_SUBMIT_ACTION.TRANSFER,
      },
    };
  }, {});
};

const ProductVariantView = (props) => {
  const {
    canEditProductQuantity = false,
    productSubmitAction = PRODUCT_SUBMIT_ACTION.DUPLICATE,
  } = props;
  const {data} = useQuery(SETTINGS_PAGE_QUERY);
  const [isBrandActive, setIsBrandActive] = useState(
    data ? data.adminBrand.accountConnection : true,
  );
  const [autoSaveStatus, setAutoSaveStatus] = useState('');
  const {
    products: Products,
    isLoading,
    filteredProducts: filteredProductsVariants,
    setFilteredProducts,
    page,
    setPage,
    totalNumProducts,
    setProductSubmitAction,
  } = useProductContext();

  setProductSubmitAction(productSubmitAction);

  const {state: locationState} = useLocation();
  const initSelectedVariants = locationState?.isSelected;

  const initialValues = getInitialValues(
    filteredProductsVariants,
    productSubmitAction,
  );

  const theme = getMuiTheme();

  useEffect(() => {
    if (data && data.adminBrand.accountConnection) {
      setIsBrandActive(true);
    } else {
      setIsBrandActive(false);
    }
  }, [data]);

  useEffect(() => {
    // Hack to get around lack of "disabled" attribute in the version of Shopify/Polaris
    // that our node version is compatible with.
    if (!isLoading) {
      const subduedRows = document.querySelectorAll(
        '.Polaris-IndexTable__TableRow--subdued',
      );

      subduedRows.forEach((row) => {
        row.style.cursor = 'default';
        row.onclick = (e) => {
          // Do not block propagation of clicks from link that redirects to Treet Manage Listings Page
          if (!e.target.closest('.listed-link')) {
            e.stopPropagation();
          }
        };
        // Find the checkbox within the row
        const checkbox = row.querySelector('input[type="checkbox"]');
        if (checkbox) {
          // Disable the checkbox
          checkbox.disabled = true;
        }
      });
    }
  }, [isLoading]);

  const resourceName = {
    singular: 'Product',
    plural: 'Products',
  };

  const resourceIDResolver = (object) => {
    return object.id;
  };

  const {selectedResources, allResourcesSelected, handleSelectionChange} =
    useIndexResourceState(filteredProductsVariants, {
      selectedResources: initSelectedVariants,
      resourceIDResolver,
    });

  const syncFormValues = async (formSpyProps) => {
    const {values, dirty, active, dirtyFields} = formSpyProps;
    // Do not update if these fields are active - leads to laggy form & unnecessary re-renders,
    // e.g. form updating after every char input in the "description" field.
    const waitUntilInactiveFields = [
      ORIGINAL_PRICE_FIELD,
      PRICE_FIELD,
      INTERNAL_NOTES_FIELD,
      QUANTITY_FIELD,
    ];
    const waitUntilInactiveFieldsForRow = filteredProductsVariants.reduce(
      (acc, variant) => {
        const rowFieldKeys = waitUntilInactiveFields.map(
          (field) => `${stringifyKey(variant.id)}.${field}`,
        );
        return [...acc, ...rowFieldKeys];
      },
      [],
    );
    if (!dirty || waitUntilInactiveFieldsForRow.includes(active)) return;
    const dirtyFieldsRowKeys = Object.entries(dirtyFields).reduce(
      (acc, [fieldKey, isDirty]) => {
        const rowKey = fieldKey.split('.')[0];
        if (isDirty) return [...acc, rowKey];
        return acc;
      },
      [],
    );
    const dirtyVariantsData = Object.entries(values).reduce(
      (acc, [key, value]) => {
        const isDirty = dirtyFieldsRowKeys.includes(key);
        if (isDirty)
          return {
            ...acc,
            [destringifyKey(key)]: value,
          };
        return acc;
      },
      {},
    );
    if (!isEmpty(dirtyVariantsData)) {
      try {
        const response = await productService.bulkUpdateProductVariants({
          variants: dirtyVariantsData,
        });
        if (response?.status === 200) {
          setAutoSaveStatus('Changes Saved');
        } else {
          // TODO handle error logging
          throw new Error('Failed to bulk save product variants');
        }
      } catch (error) {
        setAutoSaveStatus('Error Saving Changes');
      }
    }
  };

  const pageIndexStart = 1 + PRODUCT_VARIANTS_PAGE_SIZE * page;
  const pageIndexEnd =
    PRODUCT_VARIANTS_PAGE_SIZE * page + filteredProductsVariants?.length;
  const hasPagination = PRODUCT_VARIANTS_PAGE_SIZE < totalNumProducts;

  const accountVerified = isBrandActive ? (
    <>
      <FinalForm
        // Submit here is an unused no-op - we use the form purely to store data values for StickyDataControl to use.
        onSubmit={() => {}}
        initialValues={initialValues}
        keepDirtyOnReinitialize
        render={(formRenderProps) => {
          const {handleSubmit} = formRenderProps;
          return (
            <>
              <StickyDataControl
                initialData={Products}
                filteredProductsVariants={filteredProductsVariants}
                setFilteredProducts={setFilteredProducts}
                handleSelectionChange={handleSelectionChange}
                selectedResources={selectedResources}
                autoSaveStatus={autoSaveStatus}
                productSubmitAction={productSubmitAction}
                hasInitSelected={!isEmpty(initSelectedVariants)}
              />
              {isLoading ? (
                <Frame>
                  <Loading />
                </Frame>
              ) : (
                <ThemeProvider theme={theme}>
                  <form id={PRODUCT_VARIANT_FORM_ID} onSubmit={handleSubmit}>
                    <IndexTable
                      resourceName={resourceName}
                      itemCount={filteredProductsVariants.length}
                      selectedItemsCount={
                        allResourcesSelected ? 'All' : selectedResources.length
                      }
                      onSelectionChange={handleSelectionChange}
                      emptyState={<></>}
                      headings={[
                        {title: ''}, // Product Image - no title required.
                        {title: 'Product'},
                        {title: 'Variant'},
                        {title: 'SKU'},
                        {title: 'Status On Treet'},
                        {
                          title: canEditProductQuantity
                            ? 'Quantity'
                            : 'Inventory (synced)',
                        },
                        {title: 'Condition'},
                        {title: 'Original Price'},
                        {title: 'Treet Price'},
                        {title: 'Seller Notes (optional)'},
                        {title: 'Internal Notes (optional)'},
                      ]}
                    >
                      {filteredProductsVariants.map(
                        (variantWithProduct, index) => (
                          <ProductVariantRow
                            key={variantWithProduct.id}
                            variantWithProduct={variantWithProduct}
                            index={index}
                            isSelected={selectedResources.includes(
                              variantWithProduct?.id,
                            )}
                            canEditProductQuantity={canEditProductQuantity}
                            productSubmitAction={productSubmitAction}
                          />
                        ),
                      )}
                    </IndexTable>
                    {hasPagination && (
                      <Box mt={2} mb={5} display="flex" justifyContent="center">
                        <Pagination
                          onPrevious={() => {
                            setPage(page - 1);
                            handleSelectionChange('all', false);
                          }}
                          onNext={() => {
                            setPage(page + 1);
                            handleSelectionChange('all', false);
                          }}
                          type="table"
                          hasPrevious={pageIndexStart > 1}
                          hasNext={pageIndexEnd < totalNumProducts}
                          label={`${pageIndexStart}-${pageIndexEnd} of ${totalNumProducts}`}
                        />
                      </Box>
                    )}
                    <FormSpy
                      onChange={syncFormValues}
                      subscription={{
                        values: true,
                        dirty: true,
                        active: true,
                        dirtyFields: true,
                      }}
                    />
                  </form>
                </ThemeProvider>
              )}
              <StickyDataFooter
                initialData={Products}
                filteredProductsVariants={filteredProductsVariants}
                setFilteredProducts={setFilteredProducts}
                handleSelectionChange={handleSelectionChange}
                selectedResources={selectedResources}
                productSubmitAction={productSubmitAction}
                hasInitSelected={!isEmpty(initSelectedVariants)}
              />
            </>
          );
        }}
      />
      <div className="mt-3">
        <BannerProductInstructions domain={data?.adminBrand?.domain} />
      </div>
    </>
  ) : (
    <div className="my-5">
      <Banner
        status="critical"
        title="Your Treet Resale items are inactive"
        action={{
          content: 'View details',
          url: MENU_ITEMS_URL_MAP[MENU_ITEMS.ACCOUNT],
        }}
      >
        <p>
          You need to connect your account in order for items to be added to
          your Treet marketplace.
        </p>
      </Banner>
    </div>
  );

  return (
    <>
      {data === undefined ? (
        <div className="my-5">
          <SkeletonBodyText lines={4} />
        </div>
      ) : (
        accountVerified
      )}
    </>
  );
};

export default ProductVariantView;
