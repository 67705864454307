import axios from 'axios';
import {useCallback, useEffect, useMemo, useState} from 'react';
import {useProductContext} from '../../context';
import {ProductService} from '../../services/product.service';
import {useDebounce} from '../../utils/helpers/use-debounce';
import {NAME_SORT_ORDER} from '../../constants/ordering';
import {pluralize} from '../../utils/string';
import {
  PRODUCT_SUBMIT_ACTION,
  PRODUCT_SERVICE_METHOD,
  mapProductSubmitActionToProductServiceMethod,
  mapProductSubmitActionToUploadType,
} from '../../app/src/util/constants';
import {VARIANT_FILTER, INVENTORY_FILTER} from '../../constants/filters';

export const useControlData = ({
  setFilteredProducts,
  // filterLogic,
  initialData,
  selectedResources,
  filteredProducts,
  handleSelectionChange,
  hasInitSelected,
}) => {
  const productService = new ProductService();
  const {
    setLoading,
    isUpdating,
    setUpdating,
    setProducts,
    page,
    setPage,
    totalNumProducts,
    setTotalNumProducts,
    productSubmitAction,
  } = useProductContext();

  const [toastMessage, setToastMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState(null);
  const [query, setQuery] = useState('');
  const [filterConditions, setFilterConditions] = useState('');
  const [colorFilters, setColorFilters] = useState([]);
  const [sizeFilters, setSizeFilters] = useState([]);
  const [showAdd, setShowAdd] = useState(false);
  const [sortOrder, setSortOrder] = useState(NAME_SORT_ORDER.FROM_A_TO_Z);
  const [isSelectAll, setIsSelectAll] = useState(false);

  // Cross post and transfer actions require there to be inventory with stock
  const shouldActionRequireInventoryWithStock =
    productSubmitAction === PRODUCT_SUBMIT_ACTION.CROSS_POST ||
    productSubmitAction === PRODUCT_SUBMIT_ACTION.TRANSFER;
  const [selectedInventoryFilter, setSelectedInventoryFilter] = useState(
    shouldActionRequireInventoryWithStock
      ? INVENTORY_FILTER.WITH_STOCK
      : INVENTORY_FILTER.ALL_INVENTORY,
  );

  const debouncedQuery = useDebounce(query, 500);

  const isFiltering = useMemo(
    () =>
      !!filterConditions.length ||
      !!sizeFilters.length ||
      !!colorFilters.length,
    [filterConditions, sizeFilters, colorFilters],
  );

  const updateText = useCallback(
    (value) => {
      setQuery(value);
    },
    [initialData],
  );

  const updateFilters = useCallback((filter, data, updateState) => {
    if (filter === 'clear') {
      updateState([]);
    } else {
      const index = data.findIndex((cond) => cond === filter);

      if (index >= 0) {
        data.splice(index, 1);
        updateState(data);
      } else {
        updateState((prev) => [...prev, filter]);
      }
    }
  }, []);

  const handleUpdateStatus = useCallback(
    async (productVariantsData, productSubmitAction) => {
      setLoading(true);
      setErrorMessage(null);
      const isUnlink =
        productSubmitAction === PRODUCT_SUBMIT_ACTION.UNLINK_CROSS_POST;

      const dibzProductServiceMethod =
        mapProductSubmitActionToProductServiceMethod[productSubmitAction];

      try {
        const {data} = await productService[dibzProductServiceMethod]({
          variants: productVariantsData
            ? productVariantsData
            : selectedResources.map((id) => ({id})),
          uploadType: mapProductSubmitActionToUploadType[productSubmitAction],
          isSelectAll,
          totalNumProducts,
        });

        const updatedProducts = filteredProducts
          .filter((product) => {
            // If unlinking, filter out products that were just unlinked from the updated products
            if (isUnlink) {
              return !selectedResources.includes(product.id);
            }
            return true;
          })
          .map((product) => {
            let current;
            if (data?.data) {
              current = data?.data?.find(({id}) => product.id === id) || {};
            }

            return selectedResources.includes(product.id)
              ? {
                  ...product,
                  ...current,
                  onDibz:
                    dibzProductServiceMethod ===
                    PRODUCT_SERVICE_METHOD.ADD_TO_DIBZ,
                }
              : product;
          });

        handleSelectionChange('all', false);
        setFilteredProducts(updatedProducts);
        setProducts(updatedProducts);
        setShowAdd(false);
        setToastMessage(
          dibzProductServiceMethod === PRODUCT_SERVICE_METHOD.ADD_TO_DIBZ
            ? `Successfully created ${pluralize(
                'listing',
                isSelectAll ? totalNumProducts : selectedResources?.length,
                true,
              )} on Treet`
            : dibzProductServiceMethod ===
              PRODUCT_SERVICE_METHOD.UNSYNC_FROM_TREET
            ? `Successfully unlinked ${pluralize(
                'listing',
                isSelectAll ? totalNumProducts : selectedResources?.length,
                true,
              )} from Treet`
            : `Successfully removed ${pluralize(
                'listing',
                isSelectAll ? totalNumProducts : selectedResources?.length,
                true,
              )} from Treet`,
        );
      } catch (error) {
        console.log({error});

        // Show updated products data
        const {data: allUpdatedProducts} =
          await productService.getFilteredProducts({
            status: filterConditions.length
              ? Array.isArray(filterConditions)
                ? filterConditions.join('-')
                : filterConditions
              : undefined,
            sort: sortOrder,
            search: debouncedQuery || undefined,
            variantFilter: variantFilterForView(),
            inventoryFilter: selectedInventoryFilter,
          });

        setFilteredProducts(allUpdatedProducts.data.rows);
        setProducts(allUpdatedProducts.data.rows);

        setErrorMessage(
          error?.data?.errorMessage ||
            'Error listing items on Treet. Please reach out to support@treet.co',
        );
      } finally {
        setLoading(false);
      }
    },
    [selectedResources],
  );

  const handleBulkUpdateSave = useCallback(
    async (variantUpdateData) => {
      setLoading(true);
      try {
        const {data} = await productService.bulkUpdateProductVariants({
          variants: variantUpdateData,
          isSelectAll,
          totalNumProducts,
        });
        // const updatedProducts = filteredProducts.map((product) => {
        //   let current;
        //   if (data?.data) {
        //     current = data?.data?.find(({id}) => product.id == id) || {};
        //   }

        //   return selectedResources.includes(product.id)
        //     ? {
        //         ...product,
        //         ...current,
        //       }
        //     : product;
        // });

        const {data: allUpdatedProducts} =
          await productService.getFilteredProducts({
            status: filterConditions.length
              ? Array.isArray(filterConditions)
                ? filterConditions.join('-')
                : filterConditions
              : undefined,
            sort: sortOrder,
            search: debouncedQuery || undefined,
            page,
            variantFilter: variantFilterForView(),
            inventoryFilter: selectedInventoryFilter,
          });
        setFilteredProducts(allUpdatedProducts.data.rows);
        setProducts(allUpdatedProducts.data.rows);
        setToastMessage('Changes Saved');
      } catch (error) {
        console.log({error});
        setToastMessage('Error Saving Changes!');
      } finally {
        setLoading(false);
      }
    },
    [selectedResources],
  );

  const getSortedProducts = useCallback(async (order) => {
    setLoading(true);
    setSortOrder(order);
    await productService
      .getSortedProducts(order)
      .then(({data}) => {
        setFilteredProducts(data.data.rows);
        setTotalNumProducts(data.data.count);
      })
      .catch((error) => console.log({error}))
      .finally(() => setLoading(false));
  }, []);

  const variantFilterForView = useCallback(() => {
    if (productSubmitAction === PRODUCT_SUBMIT_ACTION.UNLINK_CROSS_POST) {
      return VARIANT_FILTER.IS_SYNC_INVENTORY_WITH_TREET;
    }
  }, [productSubmitAction]);

  const fetchProducts = async (source) => {
    if (!isUpdating) {
      setLoading(true);
      await productService
        .getFilteredProducts(
          {
            status: filterConditions.length
              ? Array.isArray(filterConditions)
                ? filterConditions.join('-')
                : filterConditions
              : undefined,
            sort: sortOrder,
            search: query || undefined,
            variantFilter: variantFilterForView(),
            inventoryFilter: selectedInventoryFilter,
            page,
          },
          source,
        )
        .then(({data}) => {
          if (data?.data?.rows) {
            setFilteredProducts(data.data.rows);
            setTotalNumProducts(data.data.count);
          }
        })
        .catch((error) => console.log({error}))
        .finally(() => setLoading(false));
    }
  };

  const fetchProductsWithFilter = useCallback(
    async (source) => {
      if (!hasInitSelected) {
        handleSelectionChange('all', false);
      }
      await fetchProducts(source);
      if (page !== 0) {
        // Reset pagination upon search query change or inventory filter change
        setPage(0);
      }
    },
    // TODO (TREET-6224) Combine all getProducts params into a single state
    [debouncedQuery, selectedInventoryFilter, isUpdating],
  );

  const fetchProductsWithPage = useCallback(
    async (source) => {
      await fetchProducts(source);
    },
    // TODO (TREET-6224) Combine all getProducts params into a single state
    [page, isUpdating],
  );

  const resetFilters = () => {
    setSelectedInventoryFilter(INVENTORY_FILTER.ALL_INVENTORY);
    setQuery('');
    setFilterConditions('');
    setSizeFilters([]);
    setColorFilters([]);
  };

  const handleReloadShopifyProducts = useCallback(async () => {
    resetFilters();
    setLoading(true);
    setUpdating(true);
    setErrorMessage(null);

    try {
      const response = await productService.reloadShopifyProducts();
      setToastMessage(response.data.message);
    } catch (e) {
      console.error(e);
      if (e?.message?.includes('timeout')) {
        setErrorMessage(
          'The requested product data refresh is taking longer than expected and the process has moved to the background. Please refresh the page to see data changes reflected.',
        );
      } else {
        setErrorMessage(
          'Error refreshing product data. Please refresh the page and try again.',
        );
      }
    } finally {
      setLoading(false);
      setUpdating(false);
    }
  }, []);

  useEffect(() => {
    const cancelToken = axios.CancelToken;
    const source = cancelToken.source();

    fetchProductsWithFilter(source);

    return () => {
      source.cancel('Filtered products request cancelled');
    };
  }, [fetchProductsWithFilter]);

  useEffect(() => {
    const cancelToken = axios.CancelToken;
    const source = cancelToken.source();

    fetchProductsWithPage(source);

    return () => {
      source.cancel('Paginated products request cancelled');
    };
  }, [fetchProductsWithPage]);

  return {
    isFiltering,
    updateText,
    updateFilters,
    query,
    setQuery,
    getSortedProducts,
    setSizeFilters,
    setColorFilters,
    setFilterConditions,
    setSortOrder,
    sortOrder,
    filterConditions,
    colorFilters,
    sizeFilters,
    handleUpdateStatus,
    toastMessage,
    errorMessage,
    setErrorMessage,
    showAdd,
    setToastMessage,
    setShowAdd,
    handleBulkUpdateSave,
    isSelectAll,
    setIsSelectAll,
    handleReloadShopifyProducts,
    selectedInventoryFilter,
    setSelectedInventoryFilter,
  };
};
