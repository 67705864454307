import 'isomorphic-fetch';
import React, {useCallback, useEffect, useState} from 'react';
import {ProductService} from '../services';
import {useTreetShopContext} from './treet-shop-context';
import {isEmpty} from 'lodash';

const Context = React.createContext(null);
const productService = new ProductService();

// This context manages everything that has to do with products such as
// All current products, the selected products, the active variants

const ProductContext = ({children}) => {
  const [isUpdating, setUpdating] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [loadingVariants, setLoadingVariants] = useState(false);
  const [products, setProducts] = useState([]);
  const [totalNumProducts, setTotalNumProducts] = useState([]);
  const [filteredProducts, setFiltered] = useState([]);
  const [selectedProduct, setProduct] = useState();
  const [activeVariants, setVariants] = useState([]);
  const [page, setPage] = useState(0);
  const [productSubmitAction, setProductSubmitAction] = useState(undefined);
  // prevent from fetching listings multiple times
  const [areVariantsSynced, setAreVariantsSynced] = useState(false);

  const {treetShopConfig} = useTreetShopContext();
  const {treetId} = treetShopConfig;

  // const variantService = new VariantService();

  useEffect(() => {
    if (treetId && !isEmpty(filteredProducts) && !areVariantsSynced) {
      const productVariantIds = filteredProducts.map(
        (product) => product.shop_variant_id,
      );
      setUpdating(true);
      setLoading(true);
      productService
        .getListingsFromTreet(treetId, productVariantIds)
        .then(() => {
          setAreVariantsSynced(true);
        })
        .catch((error) => {
          console.log({error});
        })
        .finally(() => {
          setUpdating(false);
          setLoading(false);
        });
    }
  }, [treetId, filteredProducts]);

  const setSelectedProduct = useCallback(async (productId) => {
    if (productId) {
      setProduct(productId);
    } else {
      setProduct(undefined);
    }
    // if (productId) {
    //   setLoading(true);
    //   await variantService
    //     .getVariant(productId)
    //     .then(({data}) => {
    //       setVariants(data.data);
    //       setProduct(productId);
    //     })
    //     .catch((error) => {
    //       console.log({error});
    //       setProduct(undefined);
    //     })
    //     .finally(() => setLoading(false));
    // } else {
    //   setProduct(undefined);
    // }
  }, []);

  const setFilteredProducts = useCallback(async (products) => {
    setFiltered(products);
  });

  return (
    <Context.Provider
      value={{
        isLoading,
        setLoading,
        isUpdating,
        setUpdating,
        loadingVariants,
        setLoadingVariants,
        products,
        setProducts,
        selectedProduct,
        setSelectedProduct,
        filteredProducts,
        setFilteredProducts,
        activeVariants,
        setVariants,
        page,
        setPage,
        totalNumProducts,
        setTotalNumProducts,
        productSubmitAction,
        setProductSubmitAction,
      }}
    >
      {children}
    </Context.Provider>
  );
};

const useProductContext = () => React.useContext(Context);

export {ProductContext, useProductContext};
