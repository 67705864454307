import React from 'react';
import {Heading, Button} from '@shopify/polaris';
import {Box} from '@material-ui/core';
import {useNavigate} from 'react-router-dom';
import Typography from '@material-ui/core/Typography';

const landingCardStyle = {
  background: '#FFFFFF',
  borderRadius: '12px',
  border: '2px solid #e4e5e7',
  maxWidth: '360px',
  height: '450px',
  padding: '30px',
  overflow: 'hidden',
};

export const LandingCard = (props) => {
  const {
    title,
    titleIcon,
    description,
    extraDescription,
    buttonText,
    navigateTo,
    isDisabled,
    children,
  } = props;

  const navigate = useNavigate();

  return (
    <Box
      display="flex"
      alignItems="center"
      flexDirection="column"
      style={landingCardStyle}
      justifyContent="space-between"
    >
      <Box>
        <Box display="flex" flexDirection="row" mb={3}>
          {children}
        </Box>
        <Box>
          <Box my={1} display="flex" flexDirection="row" alignItems="center">
            <Heading>{title}</Heading>
            <Box ml={1}>{titleIcon}</Box>
          </Box>
          <Box mb={3}>
            <Typography
              variant="h1"
              style={{fontSize: '14px', fontWeight: 500}}
            >
              {description}
            </Typography>
          </Box>
          <Box>
            <Typography
              variant="h1"
              style={{fontSize: '14px', fontWeight: 'bold', color: '#454545'}}
            >
              {extraDescription}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box display="flex" width="90%" justifyContent="center">
        <Button
          primary
          fullWidth
          onClick={() => navigate(navigateTo)}
          disabled={isDisabled}
        >
          {buttonText}
        </Button>
      </Box>
    </Box>
  );
};
