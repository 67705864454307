import React from 'react';
import {useAppBridge} from '@shopify/app-bridge-react';
import {ChannelMenu} from '@shopify/app-bridge/actions';
import {createChannelMenuConfig, MENU_ITEMS} from '../../../utils/navigation';
import UploadHistoryView from '../../../views/product/UploadHistoryView';
import TableContainer from '../../../components/TableContainer';
import pageWrapper from '../foundation/PageWrapper';

const UploadsComponent = () => {
  const app = useAppBridge();

  ChannelMenu.create(app, createChannelMenuConfig(app, MENU_ITEMS.UPLOADS));

  return (
    <>
      <TableContainer>
        <UploadHistoryView />
      </TableContainer>
    </>
  );
};

export const Uploads = pageWrapper(UploadsComponent);
export default Uploads;
