import React, {useState, useEffect} from 'react';
import {
  Button,
  Card,
  DisplayText,
  Heading,
  Spinner,
  TextContainer,
  TextStyle,
  Toast,
} from '@shopify/polaris';
import {Divider} from '../../../../components/divider/Divider';
import {
  generateCloudinarySignature,
  removeImageFromCloudinary,
  cloudinarySafeString,
} from '../../../../utils';
import {gql, useQuery} from '@apollo/client';
import {OptionListCategories} from '../../../../components/option-list/OptionListCategories';
import {OptionListSubCategories} from '../../../../components/option-list/OptionListSubCategories';
import {UtilService} from '../../../../services/util.service';
import {BrandSizingGuideService} from '../../../../services/brandSizingGuide.service';

const SETTINGS_PAGE_QUERY = gql`
  query OnboardingPageQuery {
    adminBrand {
      id
      name
      onboardingCompleted
    }
  }
`;

const addfileAreaStyle = {
  border: '1px dashed #8C9196',
  padding: '5%',
};

export const SizeChart = () => {
  const [toastMessage, setToastMessage] = useState('');
  const [filesList, setFilesList] = useState([]);
  const [categories, setCategories] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedSubCategories, setSelectedSubCategories] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState([]);

  const {data: brand} = useQuery(SETTINGS_PAGE_QUERY);
  const brandSizingService = new BrandSizingGuideService();
  const utilService = new UtilService();

  useEffect(() => {
    async function fetchCategoriesAndSizingGuides() {
      try {
        setIsFetching(true);
        const {data: fetchedCategories} = await utilService.getCategories();
        setCategories(fetchedCategories?.data);
        const {data: sizingGuides} = await brandSizingService.list();
        const fetchedFilesList = sizingGuides.data.map((sizing) => {
          return {
            secure_url: sizing.secure_url,
            id: sizing.id,
            original_filename: sizing.original_filename,
            hosting_url: sizing.hosting_url,
            hosting_id: sizing.id,
            format: sizing.format,
          };
        });
        const fetchedSelectedCategories = sizingGuides.data.map((sizing) => {
          return {
            id: sizing.id,
            category_id: sizing.categories
              .map((cat) => {
                if (cat.parent_id) {
                  return;
                }
                return cat.id;
              })
              .filter((element) => element !== undefined),
          };
        });
        const fetchedSelectedSubCategories = sizingGuides.data.map((sizing) => {
          return {
            id: sizing.id,
            category_id: sizing.categories
              .map((cat) => {
                if (!cat.parent_id) {
                  return;
                }
                return cat.id;
              })
              .filter((element) => element !== undefined),
          };
        });
        const fetchedUploadedFilesId = sizingGuides.data.map((sizing) => {
          return {
            id: sizing.id,
            edit: false,
          };
        });
        setFilesList(fetchedFilesList);
        setSelectedCategories(fetchedSelectedCategories);
        setSelectedSubCategories(fetchedSelectedSubCategories);
        setUploadedFiles(fetchedUploadedFilesId);
      } catch (error) {
        console.error(error);
      } finally {
        setIsFetching(false);
      }
    }
    fetchCategoriesAndSizingGuides();
  }, []);

  const onOpenCloudinaryWidget = async ({adminBrand}, sources) => {
    const imageFolder = `brand_size_guides${
      adminBrand?.id
        ? `/${adminBrand?.id}${
            adminBrand?.name ? `_${cloudinarySafeString(adminBrand?.name)}` : ''
          }`
        : ''
    }`;

    try {
      const {signature, timestamp} = await generateCloudinarySignature({
        folder: imageFolder,
        source: 'uw',
      });
      let widget = window.cloudinary.createUploadWidget(
        {
          cloudName: CLOUDINARY_CLOUD_NAME,
          sources,
          folder: imageFolder,
          maxFiles: 1,
          uploadSignature: signature,
          uploadSignatureTimestamp: timestamp,
          api_key: CLOUDINARY_API_KEY,
          maxFileSize: 50000000,
          maxImageFileSize: 50000000,
        },
        async (error, result) => {
          if (!error && result && result.event === 'success') {
            const imageInfo = result.info;
            imageInfo.id = result.info.asset_id;
            const images = [...filesList, imageInfo];
            setFilesList(images);
          }
        },
      );
      widget.open();
    } catch (err) {
      setToastMessage('Operation failed. Please try again.');
    }
  };

  const onRemoveSizeItem = async (file) => {
    const removedFile = filesList.filter((item) => item.id !== file.id);
    try {
      setIsFetching(true);
      await removeImageFromCloudinary(file.public_id || file.id);
      if (file.id) {
        await brandSizingService.delete(file.id);
      }
      setFilesList(removedFile);
      setToastMessage('Operation successful.');
    } catch (error) {
      console.error(error);
      setToastMessage('Operation failed. Please try again.');
    } finally {
      setIsFetching(false);
    }
  };

  const isMultipleCategoriesSelected = (file) => {
    const categoriesList = selectedCategories.find(
      (item) => item.id === file.id,
    );
    return categoriesList?.category_id.length > 1;
  };

  const onCategoryClick = (categories, file) => {
    const filteredOut = selectedCategories.filter(
      (item) => item.id !== file.id,
    );
    setSelectedCategories([
      ...filteredOut,
      {
        id: file.id,
        category_id: categories,
      },
    ]);
  };

  const onSubCategoryClick = (categories, file) => {
    const filteredOut = selectedSubCategories.filter(
      (item) => item?.id !== file?.id,
    );
    setSelectedSubCategories([
      ...filteredOut,
      {
        id: file.asset_id || file.hosting_id,
        category_id: categories,
      },
    ]);
  };

  const onSubmitEditBrandSizingGuides = async (file) => {
    setIsFetching(true);
    try {
      const categoriesToEdit = selectedCategories.find(
        (item) => item.id === file.id,
      );
      const subCategoriesToEdit = selectedSubCategories.find(
        (item) => item.id === file.id,
      );

      await brandSizingService.update(
        file.asset_id || file.id,
        categoriesToEdit.category_id
          .concat(subCategoriesToEdit?.category_id)
          .filter((element) => element !== undefined),
      );
      setUploadedFiles(
        uploadedFiles.map((item) => {
          if (item.id === file.id) {
            return {
              id: file.id,
              edit: false,
            };
          }
          return item;
        }),
      );

      setToastMessage('Operation successful.');
    } catch (error) {
      console.error(error);
      setToastMessage('Operation failed. Please try again.');
    } finally {
      setIsFetching(false);
    }
  };

  const onActivateEditBrandSizingGuides = (file) => {
    const updatedFiles = uploadedFiles.map((item) => {
      if (item.id === file.id) {
        return {
          ...item,
          edit: true,
        };
      }
      return item;
    });
    setUploadedFiles(updatedFiles);
  };

  const onSubmitBrandSizingGuides = async (file) => {
    try {
      const existingFileSubmitted = uploadedFiles.find(
        (fileExisting) => fileExisting.id === file.id,
      );
      if (existingFileSubmitted) {
        await onSubmitEditBrandSizingGuides(file);
        return;
      }

      setIsFetching(true);
      const filteredCategories = selectedCategories.find(
        (cat) => cat.id === file.id,
      );
      const filteredSubCategories = selectedSubCategories.find(
        (cat) => cat.id === (file.asset_id || file.hosting_id),
      );
      const mergedCategories = filteredCategories.category_id
        .concat(filteredSubCategories?.category_id)
        .filter((element) => element !== undefined);

      const brandSizingGuides = {
        id: file.asset_id,
        format: file.format || file.original_extension,
        hosting_url: 'https://res.cloudinary.com',
        hosting_id: file.asset_id,
        secure_url: file.secure_url,
        original_filename: file.original_filename,
        categories: mergedCategories,
      };
      await brandSizingService.add(brandSizingGuides);
      setUploadedFiles([
        ...uploadedFiles,
        {
          id: file.asset_id,
          edit: false,
        },
      ]);
      setToastMessage('Operation successful.');
    } catch (error) {
      console.error(error);
      setToastMessage('Operation failed. Please try again.');
    } finally {
      setIsFetching(false);
    }
  };

  const isFileIncluded = (file) => {
    return uploadedFiles.find((item) => item.id === file.id && !item.edit);
  };

  return (
    <div className="bg-light p-5">
      <Heading element="h2">
        Share your products sizing
        {isFetching && (
          <span style={{marginLeft: 10}}>
            <Spinner size="small" />
          </span>
        )}
      </Heading>
      <div>
        <Divider />
      </div>
      <div className="container">
        <div className="row mt-4 mb-4">
          <div className="col-12 col-md-4">
            <TextContainer>
              <Heading element="h3" size="small">
                Upload your sizing
              </Heading>
              <span className="Polaris-subheader">
                Upload file(s) or share a link for your sizing guide. File types
                supported: PDF, JPEG, PNG. Recomended aspect ratio 16:9
              </span>
            </TextContainer>
          </div>
          <div className="col-12 col-md-8">
            <Card title="Add sizing file" sectioned>
              {filesList &&
                filesList.length > 0 &&
                filesList.map((file, index) => {
                  return (
                    <div className="col-12" key={index}>
                      <div className="row p-3">
                        <div className="col-6 col-md-10">
                          <TextStyle variation="subdued">
                            {file?.original_filename}.
                            {file?.original_extension || file?.format}
                          </TextStyle>
                        </div>
                        <a
                          onClick={() => onRemoveSizeItem(file)}
                          role="button"
                          className="col-6 col-md-2 text-decoration-none"
                        >
                          Remove
                        </a>
                      </div>
                      <div className="col-12 mb-3">
                        <Divider />
                      </div>
                    </div>
                  );
                })}
              <div className="container">
                <div className="row" style={addfileAreaStyle}>
                  <div className="col-6 d-flex justify-content-end">
                    <Button
                      onClick={() =>
                        onOpenCloudinaryWidget(brand, [
                          'local',
                          'url',
                          'camera',
                        ])
                      }
                    >
                      Add file
                    </Button>
                  </div>
                  <div className="col-6 d-flex justify-content-start align-items-center">
                    <a
                      role="button"
                      className="text-primary text-text-decoration-underline"
                      onClick={() => onOpenCloudinaryWidget(brand, ['url'])}
                    >
                      Add from URL
                    </a>
                  </div>
                </div>
              </div>
            </Card>
          </div>
        </div>
        <div className="row mb-4 mt-4">
          <div className="col-12 col-md-4">
            <TextContainer>
              <Heading element="h3" size="small">
                Assign categories
              </Heading>
              <span className="Polaris-subheader">
                Assign categories to each file you uploaded. Categories options
                will appear once a file is added.
              </span>
            </TextContainer>
          </div>
          <div className="col-12 col-md-8">
            <Card title="Categories" sectioned>
              {filesList && filesList.length > 0 ? (
                filesList.map((file, index) => {
                  return (
                    <div className="col-12" key={index}>
                      <div className="row p-3">
                        <div className="col-12">
                          <TextStyle variation="strong">
                            {file?.original_filename}.
                            {file?.original_extension || file?.format}
                          </TextStyle>
                        </div>
                      </div>
                      <div className="row p-3">
                        {!isFileIncluded(file) ? (
                          <div className="col-12 col-md-5">
                            <TextStyle>Product type</TextStyle>
                            <OptionListCategories
                              categories={categories}
                              initialSelectedCategories={
                                selectedCategories.find(
                                  (cat) => cat.id === file.id,
                                )?.category_id || []
                              }
                              handleCategoryClick={(cat) =>
                                onCategoryClick(cat, file)
                              }
                            />
                          </div>
                        ) : (
                          <div className="col-12 col-md-5">
                            <TextStyle>Product type</TextStyle>
                            {selectedCategories.map((cat, index) => {
                              if (cat.id === (file.asset_id || file.id)) {
                                return (
                                  <div
                                    className="col-12"
                                    key={'display-text' + index}
                                  >
                                    {cat.category_id.map(
                                      (categoryItem, index) => {
                                        return (
                                          <div
                                            className="col-12"
                                            key={'display-text' + index}
                                          >
                                            <DisplayText size="small">
                                              {
                                                categories.find(
                                                  (cat) =>
                                                    cat.id === categoryItem,
                                                )?.name
                                              }
                                            </DisplayText>
                                          </div>
                                        );
                                      },
                                    )}
                                  </div>
                                );
                              }
                            })}
                          </div>
                        )}
                        {!isFileIncluded(file) ? (
                          <div className="col-12 col-md-5">
                            <TextStyle>Product subcategory</TextStyle>
                            <OptionListSubCategories
                              selectedCategories={selectedCategories}
                              initialSelectedSubCategories={
                                selectedSubCategories.find(
                                  (cat) => cat.id === file.id,
                                )?.category_id || []
                              }
                              fileInfo={file}
                              handleSubCategoryClick={(cat) =>
                                onSubCategoryClick(cat, file)
                              }
                              categories={categories}
                            />
                          </div>
                        ) : (
                          <div className="col-12 col-md-5">
                            <TextStyle>Product subcategory</TextStyle>
                            {selectedSubCategories.map((cat, index) => {
                              if (cat.id === (file.asset_id || file.id)) {
                                const catData = cat.category_id;
                                let catNames = '';
                                catData.forEach((categoryData) => {
                                  categories.find((catFilter) => {
                                    const subCatName =
                                      catFilter.subCategories.find(
                                        (catItem) =>
                                          catItem.id === categoryData,
                                      )?.name;
                                    if (!subCatName) {
                                      return false;
                                    }
                                    catNames += subCatName + ',';
                                  });
                                });
                                return (
                                  <div
                                    className="col-12 text-break"
                                    key={'display-text' + index}
                                  >
                                    <DisplayText size="small">
                                      {catNames.slice(0, -1)}
                                    </DisplayText>
                                  </div>
                                );
                              }
                            })}
                          </div>
                        )}
                        <div
                          className={`col-12 col-md-2 d-flex ${
                            isMultipleCategoriesSelected(file)
                              ? ''
                              : 'align-items-end'
                          } `}
                        >
                          <div>
                            <Button
                              size="slim"
                              onClick={() => {
                                isFileIncluded(file)
                                  ? onActivateEditBrandSizingGuides(file)
                                  : onSubmitBrandSizingGuides(file);
                              }}
                              disabled={
                                isFetching ||
                                selectedCategories.length === 0 ||
                                selectedSubCategories.length === 0
                              }
                            >
                              {isFileIncluded(file) ? 'Edit' : 'Done'}
                            </Button>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 mb-3">
                        <Divider />
                      </div>
                    </div>
                  );
                })
              ) : (
                <p className="text-center">
                  Categories will appear once file is uploaded
                </p>
              )}
            </Card>
          </div>
        </div>
        {toastMessage.length ? (
          <Toast
            content={toastMessage}
            duration={2500}
            error={toastMessage.includes('failed')}
            onDismiss={() => setToastMessage('')}
          />
        ) : null}
      </div>
    </div>
  );
};
