import {UPLOAD_TYPE} from '../../../constants/treet-data';

export const PRODUCT_SUBMIT_ACTION = {
  CROSS_POST: 'CROSS_POST',
  DUPLICATE: 'DUPLICATE',
  TRANSFER: 'TRANSFER',
  UNLINK_CROSS_POST: 'UNLINK_CROSS_POST',
};

export const PRODUCT_SERVICE_METHOD = {
  ADD_TO_DIBZ: 'addToDibz',
  REMOVE_FROM_DIBZ: 'removeFromDibz',
  UNSYNC_FROM_TREET: 'unsyncFromTreet',
};

export const DEFAULT_BRAND_FULFILLMENT_METHOD = {
  TREET: 'TREET',
  SHOPIFY: 'SHOPIFY',
  RETAIL_REWORKS: 'RETAIL_REWORKS',
};

export const mapProductSubmitActionToUploadType = {
  [PRODUCT_SUBMIT_ACTION.CROSS_POST]: UPLOAD_TYPE.CrossPost,
  [PRODUCT_SUBMIT_ACTION.DUPLICATE]: UPLOAD_TYPE.Duplicate,
  [PRODUCT_SUBMIT_ACTION.TRANSFER]: UPLOAD_TYPE.Transfer,
};

export const mapProductSubmitActionToProductServiceMethod = {
  [PRODUCT_SUBMIT_ACTION.CROSS_POST]: PRODUCT_SERVICE_METHOD.ADD_TO_DIBZ,
  [PRODUCT_SUBMIT_ACTION.DUPLICATE]: PRODUCT_SERVICE_METHOD.ADD_TO_DIBZ,
  [PRODUCT_SUBMIT_ACTION.TRANSFER]: PRODUCT_SERVICE_METHOD.ADD_TO_DIBZ,
  [PRODUCT_SUBMIT_ACTION.UNLINK_CROSS_POST]:
    PRODUCT_SERVICE_METHOD.UNSYNC_FROM_TREET,
};
