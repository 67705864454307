/* eslint-disable react/no-children-prop */
import {
  Autocomplete,
  Banner,
  Button,
  Frame,
  Icon,
  Tooltip,
  Link,
} from '@shopify/polaris';
import React, {useState, useEffect} from 'react';
import {RefreshMajor, SearchMinor} from '@shopify/polaris-icons';
import {useProductContext} from '../../context';
import {FilterActionComponent} from '../action-components';
import {useControlData} from './use-control-data.hook';
import {useFormState} from 'react-final-form';
import {stringifyKey} from '../../utils/form';
import {Box, MenuItem, Select} from '@material-ui/core';
import {
  LISTED_ON_TREET_FILTER,
  TREET_APP_TAG,
} from '../../constants/treet-data';
import {pluralize} from '../../utils/string';
import {
  CONDITION_FIELD,
  INTERNAL_NOTES_FIELD,
  ORIGINAL_PRICE_FIELD,
  PRICE_FIELD,
  QUANTITY_FIELD,
  SELLER_NOTES_FIELD,
} from '../../constants/product-form';
import ConditionalWrapper from '../utils/conditionalWrapper';
import {BulkEditConditionModal} from './bulk-edit-condition.modal';
import {BulkEditQuantityModal} from './bulk-edit-quantity.modal';
import {BulkEditOriginalPriceModal} from './bulk-edit-original-price.modal';
import {BulkEditInternalNotesModal} from './bulk-edit-internal-notes.modal';
import {BulkEditTreetPriceModal} from './bulk-edit-treet-price.modal';
import {preventDecimal, preventNegativeNumber} from '../../views/product/utils';
import {BulkEditSellerNotesModal} from './bulk-edit-seller-notes.modal';
import styled from 'styled-components';
import {isEmpty} from 'lodash';
import {PRODUCT_SUBMIT_ACTION} from '../../app/src/util/constants';
import {INVENTORY_FILTER} from '../../constants/filters';

const MAX_NUM_ROWS_TO_LIST = 500;

const ZERO_QUANTITY_ITEM_ERROR =
  'Listings must be created with a quantity greater than zero (0). Please double check your data and ensure that no rows have a quantity of 0.';
const MAX_ROWS_EXCEEDED_ERROR =
  'A maximum of 500 product variants can be listed at once. Please reduce the number of selected rows and try again.';

const StyledSearch = styled.div`
  .Polaris-TextField {
    height: 42px;
  }

  .Polaris-TextField__Input {
    font-size: 0.9rem !important;
    padding-top: 0;
    padding-bottom: 0;
  }

  .Polaris-TextField__Prefix {
    font-size: 0.9rem !important;
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }
`;

const StyledBulkActionDropdown = styled.div`
  .Polaris-Icon {
    height: 20px;
  }
`;

const StyledBox = styled(Box)`
  .Polaris-Banner {
    align-items: center;
  }
  .Polaris-Banner--withinPage {
    padding: 1rem;
  }
  .Polaris-Banner--withinPage .Polaris-Banner__ContentWrapper {
    margin-top: 0px;
  }
`;

const SelectAcrossPagesBanner = ({
  numSelected,
  totalNumProducts,
  setIsSelectAll,
  isSelectAll,
  handleSelectionChange,
}) => {
  return (
    <StyledBox my={1.5}>
      <Banner
        status="info"
        title={
          <Box display="flex" alignItems="center">
            {isSelectAll ? (
              <>
                All {pluralize('product variant', totalNumProducts, true)} are
                selected.
                <Link
                  onClick={() => {
                    handleSelectionChange('all', false);
                    setIsSelectAll(false);
                  }}
                >
                  <Box ml="4px" display="flex">
                    Clear selection.
                  </Box>
                </Link>
              </>
            ) : (
              <>
                {pluralize('product variant', numSelected, true)} selected on
                this page.
                <Link
                  onClick={() => {
                    handleSelectionChange('all', true);
                    setIsSelectAll(true);
                  }}
                >
                  <Box ml="4px" display="flex">
                    Select all{' '}
                    {pluralize('product variant', totalNumProducts, true)}.
                  </Box>
                </Link>
              </>
            )}
          </Box>
        }
      />
    </StyledBox>
  );
};

export const DataControlComponent = ({
  className,
  initialData = [],
  selectedResources = [],
  setFilteredProducts,
  filteredProductsVariants,
  handleSelectionChange,
  autoSaveStatus,
  productSubmitAction,
  hasInitSelected,
}) => {
  const {totalNumProducts, isLoading} = useProductContext();
  const {
    updateText,
    getSortedProducts,
    filterConditions,
    query,
    handleBulkUpdateSave,
    setErrorMessage,
    errorMessage,
    isSelectAll,
    setIsSelectAll,
    handleReloadShopifyProducts,
    selectedInventoryFilter,
    setSelectedInventoryFilter,
  } = useControlData({
    initialData,
    setFilteredProducts,
    selectedResources,
    filteredProducts: filteredProductsVariants,
    handleSelectionChange,
    hasInitSelected,
  });

  const {values: formValues} = useFormState();

  const [bulkEditModalType, setBulkEditModalType] = useState(false);

  const [allProductVariants, setAllProductVariants] = useState([]);

  const isUnlink =
    productSubmitAction === PRODUCT_SUBMIT_ACTION.UNLINK_CROSS_POST;

  const hasZeroQuantityItem = filteredProductsVariants
    .filter(({id}) => selectedResources.includes(id))
    .some(
      ({id}) => Number(formValues[stringifyKey(id)]?.[QUANTITY_FIELD]) <= 0,
    );

  const totalNumSelectedRows = filteredProductsVariants.filter(({id}) =>
    selectedResources.includes(id),
  )?.length;

  const filterInventory = (filterOption) => {
    setSelectedInventoryFilter(filterOption);
    if (filterOption === INVENTORY_FILTER.WITH_STOCK) {
      const productVariantsWithStock = filteredProductsVariants.filter(
        (product) => Number(product['inventory_quantity']) > 0,
      );
      setFilteredProducts(productVariantsWithStock);
    } else {
      setFilteredProducts(allProductVariants);
    }
  };

  useEffect(() => {
    const isEntirePageSelection =
      selectedResources?.length === filteredProductsVariants?.length;
    if (!isEntirePageSelection) {
      // Reset isSelectAll state (i.e. "select all across all pages") upon individual row selection change
      // (i.e. is not a full page selection). If it's a full page selection, do not modify isSelectAll state.
      setIsSelectAll(false);
    }

    if (hasZeroQuantityItem && !isUnlink) {
      setErrorMessage(ZERO_QUANTITY_ITEM_ERROR);
    } else if (totalNumSelectedRows > MAX_NUM_ROWS_TO_LIST) {
      setErrorMessage(MAX_ROWS_EXCEEDED_ERROR);
    } else {
      setErrorMessage('');
    }
  }, [selectedResources]);

  useEffect(() => {
    if (filteredProductsVariants && isEmpty(allProductVariants)) {
      setAllProductVariants(filteredProductsVariants);
    }
  }, [filteredProductsVariants]);

  const selectedProductVariantsData = filteredProductsVariants
    .filter(({id}) => selectedResources.includes(id))
    .map(({id}) => ({id, ...formValues[stringifyKey(id)]}));

  const handleChangeSort = (_, value) => getSortedProducts(value);

  const areProductsSelected = !!selectedResources.length;

  const pageSize = filteredProductsVariants?.length;
  const hasPagination = pageSize < totalNumProducts;

  const showBulkEdit = !isUnlink;

  const disableQtyEdit =
    productSubmitAction === PRODUCT_SUBMIT_ACTION.CROSS_POST;

  return (
    <div className={`DataControl ${className || ''}`}>
      <Frame>
        {errorMessage && (
          <Box mb={3}>
            <Banner
              status="critical"
              title={errorMessage}
              onDismiss={() => setErrorMessage(null)}
            />
          </Box>
        )}
        {bulkEditModalType === CONDITION_FIELD && (
          <BulkEditConditionModal
            bulkEditModalType={bulkEditModalType}
            selectedResources={selectedResources}
            setBulkEditModalType={setBulkEditModalType}
            selectedProductVariantsData={selectedProductVariantsData}
            handleBulkUpdateSave={handleBulkUpdateSave}
            isSelectAll={isSelectAll}
            totalNumProducts={totalNumProducts}
          />
        )}
        {bulkEditModalType === ORIGINAL_PRICE_FIELD && (
          <BulkEditOriginalPriceModal
            bulkEditModalType={bulkEditModalType}
            selectedResources={selectedResources}
            setBulkEditModalType={setBulkEditModalType}
            selectedProductVariantsData={selectedProductVariantsData}
            handleBulkUpdateSave={handleBulkUpdateSave}
            onKeyDown={preventNegativeNumber}
            isSelectAll={isSelectAll}
            totalNumProducts={totalNumProducts}
          />
        )}

        {bulkEditModalType === PRICE_FIELD && (
          <BulkEditTreetPriceModal
            bulkEditModalType={bulkEditModalType}
            selectedResources={selectedResources}
            setBulkEditModalType={setBulkEditModalType}
            selectedProductVariantsData={selectedProductVariantsData}
            handleBulkUpdateSave={handleBulkUpdateSave}
            onKeyDown={preventNegativeNumber}
            isSelectAll={isSelectAll}
            totalNumProducts={totalNumProducts}
          />
        )}

        {bulkEditModalType === SELLER_NOTES_FIELD && (
          <BulkEditSellerNotesModal
            bulkEditModalType={bulkEditModalType}
            selectedResources={selectedResources}
            setBulkEditModalType={setBulkEditModalType}
            selectedProductVariantsData={selectedProductVariantsData}
            handleBulkUpdateSave={handleBulkUpdateSave}
            isSelectAll={isSelectAll}
            totalNumProducts={totalNumProducts}
          />
        )}

        {bulkEditModalType === INTERNAL_NOTES_FIELD && (
          <BulkEditInternalNotesModal
            bulkEditModalType={bulkEditModalType}
            selectedResources={selectedResources}
            setBulkEditModalType={setBulkEditModalType}
            selectedProductVariantsData={selectedProductVariantsData}
            handleBulkUpdateSave={handleBulkUpdateSave}
          />
        )}

        {bulkEditModalType === QUANTITY_FIELD && (
          <BulkEditQuantityModal
            bulkEditModalType={bulkEditModalType}
            selectedResources={selectedResources}
            setBulkEditModalType={setBulkEditModalType}
            selectedProductVariantsData={selectedProductVariantsData}
            handleBulkUpdateSave={handleBulkUpdateSave}
            onKeyDown={(event) => {
              preventNegativeNumber(event);
              preventDecimal(event);
            }}
            isSelectAll={isSelectAll}
            totalNumProducts={totalNumProducts}
          />
        )}
        <Box display="flex" flexDirection="column" py={2}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box display="flex" alignItems="center" justifyContent="flex-start">
              <Box mr={1}>
                <StyledSearch>
                  <div className="search-input__box">
                    <Autocomplete
                      options={initialData}
                      selected={[]}
                      textField={
                        <Autocomplete.TextField
                          onChange={updateText}
                          value={query}
                          type="search"
                          prefix={<Icon source={SearchMinor} />}
                          placeholder="Filter Products"
                        />
                      }
                    />
                  </div>
                </StyledSearch>
              </Box>
              {showBulkEdit && (
                <Box mr={1}>
                  <ConditionalWrapper
                    condition={!areProductsSelected}
                    wrapper={(children) => (
                      <Tooltip
                        content={
                          filterConditions.includes(LISTED_ON_TREET_FILTER)
                            ? "Filter by 'Ready To Be Listed On Treet' to select rows to edit or upload"
                            : 'Select rows below to edit or upload'
                        }
                        preferredPosition="above"
                      >
                        {children}
                      </Tooltip>
                    )}
                  >
                    <StyledBulkActionDropdown>
                      <FilterActionComponent
                        key="bulk-edit"
                        activatorText="Bulk Edit"
                        disabled={!areProductsSelected}
                        items={[
                          {
                            content: 'Condition',
                            onAction: () => {
                              setBulkEditModalType(CONDITION_FIELD);
                            },
                          },
                          {
                            content: 'Original Price',
                            onAction: () => {
                              setBulkEditModalType(ORIGINAL_PRICE_FIELD);
                            },
                          },
                          {
                            content: 'Treet Price',
                            onAction: () => {
                              setBulkEditModalType(PRICE_FIELD);
                            },
                          },
                          {
                            content: 'Seller Notes',
                            onAction: () => {
                              setBulkEditModalType(SELLER_NOTES_FIELD);
                            },
                          },
                          {
                            content: 'Internal Notes',
                            onAction: () => {
                              setBulkEditModalType(INTERNAL_NOTES_FIELD);
                            },
                          },
                          {
                            content: 'Quantity',
                            onAction: () => {
                              setBulkEditModalType(QUANTITY_FIELD);
                            },
                            disabled: disableQtyEdit,
                          },
                        ]}
                      />
                    </StyledBulkActionDropdown>
                  </ConditionalWrapper>
                </Box>
              )}
              <Box mr={1}>{autoSaveStatus}</Box>
            </Box>
            <Box display="flex" alignItems="center" justifyContent="flex-end">
              <Box ml={1} display="flex" flexDirection="row">
                <Tooltip
                  content={`Reload products tagged with "${TREET_APP_TAG}" from Shopify.`}
                >
                  <Button
                    loading={isLoading}
                    className="btn filter-btn"
                    type="button"
                    onClick={handleReloadShopifyProducts}
                    accessibilityLabel="Reload products from Shopify"
                  >
                    <Box height="14px" width="14px">
                      {isLoading ? null : <Icon source={RefreshMajor} />}
                    </Box>
                  </Button>
                </Tooltip>
                <Box ml={1}>
                  <Select
                    defaultValue={selectedInventoryFilter}
                    value={selectedInventoryFilter}
                    onChange={(e) => filterInventory(e.target.value)}
                    MenuProps={{
                      anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'left',
                      },
                      transformOrigin: {
                        vertical: 'top',
                        horizontal: 'left',
                      },
                    }}
                  >
                    <MenuItem value={INVENTORY_FILTER.ALL_INVENTORY}>
                      All Inventory
                    </MenuItem>
                    <MenuItem value={INVENTORY_FILTER.WITH_STOCK}>
                      Inventory With Stock
                    </MenuItem>
                  </Select>
                </Box>
              </Box>
            </Box>
          </Box>
          {hasPagination && !query && selectedResources.length > 0 && (
            <SelectAcrossPagesBanner
              numSelected={selectedResources.length}
              totalNumProducts={totalNumProducts}
              setIsSelectAll={setIsSelectAll}
              isSelectAll={isSelectAll}
              handleSelectionChange={handleSelectionChange}
            />
          )}
        </Box>
      </Frame>
    </div>
  );
};
