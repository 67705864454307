import {ApiClient} from '../utils/api/api-client';
import {EndpointService} from '../utils/api/endpoints';
// const config = require("../../config");

const apiClient = new ApiClient();

export class UtilService {
  async getColors() {
    return apiClient.call(EndpointService.getColors({search: 'color'}));
  }

  async getSizes() {
    return apiClient.call(EndpointService.getSizes({search: 'size'}));
  }

  async getVariantColors(id) {
    return apiClient.call(
      EndpointService.getVariantColors(id, {search: 'color'}),
    );
  }

  async getVariantSizes(id) {
    return apiClient.call(
      EndpointService.getVariantSizes(id, {search: 'size'}),
    );
  }

  async getCategories() {
    return apiClient.call(EndpointService.getCategories);
  }
}

export const verifyAuthErrors = async () => {
  return apiClient.call(EndpointService.verifyAuthErrors);
};
