import React from 'react';
import {OnboardingCard, OnboardingInfo} from '@shopify/channels-ui';
import {QuickSaleMajor} from '@shopify/polaris-icons';
import {gql, useMutation} from '@apollo/client';

const COMPLETE_ONBOARDING_INFO_MUTATION = gql`
  mutation CompleteOnboardingInfo {
    completeOnboardingInfo {
      id
      onboardingInfoCompleted
    }
  }
`;

const OnboardingInfoCard = ({state}) => {
  const [completeOnboardingInfo, {loading}] = useMutation(
    COMPLETE_ONBOARDING_INFO_MUTATION,
  );

  const handleAction = () => {
    completeOnboardingInfo();
  };

  return (
    <OnboardingCard
      title={<h5>What you need to know before you start</h5>}
      state={state}
    >
      <OnboardingInfo
        completed={state === 'completed'}
        items={[
          {
            icon: {
              color: 'subdued',
              source: QuickSaleMajor,
            },
            title: <h5>Upload Shopify products to your Treet marketplace</h5>,
            content: (
              <p>
                Upload Shopify products to your Treet marketplace. Buyers will
                be able to purchase your products as Brand Direct items directly
                from your Treet marketplace.
              </p>
            ),
          },
          //       refunded orders.
        ]}
        action={{
          content: 'I understand',
          loading,
          onAction: handleAction,
        }}
      />
    </OnboardingCard>
  );
};

export default OnboardingInfoCard;
