import {Endpoint, Request} from './api-client';

// eslint-disable-next-line no-undef
let baseUrl = APP_HOST;
let apiUrl = `${baseUrl}/dibz`;

// eslint-disable-next-line no-undef
let commandCenterUrl = COMMAND_CENTER_HOST;

function handleQueryParams(url, query) {
  if (query) {
    const query = Object.entries(query)
      .filter(([, value]) => typeof value !== 'undefined')
      .map(([field, value]) => field + '=' + value)
      .join('&');
    url += query ? '?' + query : '';
  }

  return url;
}

export const EndpointService = {
  // products endpoint
  getProduct: Endpoint(Request.GET, `${apiUrl}/products`),
  getFilteredProducts: (query, cancelToken) =>
    Endpoint(Request.GET, `${apiUrl}/products`, {query}, cancelToken),
  getProductStat: Endpoint(Request.GET, `${apiUrl}/products/statistics`),
  getProductIncomplete: Endpoint(Request.GET, `${apiUrl}/products/incomplete`),
  bulkUpdateProductsDiscount: Endpoint(
    Request.PUT,
    `${apiUrl}/products/bulk-update-discount`,
  ),
  bulkUpdateProductVariants: Endpoint(
    Request.PUT,
    `${apiUrl}/products/bulk-update-product-variants`,
  ),
  reloadShopifyProducts: Endpoint(
    Request.GET,
    `${apiUrl}/products/reload-shopify-products`,
  ),
  // update product status
  addToDibz: Endpoint(Request.PUT, `${apiUrl}/products/add-to-dibz`),
  removeFromDibz: Endpoint(Request.PUT, `${apiUrl}/products/remove-from-dibz`),
  unsyncFromTreet: Endpoint(
    Request.PUT,
    `${apiUrl}/products/unsync-from-treet`,
  ),
  getListingsFromTreet: (treetId, productVariantIds) =>
    Endpoint(Request.GET, `${apiUrl}/products/listings`, {
      query: {treetId, productVariantIds},
    }),

  // variant endpoints
  getVariant: (productId, query, cancelToken) =>
    Endpoint(
      Request.GET,
      `${apiUrl}/products/variants/${productId}`,
      {query},
      cancelToken,
    ),
  updateVariantDiscount: Endpoint(
    Request.PUT,
    `${apiUrl}/discount/update-variants-discount`,
  ),
  createPriceRule: Endpoint(Request.POST, `${apiUrl}/discount/price-rule`),
  // update variant status
  addVariantToDibz: Endpoint(
    Request.PUT,
    `${apiUrl}/products/variants/add-to-dibz`,
  ),
  removeVariantFromDibz: Endpoint(
    Request.PUT,
    `${apiUrl}/products/variants/remove-from-dibz`,
  ),

  // filter products
  filterByStatus: (status) =>
    Endpoint(Request.GET, handleQueryParams(`${apiUrl}/products`, {status})),
  filterByColor: (color) =>
    Endpoint(Request.GET, handleQueryParams(`${apiUrl}/products`, {color})),
  filterBySize: (size) =>
    Endpoint(Request.GET, handleQueryParams(`${apiUrl}/products`, {size})),

  // sort products
  getSortedProducts: (order) =>
    Endpoint(Request.GET, `${apiUrl}/products?sort=${order}`),

  // filter variants
  filterVariantsByStatus: (id, status) =>
    Endpoint(Request.GET, `${apiUrl}/products/variants/${id}?status=${status}`),
  filterVariantByColor: (id, color) =>
    Endpoint(Request.GET, `${apiUrl}/products/variants/${id}?color=${color}`),
  filterVariantBySize: (id, size) =>
    Endpoint(Request.GET, `${apiUrl}/products/variants/${id}?size=${size}`),

  // util endpoints
  getColors: (query) =>
    Endpoint(Request.GET, `${apiUrl}/shop/product`, {query}),
  getSizes: (query) => Endpoint(Request.GET, `${apiUrl}/shop/product`, {query}),
  getVariantColors: (id, query) =>
    Endpoint(Request.GET, `${apiUrl}/shop/variant/${id}`, {query}),
  getVariantSizes: (id, query) =>
    Endpoint(Request.GET, `${apiUrl}/shop/variant/${id}`, {query}),
  getCategories: Endpoint(Request.GET, `${apiUrl}/products/categories`),
  verifyAuthErrors: Endpoint(Request.GET, `${apiUrl}/verify-auth-errors`),

  // product description endpoint
  getShippingOptions: Endpoint(Request.GET, `${apiUrl}/shop/shipping-options`),
  updateDefaultShipping: (id) =>
    Endpoint(Request.PUT, `${apiUrl}/shop/shipping-options/${id}`),
  getBrandDescription: Endpoint(Request.GET, `${apiUrl}/brands/description`),
  updateBrandDescription: Endpoint(
    Request.PUT,
    `${apiUrl}/brands/add-description`,
  ),
  getBrandMission: Endpoint(Request.GET, `${apiUrl}/brands/mission`),
  updateBrandMission: Endpoint(Request.PUT, `${apiUrl}/brands/add-mission`),
  getCloudinarySignature: Endpoint(
    Request.POST,
    `${commandCenterUrl}/images/generate_signature`,
  ),
  removeImageFromCloudinary: Endpoint(
    Request.DELETE,
    `${commandCenterUrl}/images/remove`,
  ),
  getBrandImages: Endpoint(Request.GET, `${apiUrl}/brand-images`),
  addBrandImage: Endpoint(Request.POST, `${apiUrl}/brand-images`),
  deleteBrandImage: (id) =>
    Endpoint(Request.DELETE, `${apiUrl}/brand-images/${id}`),

  //brand sizing guide endpoints
  getBrandSizingGuides: Endpoint(Request.GET, `${apiUrl}/brand-sizing-guides`),
  addBrandSizingGuide: Endpoint(Request.POST, `${apiUrl}/brand-sizing-guides`),
  deleteBrandSizingGuide: (id) =>
    Endpoint(Request.DELETE, `${apiUrl}/brand-sizing-guides/${id}`),
  updateBrandSizingGuideCategories: (id) =>
    Endpoint(Request.PUT, `${apiUrl}/brand-sizing-guides/${id}/categories`),

  getContentfulConfig: Endpoint(Request.GET, `${apiUrl}/contentful/config`),

  // Treet Shop endpoints
  getTreetShopConfig: Endpoint(Request.GET, `${apiUrl}/treet-shop/config`),
  getUploadHistory: (treetId) =>
    Endpoint(Request.GET, `${apiUrl}/treet-shop/upload-history`, {
      query: {treetId},
    }),
  getEnabledPermissionsForTreet: (treetId) =>
    Endpoint(
      Request.GET,
      `${apiUrl}/treet-shop/enabled-permissions-for-treet`,
      {
        query: {treetId},
      },
    ),
};

/*

update Brands description:
put:
http://localhost:8081/dibz/brands/add-description
{
    "description": "Brand description" */
