import React from 'react';

const CrossPostIcon = () => (
  <svg
    width="183"
    height="72"
    viewBox="0 0 183 72"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/2000/svg"
  >
    <g opacity="0.8">
      <circle
        cx="147"
        cy="34"
        r="31"
        fill="white"
        stroke="#DEDEDE"
        strokeWidth="2"
      />
      <path
        d="M146.743 40.5605C146.743 45.3314 149.796 48.4325 154.137 48.4325C154.853 48.4325 155.473 48.337 155.998 48.1939C156.284 48.1462 156.523 48.0508 156.761 47.9554V43.1845L156.38 43.3276C156.141 43.3753 155.855 43.4231 155.33 43.4231C153.422 43.4231 152.229 42.2303 152.229 40.322V31.2574H157V26.248H152.229V21H147.458V24.1011C147.458 25.8663 146.599 26.725 144.834 26.725H143.403V31.2574H146.743V40.5605Z"
        fill="#1E3D3F"
      />
      <path
        d="M143.072 34.6102C138.314 34.6102 134.458 38.4669 134.458 43.2243V43.2243L134.789 43.2243C139.546 43.2243 143.403 39.3676 143.403 34.6102V34.6102L143.072 34.6102Z"
        fill="#437073"
      />
      <path
        d="M140.028 30.2156C137.098 28.7119 133.504 29.8685 132 32.7988V32.7988L132.295 32.9501C135.225 34.4537 138.82 33.2971 140.323 30.3668V30.3668L140.028 30.2156Z"
        fill="#437073"
      />
      <path
        d="M140.275 26.5988C140.325 23.9378 138.209 21.7397 135.548 21.6891V21.6891L135.543 21.9568C135.492 24.6178 137.609 26.8159 140.27 26.8664V26.8664L140.275 26.5988Z"
        fill="#437073"
      />
    </g>
    <path d="M98 21L103 26L98 31" stroke="#437073" strokeWidth="3" />
    <path d="M80 26L103 26" stroke="#437073" strokeWidth="3" />
    <path d="M85 48L80 43L85 38" stroke="#437073" strokeWidth="3" />
    <path d="M103 43L80 43" stroke="#437073" strokeWidth="3" />
    <g opacity="0.8">
      <circle
        cx="36"
        cy="34"
        r="31"
        fill="white"
        stroke="#DEDEDE"
        strokeWidth="2"
      />
      <path
        d="M46.72 22.1642C46.6942 22.0095 46.5652 21.9063 46.4362 21.9063C46.3072 21.9063 44.0364 21.8548 44.0364 21.8548C44.0364 21.8548 42.1269 19.9983 41.9463 19.8178C41.7657 19.6373 41.3786 19.6889 41.2496 19.7405C41.2496 19.7405 40.8884 19.8436 40.2949 20.0241C40.1917 19.6889 40.0368 19.3021 39.8304 18.8896C39.1595 17.6004 38.1532 16.9042 36.9662 16.9042C36.8888 16.9042 36.8114 16.9042 36.7082 16.93C36.6823 16.8784 36.6307 16.8526 36.6049 16.8011C36.0889 16.2338 35.418 15.976 34.618 16.0018C33.0698 16.0533 31.5216 17.162 30.283 19.1474C29.4057 20.5398 28.7348 22.2931 28.5284 23.6597C26.7479 24.2011 25.5093 24.5879 25.4835 24.6137C24.5804 24.8973 24.5546 24.9231 24.4514 25.774C24.3739 26.4186 22 44.5965 22 44.5965L41.7141 48L50.2551 45.8857C50.2293 45.8599 46.7458 22.3189 46.72 22.1642ZM39.3143 20.3335C38.8499 20.4624 38.3338 20.6429 37.7919 20.7976C37.7919 20.0241 37.6887 18.9154 37.3274 17.9871C38.4886 18.2192 39.0563 19.5342 39.3143 20.3335ZM36.734 21.1328C35.7018 21.4422 34.5664 21.8032 33.4311 22.1384C33.7407 20.9265 34.36 19.7147 35.0825 18.9154C35.3664 18.6317 35.7534 18.2966 36.1921 18.0903C36.6565 18.9927 36.7598 20.2562 36.734 21.1328ZM34.6438 17.0331C35.0051 17.0331 35.3147 17.1105 35.5728 17.2652C35.1599 17.4972 34.7471 17.8067 34.36 18.2192C33.3795 19.2764 32.6312 20.9265 32.3215 22.4994C31.3926 22.783 30.4636 23.0666 29.6121 23.3245C30.154 20.8492 32.2441 17.1105 34.6438 17.0331Z"
        fill="#95BF47"
      />
      <path
        d="M46.4359 21.9063C46.3069 21.9063 44.0362 21.8548 44.0362 21.8548C44.0362 21.8548 42.1267 19.9983 41.9461 19.8178C41.8687 19.7405 41.7913 19.7147 41.688 19.6889V47.9742L50.2291 45.8599C50.2291 45.8599 46.7456 22.3189 46.7198 22.1384C46.694 22.0095 46.565 21.9063 46.4359 21.9063Z"
        fill="#5E8E3E"
      />
      <path
        d="M36.9404 26.2897L35.9599 30.0026C35.9599 30.0026 34.8503 29.487 33.5343 29.5901C31.599 29.719 31.599 30.9309 31.599 31.2403C31.7023 32.8905 36.0631 33.2515 36.3211 37.1449C36.5018 40.2132 34.6955 42.3017 32.0893 42.4564C28.9413 42.6627 27.2124 40.8062 27.2124 40.8062L27.8833 37.97C27.8833 37.97 29.6122 39.285 31.0056 39.1818C31.9087 39.1303 32.2441 38.3825 32.2183 37.8668C32.0893 35.701 28.5284 35.8299 28.2962 32.2717C28.1155 29.2807 30.0766 26.2381 34.4117 25.9803C36.0889 25.8514 36.9404 26.2897 36.9404 26.2897Z"
        fill="white"
      />
    </g>
  </svg>
);

export default CrossPostIcon;
