import {useEffect, useMemo, useState} from 'react';
import {BrandService} from '../../../../../services/brand.service';
import {isEmpty} from 'lodash';

const normalizePermissions = (data) => {
  return data.map((permission) => permission.handle);
};

const useEnabledPermissionsOnTreet = (treetId) => {
  const [
    isEnabledPermissionsOnTreetLoading,
    setEnabledPermissionsOnTreetLoading,
  ] = useState(false);
  const [enabledPermissionsOnTreetError, setEnabledPermissionsOnTreetError] =
    useState(null);
  const [enabledPermissionsOnTreet, setEnabledPermissionsOnTreet] = useState(
    [],
  );
  const brandService = useMemo(() => new BrandService(), []);

  const dismissEnabledPermissionsOnTreetError = () =>
    setEnabledPermissionsOnTreetError(null);

  useEffect(() => {
    if (isEmpty(enabledPermissionsOnTreet) && !!treetId) {
      setEnabledPermissionsOnTreetLoading(true);
      setEnabledPermissionsOnTreetError(null);
      brandService
        .getEnabledPermissionsForTreet(treetId)
        .then(({data}) => {
          setEnabledPermissionsOnTreet(normalizePermissions(data));
        })
        .catch((response) => {
          console.error(response);
          setEnabledPermissionsOnTreet([]);
          setEnabledPermissionsOnTreetError(
            response?.message ||
              'Error retrieving enabled permissions for Treet.',
          );
        })
        .finally(() => setEnabledPermissionsOnTreetLoading(false));
    }
  }, [treetId]);

  return {
    enabledPermissionsOnTreet,
    isEnabledPermissionsOnTreetLoading,
    enabledPermissionsOnTreetError,
    dismissEnabledPermissionsOnTreetError,
  };
};

export default useEnabledPermissionsOnTreet;
