import React from 'react';
import {pluralize} from '../../utils/string';
import {SELLER_NOTES_FIELD} from '../../constants/product-form';
import {EditTextFieldModal} from '../utils/edit-text-field.modal';

export const BulkEditSellerNotesModal = ({
  selectedResources,
  bulkEditModalType,
  setBulkEditModalType,
  selectedProductVariantsData,
  handleBulkUpdateSave,
  isSelectAll,
  totalNumProducts,
}) => {
  return (
    <EditTextFieldModal
      title={
        <>
          Set Sellers Notes For{' '}
          <b>
            {pluralize(
              'Row',
              isSelectAll ? totalNumProducts : selectedResources.length,
              true,
            )}
          </b>
        </>
      }
      subtitle="This description will be shown to buyers on your Treet marketplace. Enter up to 1000 characters."
      buttonText="Set Seller Notes"
      inputHelperText="Add seller notes  here..."
      isOpen={bulkEditModalType === SELLER_NOTES_FIELD}
      selectedProducts={selectedResources}
      handleClose={() => {
        setBulkEditModalType(null);
      }}
      initialValue={
        // If there is a singular row selected, set that row's value to be the default
        selectedProductVariantsData.length === 1
          ? selectedProductVariantsData[0][SELLER_NOTES_FIELD]
          : null
      }
      handleSubmit={async ({variantIds, value}) => {
        if (value) {
          await handleBulkUpdateSave(
            variantIds.reduce((acc, variantId) => {
              return {...acc, [variantId]: {[SELLER_NOTES_FIELD]: value}};
            }, {}),
          );
        }
        setBulkEditModalType(false);
      }}
    />
  );
};
