const shippingData = [
  {
    id: '1',
    shippingType: 'Standard Shipping',
    address1: '123 Main Street',
    addressInfo: 'Account Address',
    city: 'San Jose',
    state: 'CA',
    zipCode: '941141',
    shippingDefault: false,
  },
  {
    id: '2',
    shippingType: 'Standard Shipping',
    address1: '123 Main Street',
    addressInfo: 'Account Address',
    city: 'San Jose',
    state: 'CA',
    zipCode: '941141',
    shippingDefault: false,
  },
  {
    id: '3',
    shippingType: 'Standard Shipping',
    address1: '123 Main Street',
    addressInfo: 'Account Address',
    city: 'San Jose',
    state: 'CA',
    zipCode: '941141',
    shippingDefault: false,
  },
];

export default shippingData;
