import {hot} from 'react-hot-loader';
import React from 'react';
import {
  Routes,
  Route,
  BrowserRouter,
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
} from 'react-router-dom';
import {
  Home,
  Onboarding,
  Account,
  Settings,
  Products,
  CrossPostProducts,
  Finish,
  Uploads,
  TransferProducts,
  CrossPostedInventory,
} from '../sections';
import AppProvider from './AppProvider';
import {MENU_ITEMS, MENU_ITEMS_URL_MAP} from '../../../utils/navigation';
import * as Sentry from '@sentry/react';
import {setupDatadogBrowserLogs} from '../../../utils/datadog/browserLogs';
import {PRODUCT_SUBMIT_ACTION} from '../util/constants';

if (DATADOG_BROWSER_LOGS_CLIENT_TOKEN) {
  setupDatadogBrowserLogs();
}

// Sentry Docs: https://docs.sentry.io/platforms/javascript/guides/react/features/react-router/
if (SENTRY_FRONTEND_DSN) {
  Sentry.init({
    dsn: SENTRY_FRONTEND_DSN,
    environment: DEPLOYED_ENV || 'development',
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          React.useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes,
        ),
      }),
    ],
    tracesSampleRate: SENTRY_TRACES_SAMPLE_RATE,
  });
}

const SentryRoutes = SENTRY_FRONTEND_DSN
  ? Sentry.withSentryReactRouterV6Routing(Routes)
  : Routes;

const Router = () => {
  return (
    <BrowserRouter>
      <SentryRoutes>
        <Route
          path={MENU_ITEMS_URL_MAP[MENU_ITEMS.OVERVIEW]}
          element={<AppProvider />}
        >
          <Route index element={<Home />} />
          <Route
            path={MENU_ITEMS_URL_MAP[MENU_ITEMS.ONBOARDING]}
            element={<Onboarding />}
          />
          <Route
            path={MENU_ITEMS_URL_MAP[MENU_ITEMS.ACCOUNT]}
            element={<Account />}
          />
          <Route
            path={MENU_ITEMS_URL_MAP[MENU_ITEMS.TRANSFER_PRODUCTS]}
            element={<TransferProducts />}
          />
          <Route
            path={MENU_ITEMS_URL_MAP[MENU_ITEMS.PRODUCTS]}
            element={
              <Products
                productSubmitAction={PRODUCT_SUBMIT_ACTION.DUPLICATE}
                canEditProductQuantity
              />
            }
          />
          <Route
            path={MENU_ITEMS_URL_MAP[MENU_ITEMS.CROSS_POST_PRODUCTS]}
            element={<CrossPostProducts />}
          />
          <Route
            path={MENU_ITEMS_URL_MAP[MENU_ITEMS.CROSS_POSTED_INVENTORY]}
            element={<CrossPostedInventory />}
          />
          <Route
            path={MENU_ITEMS_URL_MAP[MENU_ITEMS.UPLOADS]}
            element={<Uploads />}
          />
          <Route
            path={MENU_ITEMS_URL_MAP[MENU_ITEMS.SETTINGS]}
            element={<Settings />}
          />
          <Route
            path={MENU_ITEMS_URL_MAP[MENU_ITEMS.FINISH]}
            element={<Finish />}
          />
        </Route>
      </SentryRoutes>
    </BrowserRouter>
  );
};

export default hot(module)(Router);
