import React, {useCallback, useState, useEffect, useMemo} from 'react';
import {Button, OptionList, Popover} from '@shopify/polaris';
import PropTypes from 'prop-types';

export const OptionListCategories = ({
  categories,
  handleCategoryClick,
  initialSelectedCategories,
}) => {
  const [selected, setSelected] = useState([]);
  const [popoverActive, setPopoverActive] = useState(true);

  useEffect(() => {
    setSelected(initialSelectedCategories);
  }, []);

  const togglePopoverActive = useCallback(
    () => setPopoverActive((popoverActive) => !popoverActive),
    [],
  );

  const isButtonDisabled = !categories || categories?.length == 0;

  const buttonLabel = useMemo(() => {
    let catSelected = '';
    if (selected.length > 0) {
      catSelected = selected
        .map((cat) => {
          return categories.find((catItem) => catItem.id === cat).name;
        })
        .join(', ');
    }
    return selected.length > 0 ? `${catSelected}` : 'Category';
  }, [selected, categories]);

  const optionListCategories = categories.map((cat) => {
    {
      return {label: cat.name, value: cat.id};
    }
  });

  const activator = (
    <Button
      disabled={isButtonDisabled}
      onClick={togglePopoverActive}
      disclosure
    >
      {buttonLabel}
    </Button>
  );

  const onCategoryClick = useCallback(
    (category) => {
      setSelected(category);
      handleCategoryClick(category);
    },
    [handleCategoryClick],
  );

  return (
    <div>
      <Popover
        active={popoverActive}
        activator={activator}
        onClose={togglePopoverActive}
      >
        <OptionList
          title="Categories"
          onChange={onCategoryClick}
          options={optionListCategories}
          selected={selected}
          allowMultiple
        />
      </Popover>
    </div>
  );
};

OptionListCategories.propTypes = {
  categories: PropTypes.arrayOf(PropTypes.object),
  initialSelectedCategories: PropTypes.arrayOf(PropTypes.number),
  handleCategoryClick: PropTypes.func,
};
