import {ApiClient, Endpoint, Request} from '../utils/api/api-client';
import {EndpointService} from '../utils/api/endpoints';

// eslint-disable-next-line no-undef
let baseUrl = APP_HOST;
let apiUrl = `${baseUrl}/dibz`;

const apiClient = new ApiClient();

export class ProductService {
  /**
   * @dev fetch a list of all products
   * @param query {Record<string, any>}
   * @dev returns a promise
   */
  async getProducts(query) {
    return apiClient.call(EndpointService.getProduct(query));
  }

  async getFilteredProducts(query, cancelToken) {
    return apiClient.call(
      EndpointService.getFilteredProducts(query, cancelToken),
    );
  }

  async getStat() {
    return apiClient.call(EndpointService.getProductStat);
  }

  async getProductIncomplete() {
    return apiClient.call(EndpointService.getProductIncomplete);
  }

  async filterByStatus(status) {
    return apiClient.call(EndpointService.filterByStatus(status));
  }

  async getSortedProducts(order) {
    return apiClient.call(EndpointService.getSortedProducts(order));
  }

  async addToDibz(params) {
    return apiClient.call(EndpointService.addToDibz, params);
  }

  async removeFromDibz(products) {
    return apiClient.call(EndpointService.removeFromDibz, products);
  }

  async unsyncFromTreet(products) {
    return apiClient.call(EndpointService.unsyncFromTreet, products);
  }

  async getListingsFromTreet(treetId, productVariantIds) {
    return apiClient.call(
      EndpointService.getListingsFromTreet(treetId, productVariantIds),
    );
  }

  async filterByColor(colors) {
    const mergedColors = colors.join('-');
    return apiClient.call(EndpointService.filterByColor(mergedColors));
  }

  async filterBySize(sizes) {
    const mergedSizes = sizes.join('-');
    return apiClient.call(EndpointService.filterBySize(mergedSizes));
  }

  async bulkUpdateProductsDiscount({discounts}) {
    return apiClient.call(EndpointService.bulkUpdateProductsDiscount, {
      discounts,
    });
  }

  async reloadShopifyProducts() {
    return apiClient.call(EndpointService.reloadShopifyProducts);
  }

  async bulkUpdateProductVariants({
    variants,
    isBulk,
    isSelectAll,
    totalNumProducts,
  }) {
    return apiClient.call(EndpointService.bulkUpdateProductVariants, {
      variants,
      isBulk,
      isSelectAll,
      totalNumProducts,
    });
  }
}

export class VariantService {
  async getVariant(productId) {
    return apiClient.call(EndpointService.getVariant(productId));
  }

  async updateDiscount(data) {
    return apiClient.call(EndpointService.updateVariantDiscount, data);
  }

  async createPriceRule(data) {
    return apiClient.call(EndpointService.createPriceRule, data);
  }

  async getFilteredVariants(id, query, cancelToken) {
    return apiClient.call(EndpointService.getVariant(id, query, cancelToken));
  }

  async filterByStatus(id, status) {
    return apiClient.call(EndpointService.filterVariantsByStatus(id, status));
  }

  async filterByColor(id, colors) {
    const mergedColors = colors.join('-');
    return apiClient.call(
      EndpointService.filterVariantByColor(id, mergedColors),
    );
  }

  async filterBySize(id, sizes) {
    const mergedSizes = sizes.join('-');
    return apiClient.call(EndpointService.filterVariantBySize(id, mergedSizes));
  }

  async addToDibz(variants) {
    return apiClient.call(EndpointService.addVariantToDibz, variants);
  }

  async removeFromDibz(variants) {
    return apiClient.call(EndpointService.removeVariantFromDibz, variants);
  }
}

export class DiscountService {
  async applyDiscountVariant(data) {
    return apiClient.call(
      Endpoint(Request.PUT, `${apiUrl}/discount/update-variants-discount`),
      {},
      data,
    );
  }
}

// export class DiscountService {
//   async applyDiscountVariant(data) {
//     return apiClient.call(
//       Endpoint(Request.PUT, `${apiUrl}/discount/update-variants-discount`, {
//         data,
//       })
//     );
//   }
// }
