import {Field} from 'react-final-form';
import React from 'react';

const FieldSelectComponent = (props) => {
  const {id, input: inputProps, children, ...rest} = props;
  return (
    <select id={id} {...inputProps} {...rest}>
      {children}
    </select>
  );
};

export const FieldSelect = (props) => {
  return <Field component={FieldSelectComponent} {...props} />;
};
