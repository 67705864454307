import {AppLink} from '@shopify/app-bridge/actions';

export const MENU_ITEMS = {
  OVERVIEW: 'Overview',
  ACCOUNT: 'Account',
  PRODUCTS: 'Products',
  CROSS_POST_PRODUCTS: 'Cross-Post Products',
  TRANSFER_PRODUCTS: 'Transfer Products',
  // SETTINGS: 'Brand Profile',
  ONBOARDING: 'Onboarding',
  FINISH: 'Finish',
  UPLOADS: 'Uploads',
  CROSS_POSTED_INVENTORY: 'Cross-Posted Inventory',
};
export const MENU_ITEMS_URL_MAP = {
  [MENU_ITEMS.OVERVIEW]: '/',
  [MENU_ITEMS.ACCOUNT]: '/account',
  [MENU_ITEMS.TRANSFER_PRODUCTS]: '/transfer-products',
  [MENU_ITEMS.PRODUCTS]: '/products',
  [MENU_ITEMS.CROSS_POST_PRODUCTS]: '/cross-post-products',
  // [MENU_ITEMS.SETTINGS]: '/settings',
  [MENU_ITEMS.ONBOARDING]: '/onboarding',
  [MENU_ITEMS.FINISH]: '/finish',
  [MENU_ITEMS.UPLOADS]: '/uploads',
  [MENU_ITEMS.CROSS_POSTED_INVENTORY]: '/cross-posted-inventory',
};

const NAVIGATION_MENU_ITEMS_ORDER = [
  MENU_ITEMS.OVERVIEW,
  MENU_ITEMS.UPLOADS,
  // MENU_ITEMS.SETTINGS,
  MENU_ITEMS.ACCOUNT,
];

export const createChannelMenuConfig = (app, activeMenu) => {
  const createMenuItem = (label) =>
    AppLink.create(app, {
      label,
      destination: MENU_ITEMS_URL_MAP[label],
    });

  const items = NAVIGATION_MENU_ITEMS_ORDER.map(createMenuItem);
  const activeIndex = NAVIGATION_MENU_ITEMS_ORDER.findIndex(
    (menuItem) => menuItem === activeMenu,
  );

  return {
    items,
    active: items[activeIndex],
  };
};
