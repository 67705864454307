import React from 'react';
import {Box} from '@material-ui/core';
import {Button, Icon} from '@shopify/polaris';
import {ClockMinor, QuestionMarkMinor} from '@shopify/polaris-icons';
import {useLocation, useNavigate} from 'react-router-dom';
import {MENU_ITEMS, MENU_ITEMS_URL_MAP} from '../../utils/navigation';
import Link from '../../app/src/foundation/Link';
import ConditionalWrapper from '../utils/conditionalWrapper';
import ClockEmpty from './icons/ClockEmpty';
import HomeEmpty from './icons/HomeEmpty';
import HomeFilled from './icons/HomeFilled';
import styled from 'styled-components';

const StyledBox = styled(Box)`
  .Polaris-Button {
    padding-left: 0px;
  }
`;

const NavButton = ({
  isSelected,
  onClick,
  linkText,
  activeIcon,
  inactiveIcon,
}) => {
  return (
    <StyledBox>
      <Button
        plain
        monochrome
        removeUnderline
        size="slim"
        textAlign="left"
        fullWidth
        onClick={onClick}
      >
        <Box display="flex" alignItems="center" justifyContent="center">
          <Box height="18px" width="18px" mr={1}>
            <Icon source={isSelected ? activeIcon : inactiveIcon} />
          </Box>
          <ConditionalWrapper
            condition={isSelected}
            wrapper={(children) => <b>{children}</b>}
          >
            {linkText}
          </ConditionalWrapper>
        </Box>
      </Button>
    </StyledBox>
  );
};

export const TopNavComponent = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const isLinkSelected = (path) => {
    if (path === '/') {
      return location?.pathname === path;
    }
    return location?.pathname.includes(path);
  };

  return (
    <>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        maxWidth="1600px"
        margin="0 auto"
      >
        {/* Navbar Links */}
        <Box display="flex" alignItems="center" justifyContent="flex-start">
          <NavButton
            isSelected={isLinkSelected(MENU_ITEMS_URL_MAP[MENU_ITEMS.OVERVIEW])}
            onClick={() => navigate(MENU_ITEMS_URL_MAP[MENU_ITEMS.OVERVIEW])}
            activeIcon={HomeFilled}
            inactiveIcon={HomeEmpty}
            linkText="Home"
          />
          <NavButton
            isSelected={isLinkSelected(MENU_ITEMS_URL_MAP[MENU_ITEMS.UPLOADS])}
            onClick={() => navigate(MENU_ITEMS_URL_MAP[MENU_ITEMS.UPLOADS])}
            activeIcon={ClockMinor}
            inactiveIcon={ClockEmpty}
            linkText="Upload History"
          />
        </Box>
        <Box display="flex" flexDirection="row" alignItems="center">
          <Box
            mr={2}
            ml={1}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Link
              onClick={() =>
                window.open('https://survey.refiner.io/m4qx8d-dr3vkv', '_blank')
              }
            >
              Give Feedback
            </Link>
          </Box>
          <Box>
            <Button
              className="btn filter-btn"
              type="button"
              fullWidth
              onClick={() =>
                window.open(
                  'https://treetbrandsuccess.zendesk.com/hc/en-us/articles/14613916622871-Uploading-Brand-Direct-Items',
                  '_blank',
                )
              }
            >
              <Box display="flex">
                <Box height="20px">
                  <Icon source={QuestionMarkMinor} />
                </Box>
                Help
              </Box>
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
};
