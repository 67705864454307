import React from 'react';
import {OnboardingCard, OnboardingChecklist} from '@shopify/channels-ui';
import {useAppBridge} from '@shopify/app-bridge-react';
import {Redirect} from '@shopify/app-bridge/actions';

const OnboardingChecklistCard = ({hasStorefront, hasPolicies, state}) => {
  const app = useAppBridge();
  const redirect = Redirect.create(app);

  const handleOnlineStoreAction = () => {
    redirect.dispatch(Redirect.Action.ADMIN_PATH, {
      path: '/settings/channels',
    });
  };

  const handlePolicyAction = () => {
    redirect.dispatch(Redirect.Action.ADMIN_PATH, {
      path: '/settings/legal',
    });
  };

  const items = [
    {
      label: 'Create an online store',
      completed: hasStorefront,
      action: {
        onAction: handleOnlineStoreAction,
      },
    },
    // {
    //   label: 'Add a privacy policy and return policy',
    //   completed: hasPolicies,
    //   action: {
    //     onAction: handlePolicyAction,
    //   },
    // },
  ];

  /* eslint-disable react/no-unescaped-entities */
  return (
    <OnboardingCard
      title={<h5>Make sure your store meets Treet's requirements</h5>}
      state={state}
      // footer={
      //   <p>
      //     Any changes made to these items after setup might result in your store
      //     being temporarily removed from the Treet Marketplace.
      //   </p>
      // }
    >
      <OnboardingChecklist items={items} />
    </OnboardingCard>
  );

  /* eslint-enable react/no-unescaped-entities */
};

export default OnboardingChecklistCard;
