import React, {useCallback, useState} from 'react';
import {
  Button,
  Checkbox,
  Stack,
  AccountConnection,
  Link,
} from '@shopify/polaris';
import {OnboardingCard} from '@shopify/channels-ui';
import {gql, useMutation} from '@apollo/client';
import styled from 'styled-components';
import TermsAndCondition from '../../../../../components/TermsAndCondition';

const StyledTos = styled.div`
  .Polaris-Modal-Header--titleHidden {
    right: 1.8rem !important;
  }

  .terms {
    background: transparent !important;
    border: none !important;
    text-decoration: underline !important;
    color: blue !important;

    &:hover {
      opacity: 0.8;
      cursor: pointer;
    }
  }
`;

const ACCEPT_TERMS_MUTATION = gql`
  mutation AcceptTerms {
    acceptTerms {
      id
      termsAccepted
    }
  }
`;

const OnboardingTermsCard = ({state}) => {
  const [checkboxValue, setCheckboxValue] = useState(false);
  const [acceptTerms, {loading}] = useMutation(ACCEPT_TERMS_MUTATION);

  const [active, setActive] = useState(false);

  const handleChange = useCallback(() => setActive(!active), [active]);

  const handleCheckboxChange = (checked) => {
    setCheckboxValue(checked);
  };

  const handleButtonClick = () => {
    acceptTerms();
  };

  const [connected, setConnected] = useState(false);
  const accountName = connected ? 'Jane Appleseed' : '';

  const handleAction = useCallback(() => {
    setConnected((connected) => !connected);
  }, [connected]);

  const buttonText = connected ? 'Disconnect' : 'Connect';
  const details = connected ? 'Account connected' : 'No account connected';
  const terms = connected ? null : (
    <p>
      By clicking <strong>Connect</strong>, you agree to accept Sample App’s{' '}
      <Link url="Example App">terms and conditions</Link>. You’ll pay a
      commission rate of 15% on sales made through Sample App.
    </p>
  );

  return (
    <StyledTos>
      <OnboardingCard title={<h4>Account</h4>} state={state} sectioned>
        {state === 'completed' ? (
          <p>
            You have agreed to the{' '}
            <span className="terms" onClick={handleChange}>
              Treet Resale Terms of Service{' '}
            </span>
            .
          </p>
        ) : (
          <Stack vertical>
            <AccountConnection
              accountName={accountName}
              connected={connected}
              title="Example App"
              action={{
                content: buttonText,
                onAction: handleAction,
              }}
              details={details}
              termsOfService={terms}
            />
            <p>
              In order to complete set up, you need to read and agree to the{' '}
              <span className="terms" onClick={handleChange}>
                Treet Resale Terms of Service{' '}
              </span>
              .
            </p>

            <Checkbox
              label="I accept the terms"
              checked={checkboxValue}
              onChange={handleCheckboxChange}
            />
            <Stack distribution="trailing">
              <Button
                primary
                disabled={!checkboxValue}
                loading={loading}
                onClick={handleButtonClick}
              >
                Accept
              </Button>
            </Stack>
          </Stack>
        )}
      </OnboardingCard>
      <TermsAndCondition active={active} handleChange={handleChange} />
    </StyledTos>
  );
};

export default OnboardingTermsCard;
