export const preventNegativeNumber = (event) => {
  if (event.key === '-') {
    event.preventDefault();
  }
};

export const preventDecimal = (event) => {
  if (event.key === '.') {
    event.preventDefault();
  }
};

// Keep PAGE_SIZE in sync with routes/products/functions/getProducts.js
export const PRODUCT_VARIANTS_PAGE_SIZE = 100;
