import React from 'react';
import {TREET_APP_TAG} from '../../constants/treet-data';

export const BannerProductInstructions = ({domain}) => {
  const goToProducts = () => {
    const shopifyDomain = domain?.split('.')?.[0];
    if (shopifyDomain) {
      window.open(
        `https://admin.shopify.com/store/${shopifyDomain}/products`,
        '_blank',
      );
    }
  };
  return (
    <div className="container">
      <div className="row">
        <div className="col-2">
          <img src="https://cdn.shopify.com/app-store/listing_images/c2bd4c694efb547bf0f8eb33a03f1a91/icon/CPychYfyuYEDEAE=.png" />
        </div>
        <div className="col-6">
          <div className="row">
            <h1 className="fw-bold">
              Looking for a product but don’t see it here?
            </h1>
          </div>
          <div className="row">
            <p className="mt-3 mb-3">
              {`Go to the Products tab and select the product you want to see. Once in
            the product details view, make sure that the product is tagged with "${TREET_APP_TAG}". Once tagged, click the "Reload" button to refresh data from Shopify.`}
            </p>
          </div>
          <button
            onClick={goToProducts}
            className="bg-white border rounded p-2 fw-bold"
          >
            Go to Products tab
          </button>
        </div>
        <div className="col-4"></div>
      </div>
    </div>
  );
};
