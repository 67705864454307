import React from 'react';
import {Box} from '@material-ui/core';
import styled from 'styled-components';

const StyledTables = styled.div`
  .Polaris-DataTable__Cell {
    padding: 18px;
  }

  .Polaris-DataTable__Cell--header,
  .Polaris-IndexTable__TableHeading {
    color: #696969;
    border-bottom: 2px solid #e4e5e7;
    background-color: #fafbfb;
    font-weight: 600;
  }
`;

const TableContainer = ({children}) => {
  return (
    <Box width="100%" maxWidth="1600px" m="auto">
      <StyledTables>{children}</StyledTables>
    </Box>
  );
};

export default TableContainer;
