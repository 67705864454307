import React from 'react';
import {pluralize} from '../../utils/string';
import {CONDITION_FIELD} from '../../constants/product-form';
import {
  EXCELLENT,
  GOOD,
  NEW_WITHOUT_TAGS,
  NEW_WITH_TAGS,
} from '../../constants/treet-data';
import {EditRadioFieldModal} from '../utils/edit-radio-field.modal';

export const BulkEditConditionModal = ({
  selectedResources,
  bulkEditModalType,
  setBulkEditModalType,
  selectedProductVariantsData,
  handleBulkUpdateSave,
  isSelectAll,
  totalNumProducts,
}) => {
  return (
    <EditRadioFieldModal
      title={
        <>
          Set Condition For{' '}
          <b>
            {pluralize(
              'Row',
              isSelectAll ? totalNumProducts : selectedResources.length,
              true,
            )}
          </b>
        </>
      }
      buttonText="Set Condition"
      isOpen={bulkEditModalType === CONDITION_FIELD}
      selectedProducts={selectedResources}
      handleClose={() => {
        setBulkEditModalType(null);
      }}
      initialValue={
        // If there is a singular row selected, set that row's value to be the default
        selectedProductVariantsData.length === 1
          ? selectedProductVariantsData[0][CONDITION_FIELD]
          : null
      }
      handleSubmit={async ({variantIds, value}) => {
        if (value) {
          await handleBulkUpdateSave(
            variantIds.reduce((acc, variantId) => {
              return {...acc, [variantId]: {[CONDITION_FIELD]: value}};
            }, {}),
          );
        }
        setBulkEditModalType(false);
      }}
      options={[
        {
          key: NEW_WITH_TAGS,
          description: (
            <>
              <b>New With Tags:</b> has never been used and the original
              packaging and hanging tags are still on.
            </>
          ),
        },
        {
          key: NEW_WITHOUT_TAGS,
          description: (
            <>
              <b>New Without Tags:</b> item has never been worn (other than
              perhaps trying it on), but the tags have been removed. There are
              no quirks or issues whatsoever.
            </>
          ),
        },
        {
          key: EXCELLENT,
          description: (
            <>
              <b>Excellent:</b> item has only been lightly used and has been
              extremely well maintained. There may be some small signs of wear,
              but nothing major.
            </>
          ),
        },
        {
          key: GOOD,
          description: (
            <>
              <b>Good:</b> There may be some quirks like a small stain, a button
              missing, or a loose thread, but it’s overall in good shape.
            </>
          ),
        },
      ]}
    />
  );
};
