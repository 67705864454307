import {FeatureCard, IntroductionPage} from '@shopify/channels-ui';
import React from 'react';
import {useAppBridge} from '@shopify/app-bridge-react';
import {ChannelMenu} from '@shopify/app-bridge/actions';
import TreetOnboardingImage from './images/intro-screen-banner.png';
import styled from 'styled-components';
import {Box} from '@material-ui/core';

const StyledIntro = styled.div`
  .title {
    text-align: center;
    font-weight: 400 !important;
    font-size: 26px !important;
  }

  .CHUI-FeatureCard.CHUI-FeatureCard__Portrait {
    padding: 24px 10px;
  }

  .CHUI-FeatureCard__HeaderContainer {
    display: none !important;
  }

  .CHUI-FeatureCard__Description {
    display: none !important;
  }

  .CHUI-FeatureCard__InfoContainer {
    padding-bottom: 0rem !important;
  }

  .CHUI-FeatureCard__ButtonContainer {
    margin-top: 0rem !important;
    display: flex;
    margin: auto;

    .Polaris-Button {
      background: #437073;
    }
  }

  .subtitle {
    font-size: 14px !important;
    font-weight: 400 !important;
  }

  .intro-image {
    padding-top: 25px;
    filter: drop-shadow(0px 10px 10px rgba(66, 66, 66, 0.2));
  }
`;

const Introduction = ({setupInProgress}) => {
  const app = useAppBridge();
  ChannelMenu.create(app, {
    items: [],
  });

  return (
    <StyledIntro>
      <IntroductionPage title="">
        <FeatureCard
          primaryAction={{
            content: setupInProgress ? 'Continue setup' : 'Start setup',
            url: '/onboarding', // TODO: Need to use MENU_ITEMS_URL_MAP constants
          }}
          portrait
        >
          <Box display="flex" justifyContent="center" alignItems="center">
            <h3 className="title text-bold">Sell on Treet</h3>
          </Box>
          <h4 className="subtitle d-flex justify-content-center text-center">
            List off-priced items on your dedicated Treet marketplace with just
            a few clicks.
          </h4>
          <img
            src={TreetOnboardingImage}
            alt=""
            className="d-flex w-75 m-auto intro-image"
          />
        </FeatureCard>
      </IntroductionPage>
    </StyledIntro>
  );
};

export default Introduction;
