import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Card,
  Spinner,
  TextContainer,
  TextStyle,
} from '@shopify/polaris';
import styled from 'styled-components';
import AddImgIcon from './add_image.png';
import {ImagesRow} from '../images-row/ImagesRow';

const StyledImg = styled.img`
  width: 38px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const CenteredText = styled.p`
  text-align: center;
  margin-top: 1em;
`;

const Caption = styled.p`
  text-align: center;
  color: #6d7175;
  width: 50%;
  margin-top: 3%;
`;

export const UploadImage = ({
  handleUploadImage,
  imagesList,
  handleRemoveImage,
  uploadedNumber = 0,
  isLoading,
  errorUploading,
}) => {
  return (
    <Card sectioned>
      <Container>
        <StyledImg src={AddImgIcon} />
        <TextContainer>
          <CenteredText>
            <b>Drag and drop your photos here </b> <br />
            or
          </CenteredText>
        </TextContainer>
        <Button
          disabled={uploadedNumber >= 6}
          onClick={handleUploadImage}
          primary
        >
          Upload image(s)
        </Button>
        <Caption>
          Upload up to 6 images. Maximum file size is 5MB File types supported:
          JPEG, PNG, GIF, WEBP
        </Caption>
        {isLoading && <Spinner size="small" />}
        {errorUploading && (
          <TextStyle variation="negative">
            An error has occurred, try again.
          </TextStyle>
        )}
        <ImagesRow src={imagesList} handleRemoveImage={handleRemoveImage} />
      </Container>
    </Card>
  );
};

UploadImage.propTypes = {
  handleUploadImage: PropTypes.func,
  handleRemoveImage: PropTypes.func,
  imagesList: PropTypes.array,
  uploadedNumber: PropTypes.number,
  errorUploading: PropTypes.bool,
  isLoading: PropTypes.bool,
};
